import { TextField, InputAdornment } from "@mui/material";
import React, { useState } from "react";
import SearchIcon from "@mui/icons-material/Search";
import Searchpopup from "./Searchpopup";
import SearchData from "./SearchData";
import CustomSnackbar from "../../../../components/ui-component/snackbar/CustomSnackbar";

export default function Search() {
  const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT
  const userId = localStorage.getItem("userId")
  const [open, setOpen] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");
  const [searchResults, setSearchResults] = useState([]);
  const [loading, setLoading] = useState(false);

  const [snackbarOpen, setSnackbarOpen] = React.useState(false);
  const [snackbarMessage, setSnackbarMessage] = React.useState('');
  const [snackbarSeverity, setSnackbarSeverity] = React.useState('success');

  const showSnackbar = (message, severity) => {
    setSnackbarMessage(message);
    setSnackbarSeverity(severity);
    setSnackbarOpen(true);
  };


  const handelopen = () => {
    setOpen(true);
  };

  const handelclose = () => {
    setOpen(false);
    setSearchResults([]);
  };
 console.log("search",searchQuery)
  const handleSearch = async () => {
    setLoading(true); 
     handelopen()
    try {
      // Dynamically construct the request body based on the input provided
      if (!searchQuery) {
    handelclose()    // If searchQuery is empty, return without performing any search
        return;
      }
      const requestBody = {};
      if (!isNaN(searchQuery) && /^\d+$/.test(searchQuery)) {
        // If searchQuery consists entirely of digits, consider it as a number
        requestBody.mobile = searchQuery;
        requestBody.user_id  = parseInt(userId);
      } else if (searchQuery.includes("@")) {
        // If searchQuery includes '@', consider it as an email
        requestBody.email = searchQuery;
        requestBody.user_id  = parseInt(userId);
      } else {
        // Otherwise, consider it as first name
        requestBody.name  = searchQuery;
        requestBody.user_id  = parseInt(userId);
      }

      const response = await fetch(
        `${API_ENDPOINT}route/searchenquiry/`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(requestBody),
        }
      );

      if (response.ok) {
      
     
        const data = await response.json();
        console.log("data is ", data);
        setSearchResults(data); // Assuming response is an array of search results
        // Open the popup to show search results
        setLoading(false);
      }  
    
     else if(response.status===404) {
     
  setLoading(false)
  


     }
    else{
      handelclose()
      showSnackbar('Internal Server error', 'error')

    }

    


   
    } catch (error) {
      setLoading(false);
      handelclose()
      console.error("Error fetching search results:", error);
      showSnackbar('Error fetching search results' , 'error')
    }
  };

  const handleChange = (event) => {
    setSearchQuery(event.target.value);
  };
  return (
    <>
      <TextField
        id="outlined-basic"
        placeholder="Search By Name / Mobile or Email"
        variant="outlined"
        value={searchQuery}
        onChange={handleChange}
        onKeyDown={(e)=>{ if(e.key === "Enter"){
                          handleSearch()
                  }}}
        
        InputProps={{
          style: { borderRadius: "25px", width: "400px", height: "30px" },
          endAdornment: (
            <InputAdornment
              position="end"
              onClick={handleSearch}
              sx={{
                width: "50px",
               // height: "70px",
                borderRadius: "50%",
                //background: "#ABF7B1",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                cursor:"pointer"
              }}
            >
              <SearchIcon />
            </InputAdornment>
          ),
        }}
        sx={{
          "& .MuiOutlinedInput-root": {
            borderRadius: "25px",
          },
          "& .MuiOutlinedInput-input": {
            padding: 1, 
          },
        }}
      />
      <Searchpopup
        open={open}
        setOpen={setOpen}
        onClose={handelclose}
        title="GLOBAL ENQUIRY SEARCH"
        component={<SearchData searchResults={searchResults}  onClose={handelclose} loading={loading}/>}
      />

<CustomSnackbar
        open={snackbarOpen}
        onClose={() => setSnackbarOpen(false)}
        message={snackbarMessage}
        severity={snackbarSeverity}
      />
    </>
  );
}
