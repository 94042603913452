import {
  Alert,
  Box,
  Button,
  Card,
  CardContent,
  CircularProgress,
  Collapse,
  FormControl,
  FormControlLabel,
  Grid,
  InputLabel,
  MenuItem,
  Radio,
  Select,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
  useTheme,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import SubCard from "../../../components/ui-component/card/SubCard";
import { gridSpacing } from "../../../store/constant";
import Chip from "../../../components/ui-component/chip/Chip";
import { fDate } from "../../../../utils/formatTime";
import ClassCardEnroll from "./ClassCardEnroll";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";

const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT;

function ClassDetails({ demoHistory, studentDetails, onNext }) {
  const userId = localStorage.getItem("userId");
  const theme = useTheme();
  const [testTypes, setTestTypes] = useState([]);
  const [branches, setBranches] = useState([]);
  const [packages, setPackages] = useState([]);
  const [trainerSlots, setTrainerSlots] = useState([]);
  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  const [subTestTypes, setSubTestTypes] = useState([]);

  const [isFormVisible, setIsFormVisible] = useState(false);

  const [selectedDemo, setSelectedDemo] = useState(null);
  const [selectedClass, setSelectedClass] = useState(null);
  const [selectedClassData, setSelectedClassData] = useState(null);
  const [selectedSubTestType, setSelectedSubTestType] = useState(null);

  const [formData, setFormData] = useState({
    testType: studentDetails.testType || null,
    deliveryMode: studentDetails.deliveryMode || "online",
    branch_id: studentDetails.branchId || null,
  });

  console.log("formData?.testType", formData?.testType);

  const [selectedTestType, setSelectedTestType] = useState(
    studentDetails ? studentDetails.testType : null
  );

  const [selectedPackage, setSelectedPackage] = useState(null);
  const [installments, setInstallments] = useState([]);

  const [validationError, setValidationError] = useState("");

  const [selectedTestTypeId, setSelectedTestTypeId] = useState(null);

  console.log(selectedPackage);

  const validateForm = () => {
    if (!selectedClass) {
      setValidationError("Please select a class.");
      return false;
    }

    if (!selectedPackage) {
      setValidationError("Please select a package.");
      return false;
    }

    if (!formData.payable_installments) {
      setValidationError("Please select the payable installment.");
      return false;
    }

    // Clear any previous validation error
    setValidationError("");
    return true;
  };

  const handleContinueClick = () => {
    if (validateForm()) {
      onNext(
        selectedClass,
        selectedPackage,
        formData.payable_installments,
        selectedClassData,
        selectedPackage
      );
    } else {
      console.log("Form validation failed");
    }
  };

  const handlePackageChange = (event) => {
    const selectedPackageId = event.target.value;
    const selectedPackage = packages.find(
      (pack) => pack.id === selectedPackageId
    );

    // Set the selected package and installments
    setSelectedPackage(selectedPackage);
    setInstallments(
      Array.from(
        { length: selectedPackage.payable_installment },
        (_, index) => index + 1
      )
    );
  };

  const handleChange = (event) => {
    setFormData({
      ...formData,
      [event.target.name]: event.target.value,
    });
  };

  const handleTestTypeChange = (event) => {
    const testType = testTypes.find((i) => i.id === event.target.value);
    setSelectedTestTypeId(testType);
  };

  const handleSubTestTypeChange = (event) => {
    setSelectedSubTestType(event.target.value);
  };

  const handleEnrollClick = (demo) => {
    // Set the selected demo when the "Enroll" button is clicked
    console.log(demo);
    setSelectedDemo(demo);

    const selectedClass = demo.class_id;
    const selectedTestType = demo.test_type_id?.id;

    setSelectedClass(selectedClass);
    setSelectedTestType(selectedTestType);

    const selectedClassData = {
      testTypeId: demo.test_type_id?.id,
      testType: demo.test_type_id?.name,
      trainerName: demo.trainer_id?.first_name,
      slot: demo.slot,
      deliveryMode: demo.delivery_mode,
      branch: demo.branch_id?.branch_name,
      branch_id: demo.branch_id?.id,
    };
    setSelectedClassData(selectedClassData);
  };

  const rows = [
    {
      id: 1,
      trainer_name: "Ms Pooja",
      demo_date: "23-11-2023",
      slot: "10:00 - 12:00",
      status: "completed",
    },
  ];

  console.log(demoHistory);

  useEffect(() => {
    // Fetch menu items using the fetch function
    // fetch(`${API_ENDPOINT}route/fetchbranch/${userId}`)
    fetch(`${API_ENDPOINT}branch`)
      .then((response) => response.json())
      .then((data) => {
        setBranches(data);
        console.log(data);
      })
      .catch((error) => {
        console.error("Error fetching branches:", error);
      });
  }, []);

  useEffect(() => {
    fetch(`${API_ENDPOINT}advisordashboard/gettesttype`)
      .then((response) => response.json())
      .then((data) => {
        setTestTypes(data);
      })
      .catch((error) => {
        console.error("Error fetching task items:", error);
      });
  }, []);
  console.log(trainerSlots);
  // console.log('Selected Trainer ID:', formData.selectedTrainer);

  useEffect(() => {
    // Fetch menu items using the fetch function
    fetch(`${API_ENDPOINT}route2.0/getsubtesttype/${selectedTestType}`)
      .then((response) => response.json())
      .then((data) => {
        setSubTestTypes(data);
        console.log(data);
      })
      .catch((error) => {
        console.error("Error fetching testtypes:", error);
        setSubTestTypes([]);
      });
  }, [selectedTestType]);

  useEffect(() => {
    const testType = testTypes.find((i) => i.id === selectedTestType);
    setSelectedTestTypeId(testType);
  }, [selectedTestType]);

  useEffect(() => {
    if (selectedTestType) {
      // Fetch packages based on the selected test type
      fetch(`${API_ENDPOINT}route/packagebytesttype/${selectedTestType}`)
        .then((response) => response.json())
        .then((data) => {
          setPackages(data);
          console.log(data);
        })
        .catch((error) => {
          console.error("Error fetching packages:", error);
          setPackages([]);
        });
    }
  }, [selectedTestType]);

  const handleSearchClassSlots = () => {
    setTrainerSlots([]); // Clear trainer slots when searching for training
    setLoading(true);

    const requestData = {
      //student_id: formData.studentId,
      //student_name: formData.studentName,
      test_type_id: formData.testType,
      mode_of_delivery: formData.deliveryMode,
      branch_id: formData.branch_id,
      // available_demo_date: formData.demo_date,
      // same_trainer: null
    };

    console.log(requestData);

    // Make the POST request to your API endpoint
    fetch(`${API_ENDPOINT}route/searchclass`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(requestData),
    })
      .then((response) => {
        if (response.status === 200) {
          return response.json();
        } else if (response.status === 403) {
          throw new Error(
            "Couldn't find any slot for this test type, try next day!!"
          );
        } else if (response.status === 404) {
          throw new Error("Cannot return data, 404");
        } else {
          throw new Error("Unexpected error occurred");
        }
      })
      .then((data) => {
        if (data && Array.isArray(data) && data.length > 0) {
          setTrainerSlots(data);
          setErrorMessage("");
          setLoading(false);
        } else {
          setTrainerSlots([]);
          setErrorMessage("No slots found for the day"); // Set the error message
          setLoading(false);
        }
      })
      .catch((error) => {
        setErrorMessage(error.message); // Set the error message in state
        console.error("Error:", error);
        setLoading(false);
      });
  };

  return (
    <Grid container spacing={gridSpacing}>
      <Grid item xs={12}>
        <SubCard
          sx={{ overflowX: "auto" }}
          title="Demo History"
          content={false}
        >
          {demoHistory.length === 0 ? (
            <Typography variant="body1" color="textSecondary">
              No demo history available.
            </Typography>
          ) : (
            <TableContainer>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell sx={{ pl: 3 }}>Trainer Name</TableCell>
                    <TableCell>Date</TableCell>
                    <TableCell>Slot</TableCell>
                    <TableCell>Status</TableCell>
                    <TableCell sx={{ pr: 3 }}>Actions</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {demoHistory.map((row, index) => (
                    <TableRow hover key={index}>
                      <TableCell sx={{ pl: 3 }}>
                        {row.trainer_id?.first_name}
                      </TableCell>
                      <TableCell>{fDate(row.available_demo_date)}</TableCell>
                      <TableCell>{row.slot}</TableCell>
                      <TableCell>
                        <Chip
                          chipcolor={row.badgeType}
                          label={row.status}
                          size="small"
                        />
                      </TableCell>
                      <TableCell>
                        <Button
                          variant="contained"
                          onClick={() => handleEnrollClick(row)}
                          disabled={row.status !== "completed"}
                        >
                          Enroll
                        </Button>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          )}
        </SubCard>
      </Grid>

      <Grid item xs={12}>
        <SubCard
          title={
            <div style={{ display: "flex", alignItems: "center" }}>
              Select Class
              <Button
                onClick={() => setIsFormVisible(!isFormVisible)}
                startIcon={
                  isFormVisible ? (
                    <KeyboardArrowUpIcon />
                  ) : (
                    <KeyboardArrowDownIcon />
                  )
                }
              >
                {isFormVisible ? "Hide Form" : "Show Form"}
              </Button>
            </div>
          }
        >
          <Collapse in={isFormVisible}>
            <Box m={2}>
              <form>
                <Grid container spacing={gridSpacing}>
                  <Grid item xs={6}>
                    <FormControl fullWidth>
                      <InputLabel>Test Type</InputLabel>
                      <Select
                        name="testType"
                        value={formData.testType}
                        //onChange={handleChange}
                        onChange={(event) => {
                          handleChange(event);
                          setSelectedTestType(event.target.value);
                          handleTestTypeChange(event);
                        }}
                        required
                      >
                        {testTypes.map((testType) => (
                          <MenuItem key={testType.id} value={testType.id}>
                            {testType.name}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Grid>
                  <Grid item xs={6}>
                    <FormControl fullWidth>
                      <InputLabel>Delivery Mode</InputLabel>
                      <Select
                        name="deliveryMode"
                        value={formData.deliveryMode}
                        onChange={handleChange}
                      >
                        <MenuItem value="online">Online</MenuItem>
                        <MenuItem value="offline">Offline</MenuItem>
                      </Select>
                    </FormControl>
                  </Grid>

                  {formData.deliveryMode === "offline" && (
                    <Grid item xs={6}>
                      <FormControl fullWidth>
                        <InputLabel>Branch</InputLabel>
                        <Select
                          name="branch_id"
                          value={formData.branch_id}
                          onChange={handleChange}
                          required
                        >
                          {branches.map((branch) => (
                            <MenuItem key={branch.id} value={branch.id}>
                              {branch.branch_name}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </Grid>
                  )}

                  <Grid item xs={12}>
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={handleSearchClassSlots}
                    >
                      Search Class Slots
                    </Button>
                  </Grid>
                </Grid>

                <Box mt={2}>
                  <Typography variant="h4" textAlign="center">
                    Class Slots Available for
                  </Typography>
                </Box>
                {loading && (
                  <CircularProgress
                    style={{ marginTop: "1rem", justifyContent: "center" }}
                  />
                )}

                <Box
                  sx={{
                    display: "flex !important",
                    flexDirection: "column !important",
                    marginBottom: "2rem",
                    marginTop: "2rem",
                  }}
                >
                  {trainerSlots.length === 0 ? (
                    <Typography>
                      No slots found for the selected criteria.
                    </Typography>
                  ) : (
                    <Grid container spacing={2}>
                      {trainerSlots.map((slot) => (
                        <Grid item xs={4} key={slot.id}>
                          <Card
                            sx={{
                              padding: "16px",
                              background:
                                theme.palette.mode === "dark"
                                  ? theme.palette.dark.main
                                  : theme.palette.grey[50],
                              border: "1px solid",
                              borderColor:
                                theme.palette.mode === "dark"
                                  ? theme.palette.dark.main
                                  : theme.palette.grey[100],
                              "&:hover": {
                                border: `1px solid${theme.palette.primary.main}`,
                              },
                            }}
                          >
                            <CardContent>
                              <Typography variant="h4">
                                Trainer: {slot.trainer_id?.first_name}
                              </Typography>
                              <Typography variant="body2">
                                Slot: {slot.start_time} - {slot.end_time}
                              </Typography>
                              <Typography variant="body2">
                                Delivery Mode: {slot.mode_of_delivery}
                              </Typography>
                              <Typography variant="body2">
                                Branch: {slot.branch_id?.branch_name}
                              </Typography>
                              <FormControlLabel
                                control={
                                  <Radio
                                    checked={selectedClass === slot.id}
                                    onChange={() => {
                                      setSelectedClass(
                                        selectedClass === slot.id
                                          ? null
                                          : slot.id
                                      );

                                      const selectedClassData = {
                                        testTypeId: slot.test_type_id?.id,
                                        testType: slot.test_type_id?.name,
                                        trainerName:
                                          slot.trainer_id?.first_name,
                                        slot: `${slot.start_time} - ${slot.end_time}`,
                                        deliveryMode: slot.mode_of_delivery,
                                        branch: slot.branch_id?.branch_name,
                                        branch_id: slot.branch_id?.id,
                                      };
                                      setSelectedClassData(selectedClassData);
                                    }}
                                    value={slot.id}
                                    name="selectedClass"
                                  />
                                }
                                label="Select"
                              />
                            </CardContent>
                          </Card>
                        </Grid>
                      ))}
                    </Grid>
                  )}
                </Box>
              </form>
            </Box>
          </Collapse>
        </SubCard>
      </Grid>

      {selectedClass && (
        <Grid item xs={12}>
          <SubCard title="Selected Class">
            <ClassCardEnroll classData={selectedClassData} />
          </SubCard>
        </Grid>
      )}

      <Grid item xs={12}>
        <SubCard title="Select Packages">
          <Box m={2}>
            <Grid container spacing={gridSpacing}>
              {selectedTestTypeId?.name == "IELTS" && (
                <>
                  <Grid item xs={6}>
                    <FormControl fullWidth>
                      <InputLabel>Select Sub Test Type</InputLabel>
                      <Select
                        name="sub_test_type_id"
                        value={selectedSubTestType}
                        onChange={handleSubTestTypeChange}
                        required
                      >
                        {subTestTypes.map((pack) => (
                          <MenuItem key={pack.id} value={pack.id}>
                            {pack.name}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Grid>

                  <Grid item xs={6}>
                    <FormControl fullWidth>
                      <InputLabel>Select Package</InputLabel>
                      <Select
                        name="package"
                        value={selectedPackage ? selectedPackage.id : ""}
                        onChange={handlePackageChange}
                        required
                      >
                        {packages
                          .filter(
                            (pack) =>
                              pack.sub_test_type_id === selectedSubTestType
                          )
                          .map((pack) => (
                            <MenuItem key={pack.id} value={pack.id}>
                              {pack.package_name}
                            </MenuItem>
                          ))}
                      </Select>
                    </FormControl>
                  </Grid>
                </>
              )}

              {selectedTestTypeId?.name == "PTE" && (
                <>
                  <Grid item xs={6}>
                    <FormControl fullWidth>
                      <InputLabel>Select Sub Test Type</InputLabel>
                      <Select
                        name="sub_test_type_id"
                        value={selectedSubTestType}
                        onChange={handleSubTestTypeChange}
                        required
                      >
                        {subTestTypes.map((pack) => (
                          <MenuItem key={pack.id} value={pack.id}>
                            {pack.name}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Grid>

                  <Grid item xs={6}>
                    <FormControl fullWidth>
                      <InputLabel>Select Package</InputLabel>
                      <Select
                        name="package"
                        value={selectedPackage ? selectedPackage.id : ""}
                        onChange={handlePackageChange}
                        required
                      >
                        {packages
                          .filter(
                            (pack) =>
                              pack.sub_test_type_id === selectedSubTestType
                          )
                          .map((pack) => (
                            <MenuItem key={pack.id} value={pack.id}>
                              {pack.package_name}
                            </MenuItem>
                          ))}
                      </Select>
                    </FormControl>
                  </Grid>
                </>
              )}

              {selectedTestTypeId?.name !== "IELTS" &&
                selectedTestTypeId?.name !== "PTE" && (
                  <>
                    <Grid item xs={6}>
                      <FormControl fullWidth>
                        <InputLabel>Select Package</InputLabel>
                        <Select
                          name="package"
                          value={selectedPackage ? selectedPackage.id : ""}
                          onChange={handlePackageChange}
                          required
                        >
                          {formData.testType === null ? (
                            <MenuItem value="">Select Test Type First</MenuItem>
                          ) : packages.filter(
                              (pack) =>
                                pack.sub_test_type_id === selectedSubTestType
                            ).length === 0 ? (
                            <MenuItem value="" disabled>
                              No Packages Available
                            </MenuItem>
                          ) : (
                            packages.map((pack) => (
                              <MenuItem key={pack.id} value={pack.id}>
                                {pack.package_name}
                              </MenuItem>
                            ))
                          )}
                        </Select>
                      </FormControl>
                    </Grid>
                  </>
                )}

              <Grid item xs={6}>
                <FormControl fullWidth>
                  <InputLabel>Payable Installments</InputLabel>
                  <Select
                    name="payable_installments"
                    value={formData.payable_installments}
                    onChange={handleChange}
                    required
                  >
                    {installments.map((installment) => (
                      <MenuItem key={installment} value={installment}>
                        {installment}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
            </Grid>
          </Box>
        </SubCard>
      </Grid>

      {validationError && (
        <Alert color="error" variant="caption" mt={1}>
          {validationError}
        </Alert>
      )}

      <Grid container justifyContent="right" spacing={0} mt={2}>
        <Grid item>
          <Button
            variant="contained"
            color="secondary"
            size="large"
            onClick={handleContinueClick}
          >
            Continue
          </Button>
        </Grid>
      </Grid>
    </Grid>
  );
}

export default ClassDetails;
