import React, { useState } from 'react';
import Button, { Avatar, ButtonBase, IconButton, Menu, MenuItem } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import PersonAddIcon from '@mui/icons-material/PersonAdd';
import { useNavigate } from 'react-router-dom';


function AddEnquiryPopover() {
  const navigate = useNavigate();
  const theme = useTheme();
 const userRole=localStorage.getItem('userRole')
  const [isMenuOpen, setMenuOpen] = useState(null);

  const handleMenuOpen = (event) => {
    setMenuOpen(event.currentTarget);
  };

  const handleMenuClose = () => {
    setMenuOpen(null);
  };

  const handleMenuClose1 = () => {
    setMenuOpen(null);
    navigate('/addenquirynew');

  }

  const handleMenuClose2 = () => {
    setMenuOpen(null);
    navigate('/incoming-call')
  };

  const handleMenuClose3 = () => {
    setMenuOpen(null);
    navigate('/walk-in-form')
  };

  const handleMenuClose4 = () => {
    setMenuOpen(null);
    navigate('/register-svo')
  };

  const handleMenuClose5 = () => {
    setMenuOpen(null);
    navigate('/walkinleads')
  };

  return (
    <>
        {/*<ButtonBase sx={{ borderRadius: '12px', overflow: 'hidden' }}>
          <Avatar
            variant="rounded"
            sx={{
              ...theme.typography.commonAvatar,
              ...theme.typography.mediumAvatar,
              transition: 'all .2s ease-in-out',
              background: theme.palette.secondary.dark,
              color: '#fff',
              '&:hover': {
                background: theme.palette.secondary.light,
                color: theme.palette.secondary.dark
              }
            }}
            onClick={handleMenuOpen}
            color="inherit"
          >
            <PersonAddIcon stroke={1.5} size="1.3rem" />
          </Avatar>
        </ButtonBase>*/}

<ButtonBase sx={{ borderRadius: '12px', overflow: 'hidden' }}>
  <Avatar
    variant="rounded"
    sx={{
      width: 40, // Adjust size as needed
      height: 40, // Adjust size as needed
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      backgroundColor: 'transparent', // No background color
      //border: '1px solid #ccc', // Optional border
      '&:hover': {
        backgroundColor: 'transparent', // Keep transparent on hover
      }
    }}
    onClick={handleMenuOpen}
    color="inherit"
  >
    <PersonAddIcon stroke={1.5} size="1.3rem" sx={{ color: '#787878' }}  />
  </Avatar>
</ButtonBase>

      
      <Menu
        anchorEl={isMenuOpen}
        open={Boolean(isMenuOpen)}
        onClose={handleMenuClose}
      >
        <MenuItem onClick={handleMenuClose1}>Add Enquiry</MenuItem>
        <MenuItem onClick={handleMenuClose2}>Register Incoming Call</MenuItem>
        <MenuItem onClick={handleMenuClose3}>Register Walk In</MenuItem>
        {(userRole==='center_admin' || userRole==='data_entry') && (
        <MenuItem onClick={handleMenuClose4}>Register In SVO</MenuItem>
        )  }
        <MenuItem onClick={handleMenuClose5}>Offline Walk Ins</MenuItem>
      </Menu>
    </>
  );
}

export default AddEnquiryPopover;
