import { Box, Card, CardContent, Typography } from "@mui/material";
import React from "react";
import NewCardcomp from "../../../components/card/NewCardcomp";


export default function DataCards({ iconSrc, title, value,backGround }) {
  return (
    <>
      <Card  sx={{
        width: "100%",
        borderRadius:'10px', padding:'10px',  boxShadow:'0px 4px 8px rgba(0, 0, 0, 0.1)',
            "&:hover": {
              cursor: "pointer",
              backgroundColor: backGround,
              boxShadow:"0px  4px 8px rgba(0, 0, 0, 0.5)" ,
              // ".revenue-card-text": {
            
              //   fontSize: "14px", // Increase title font size on hover
              // },
              // ".revenue-card-value": {
              //   fontSize: "18px", // Increase value font size on hover
              // },
            },
          }}>
        <Box
          display="flex"
         
        >
          <Box>
            <img src={iconSrc} alt="img" width="100%" />
          </Box>
          <Box display="flex" flexDirection="column" ml={1}>
            <Typography variant="h6" sx={{color:"#848080"}}>{title}</Typography>
            <Typography variant="h4" sx={{textAlign:"center"}}
             
           
            >
            {value}
            </Typography>
          </Box>
        </Box>
      </Card>
    </>
  );
}
