import React, { useEffect, useState } from 'react'
import EnquiryDetailsCard from '../leadpages/EnquiryDetailsCard'
import { useLocation, useParams } from 'react-router-dom';
import { AppBar, Backdrop, CircularProgress, Grid, Paper, Toolbar, Typography } from '@mui/material';
import EnquiryActivityCard from '../leadpages/EnquiryActivityCard';
import EnquiryAddNote from '../leadpages/EnquiryAddNote';
import ViewEnquiry from '../../../pages/advisorPages/viewEnquiry/ViewEnquiry';
import LeadStepper from '../../../components/stepper/Stepper';

const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT;

function EnquiryDetails({enquiryDetails}) {
  console.log("details", enquiryDetails)
    const { leadNo } = useParams();
    const location = useLocation();
    //const leadDetails = location.state?.enquiryDetails || null;
    const [enquiryData, setEnquiryData] = useState(enquiryDetails || null)
    const [loading, setLoading] = useState(false)

   


   const fetchEnquiryData = async (studentId) => {
      //const apiUrl = `${API_ENDPOINT}route/getstudent/${studentId}`; // Adjust the API endpoint as needed
      const apiUrl = `${API_ENDPOINT}lmenquirycrm/${studentId}`;
      try {
        const response = await fetch(apiUrl);
    
        if (!response.ok) {
          // Handle non-successful responses (e.g., 404 Not Found, 500 Internal Server Error)
          throw new Error(`Error: ${response.status} - ${response.statusText}`);
        }
    
        const data = await response.json();
        return data;
      } catch (error) {
        console.error('Error fetching data:', error);
        throw new Error('Failed to fetch data');
      }
    };

    useEffect(() => {
      setLoading(true);
      const fetchEnquiryInfo = async () => {
        //setLoading(true);
        try {
          const data = await fetchEnquiryData(leadNo); // Assuming fetchEnquiryData takes a student ID as an argument
          setEnquiryData(data);
          setLoading(false);
        } catch (error) {
          console.error('Error fetching enquiry data:', error);
          setLoading(false);
          // Handle error as needed
        }
      };
  
      if (!enquiryData && leadNo) {
        fetchEnquiryInfo();
      } else {
        setLoading(false);
      }
    }, [enquiryData, leadNo]);

    const headerHeight = 264; 
  return (
    <>
      <Backdrop open={loading} style={{ zIndex: 9999 }}>
        <Typography variant="h4" color="error" fontWeight={800} marginRight={2}>Fetching</Typography>
        <CircularProgress color="primary" />
      </Backdrop>
      {enquiryData ? (
      <Grid container spacing={2}>
        <Grid item xs={12} style={{ position: 'sticky', zIndex: 1 }}>
       
          <LeadStepper enquiryData={enquiryData} />
       
      </Grid>
      <Grid item xs={12} style={{ position: 'sticky', top: 50, zIndex: 1 }}>
        <Paper elevation={3}>
          <EnquiryDetailsCard enquiryData={enquiryData} />
        </Paper>
      </Grid>
      <Grid item xs={12}>
      <ViewEnquiry enquiryData={enquiryData} headerHeight={headerHeight}/>
      </Grid>
      {/*  <Grid item xs={12} md={6}>
        <EnquiryActivityCard activitiesData={activitiesData} />
       </Grid>
       <Grid item xs={12} md={6}>
        <EnquiryAddNote enquiryData={leadDetails}/>
      </Grid> */}
      </Grid>
      ):(
        <p>No lead details found.</p>
      )}
      </>
     
  )
}

export default EnquiryDetails