import React from "react";
import "./Navbar.css"; // Import the CSS file for styling
import Logo from "../Logo";
import Search from "../../MainLayout/Header/Search/Search";
import { Box, Button } from "@mui/material";
import VideocamIcon from '@mui/icons-material/Videocam';
import AccountPopover from "../../../../components/header/AccountPopover";
import Notification from "../../../../components/header/Notification/Notification";
import AddEnquiryPopover from "../../MainLayout/Header/addEnquiryPopover/AddEnquiryPopover";
import MyTasReport from "../../MainLayout/Header/tasks/MyTasReport";

const Navbar = () => {
  const userRole = localStorage.getItem('userRole');
  const navigateToZoomLink = () => {
    const zoomLink = 'https://zoom.us/j/97485138279?pwd=Y1cwcXRHUWZWOFN3aTZOdmhHZm4yQT09#success';
    window.open(zoomLink, "_blank");
  };

  return (
    <div className="navbar">
      {/* Left Section: Logo and Search */}
      <div className="navbar-left">
        <Logo />
        <Search />
      </div>

      {/* Right Section: Button and Icons */}
      <div className="navbar-right">
        <Box marginRight={2}>
          <Button
            variant="rounded"
            size="small"
            sx={{
              background: '#00B13F',
              color: '#fff',
              borderRadius: '12px',
              overflow: 'hidden',
              fontFamily: 'Roboto',
              fontSize: '12px',
              fontWeight: 500,
              textTransform: 'none',
              '&:hover': {
                background: 'transparent', 
                color: 'inherit'
              }
            }}
            onClick={navigateToZoomLink}
            color="inherit"
            startIcon={<VideocamIcon />}
          >
            Join SVO
          </Button>
        </Box>

        <Box>
          <MyTasReport />
        </Box>

        {userRole !== 'trainer' && (
          <Box>
            <AddEnquiryPopover />
          </Box>
        )}

        <Box >
          <Notification />
        </Box>

        <Box>
          <AccountPopover />
        </Box>
      </div>
    </div>
  );
};

export default Navbar;
