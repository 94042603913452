import { TableContainer } from '@mui/material';
import { styled } from '@mui/system';

const StyledTableContainer = styled(TableContainer)(({ theme,background  }) => ({
  "& .MuiTableCell-head": {
    backgroundColor: background,
    fontWeight: 600,
    padding: "1px",
    fontSize: "0.68rem",
    color: "#000",
    textAlign: "center",
    fontFamily: "Roboto",
  },
  "& .MuiTableCell-body": {
    textAlign: "center",
    padding: "8px 16px",
    overflow: "hidden", // Hide overflowing content
    textOverflow: "ellipsis", // Show ellipsis when content overflows
    fontFamily: "Roboto",
    fontSize: "0.68rem",
    color: "#666666",
    whiteSpace: "normal", // Allow text to wrap inside the cell
    wordWrap: "break-word",
  },
}));

export default StyledTableContainer;
