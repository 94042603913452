import { Backdrop, Typography } from "@mui/material";
import {
  Document,
  Font,
  Page,
  PDFViewer,
  StyleSheet,
  Text,
  View,
} from "@react-pdf/renderer";

import React, { useEffect, useState } from "react";

Font.register({
  family: "Open Sans",
  fonts: [
    {
      src: "https://cdn.jsdelivr.net/npm/open-sans-all@0.1.3/fonts/open-sans-regular.ttf",
    },
    {
      src: "https://cdn.jsdelivr.net/npm/open-sans-all@0.1.3/fonts/open-sans-600.ttf",
      fontWeight: 600,
    },
  ],
});
export default function DownloadTestTypeReport() {
  const [revenueData, setRevenueData] = useState([]);
  const [loading, setLoading] = useState(true);

  const styles = StyleSheet.create({
    document: { padding: 10 },
    page: {
      fontFamily: "Open Sans",

      paddingTop: 30,
      paddingLeft: 60,
      paddingRight: 60,
      lineHeight: 1.5,
      flexDirection: "column",
    },
    heading: {
      textAlign: "center",
      fontWeight: 600,
      fontSize: "18px",
      marginBottom: "10px",
    },

    heading2: {
      textAlign: "center",
      fontWeight: 600,
      fontSize: "14px",
      marginBottom: "10px",
    },

    table: {
      marginTop: 10,
      width: "100%",
      marginBottom: 10,
      borderCollapse: "collapse",
    },
    tableRow: {
      flexDirection: "row",
    },
    tableCell: {
      padding: 5,
      fontSize: 9,
      textAlign: "center",
      flex: 1,
      borderBottom: "0.5px solid black", // Bottom border for rows
      borderRight: "0.5px solid black", // Right border for columns
    },
    tableHeaderCell: {
      fontFamily: "Open Sans",
      padding: 5,
      fontSize: 10,
      textAlign: "center",
      fontWeight: "bold",
      flex: 1,
      borderBottom: "0.5px solid black", // Bottom border for header
      borderRight: "0.5px solid black", // Right border for columns
      backgroundColor: "#7ff2ba",
      color: "#002e17",
    },
    lastColumnCell: {
      borderRight: "0.5px solid black", // No right border for the last column
    },

    firstColumnCell: {
      flex: 2,
      borderLeft: "0.5px solid black",
    },
    title: {
      marginTop: "20px",
      fontSize: "14px",
    },
    subtitle: {
      fontWeight: 600,
      fontSize: "14px",
      fontFamily: "Open Sans",
    },
    chipContainer: {
      marginTop: "6px",
      flexDirection: "row",
      flexWrap: "wrap",
      gap: 3, // Add spacing between chips
    },
    chip: {
      backgroundColor: "#7ff2ba",
      borderRadius: 16,
      paddingHorizontal: 6,
      paddingVertical: 2,
      marginRight: 1,
      marginBottom: 8,
    },
    chipText: {
      fontFamily: "Open Sans",
      fontSize: 9,
    },
  });

  useEffect(() => {
    // Retrieve the data from localStorage
    setLoading(true);
    const storedData = localStorage.getItem("targetdata");

    if (storedData) {
      try {
        const parsedData = JSON.parse(storedData);
        console.log("parsedData", parsedData);

        // Destructure the parsed object to extract individual values
        const { revenueData, advisorName, selectedYear, monthname } =
          parsedData;

        // Update the state or perform actions with the retrieved data
        setRevenueData(parsedData);
        setLoading(false);
      } catch (error) {
        console.error("Error parsing stored data:", error);
        setLoading(false);
      }
    }
  }, []);

  return (
    <>
      <Backdrop open={loading} style={{ zIndex: 9999 }}>
        <Typography variant="h2" color="error" fontWeight={800} marginRight={2}>
          Fetching
        </Typography>
        <img
          src="/sand-clock.png"
          alt="Error"
          style={{ width: "200px", height: "200px" }}
        />
      </Backdrop>
      <PDFViewer style={{ width: "100%", height: "100vh" }}>
        <Document style={styles.document}>
          <Page size="A4" style={styles.page}>
            <View>
              <Text style={styles.heading}>Test Type Wise Revenue Report</Text>
            </View>
            <View style={styles.heading2}>
              <Text>
                {" "}
                {revenueData.monthname} {revenueData.selectedYear?.year}{" "}
              </Text>
            </View>

            {revenueData?.advisorName?.some(
              (name) => name.first_name === "Select All"
            ) ? (
              <Text style={styles.title}>
                Please refer the target report for{" "}
                <Text style={styles.subtitle}>All</Text> advisors
              </Text>
            ) : (
              <>
                <Text style={styles.title}>
                  Please refer the target report for :
                </Text>

                <View style={styles.chipContainer}>
                  {revenueData?.advisorName?.map((name, index) => (
                    <View key={index} style={styles.chip}>
                      <Text style={styles.chipText}>
                        {name.first_name} {name.last_name || ""}
                      </Text>
                    </View>
                  ))}
                </View>
              </>
            )}

            <View style={styles.table}>
              <View style={styles.tableRow}>
                <Text style={[styles.tableHeaderCell, styles.firstColumnCell]}>
                  Test Type
                </Text>
                <Text style={styles.tableHeaderCell}>Total Enrollments</Text>
                <Text style={styles.tableHeaderCell}>Online Enrollments</Text>
                <Text style={styles.tableHeaderCell}>Offline Enrollments</Text>
                <Text style={[styles.tableHeaderCell, styles.lastColumnCell]}>
                  Collected Revenue
                </Text>
              </View>
              {revenueData?.revenueData?.length === 0 ? (
                <Text style={{ fontSize: "10px" }}>No Data Available</Text>
              ) : (
                revenueData?.revenueData?.map((row, index) => (
                  <View style={styles.tableRow} key={index}>
                    <Text style={[styles.tableCell, styles.firstColumnCell]}>
                      {row?.test_type}{" "}
                      {row.sub_test_type_id !== ""
                        ? `(${row.sub_test_type_id})`
                        : ""}
                    </Text>
                    <Text style={styles.tableCell}>
                      {row.total_enrollments}
                    </Text>
                    <Text style={styles.tableCell}>
                      {row?.online_enrollments}
                    </Text>
                    <Text style={styles.tableCell}>
                      {row?.offline_enrollments}
                    </Text>
                    <Text style={[styles.tableCell, styles.lastColumnCell]}>
                      {row?.collected_revenue} /-
                    </Text>
                  </View>
                ))
              )}
            </View>
          </Page>
        </Document>
      </PDFViewer>
    </>
  );
}
