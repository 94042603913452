import React, { useState, useEffect } from "react";
import {
  Box,
  Card,
  Typography,
  Pagination,
  CircularProgress,
} from "@mui/material";
import NewCardcomp from "../../../components/card/NewCardcomp";
import ReactApexChart from "react-apexcharts";

export default function PaymentBreakdown({ centerPaymentLoading, centerPaymentdata }) {
  const pageSize = 5; // Number of data points to show per page
  const [currentIndex, setCurrentIndex] = useState(0); // Tracks the current pagination index
  const [chartData, setChartData] = useState({
    categories: [],
    onlinePayments: [],
    offlinePayments: [],
  });
  const [state, setState] = useState({
    series: [
      { name: "Online Payment", data: [] },
      { name: "Offline Payment", data: [] },
    ],
    options: {
      chart: { type: "bar", height: 350 },
      plotOptions: {
        bar: {
          horizontal: false,
          columnWidth: "20%",
          borderRadiusApplication: "end",
          barSpacing: 35,
        },
      },
      colors: ["#00B13F", "#F1AA00"],
      dataLabels: { enabled: false },
      stroke: { show: true, width: 2, colors: ["transparent"] },
      xaxis: {
        categories: [],
        labels: { style: { fontSize: "9px" } },
      },
      yaxis: { title: { text: "Payments" } },
      fill: { opacity: 1 },
      tooltip: {
        y: { formatter: (val) => `${val}` },
      },
    },
  });

  useEffect(() => {
    if (centerPaymentdata && centerPaymentdata.length > 0) {
      const categories = centerPaymentdata.map((item) => item.branch_name);
      const onlinePayments = centerPaymentdata.map((item) => item.online_payment);
      const offlinePayments = centerPaymentdata.map((item) => item.offline_payment);

      setChartData({ categories, onlinePayments, offlinePayments });
    }
  }, [centerPaymentdata]);

  useEffect(() => {
    const currentData = {
      categories: chartData.categories.slice(currentIndex, currentIndex + pageSize),
      onlinePayments: chartData.onlinePayments.slice(currentIndex, currentIndex + pageSize),
      offlinePayments: chartData.offlinePayments.slice(currentIndex, currentIndex + pageSize),
    };

    setState((prevState) => ({
      ...prevState,
      series: [
        { name: "Online Payment", data: currentData.onlinePayments },
        { name: "Offline Payment", data: currentData.offlinePayments },
      ],
      options: {
        ...prevState.options,
        xaxis: {
          ...prevState.options.xaxis,
          categories: currentData.categories,
        },
      },
    }));
  }, [chartData, currentIndex]);

  const totalPages = Math.ceil(chartData.categories.length / pageSize);

  return (
    <>
   <NewCardcomp height='395px'>
        <NewCardcomp>
          <Typography variant="h5" textAlign='center'>
            Online Offline Payment Breakdown
          </Typography>
        </NewCardcomp>
{centerPaymentLoading ? (
        <Box display="flex" justifyContent="center" alignItems="center" mt={2}>
          <CircularProgress />
        </Box>
      ) : ( 

        <Box mt={2} sx={{ background: "#F9F9F9", borderRadius: "14px" }}>
          <ReactApexChart
            options={state.options}
            series={state.series}
            type="bar"
            height={260}
          />
          <Box>
            <Pagination
              size="small"
              count={totalPages}
              page={Math.ceil(currentIndex / pageSize) + 1}
              onChange={(event, value) => {
                const newIndex = (value - 1) * pageSize;
                setCurrentIndex(newIndex);
              }}
              color="secondary"
              sx={{
                justifyContent: "end",
                display: "flex",
                "& .MuiPaginationItem-root": {
                  fontSize: "0.6rem",
                },
              }}
            />
          </Box>
        </Box>
  )}
      </NewCardcomp>
    </>
  );
}
