import { useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

// material-ui
import { useTheme } from "@mui/material/styles";
import {
  Alert,
  Box,
  Button,
  Checkbox,
  Divider,
  Fade,
  FormControl,
  FormControlLabel,
  FormHelperText,
  Grid,
  IconButton,
  InputAdornment,
  InputLabel,
  OutlinedInput,
  Stack,
  TextField,
  Typography,
  useMediaQuery,
} from "@mui/material";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import useScriptRef from "../../../hooks/useScriptRef";
import { useNavigate } from "react-router-dom";
import {
  setEmail,
  setPassword,
  login,
} from "../../../../redux/features/auth/authSlice";
import {
  setUser,
  updateUserRole,
} from "../../../../redux/features/user/userSlice";
import CustomSnackbar from "../../../components/ui-component/snackbar/CustomSnackbar";

const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT;

const LoginForm = ({ ...others }) => {
  const theme = useTheme();
  const scriptedRef = useScriptRef();
  const matchDownSM = useMediaQuery(theme.breakpoints.down("md"));
  const customization = useSelector((state) => state.customization);
  const [checked, setChecked] = useState(true);
  const [showPassword, setShowPassword] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [errorStatus, setErrorStatus] = useState(false);
  const [rememberMe, setRememberMe] = useState(false);

  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [snackbarSeverity, setSnackbarSeverity] = useState("success");

  const showSnackbar = (message, severity) => {
    setSnackbarMessage(message);
    setSnackbarSeverity(severity);
    setSnackbarOpen(true);
  };

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const userRef = useRef();

  const userRole = localStorage.getItem("userRole");
  const { email, password } = useSelector((state) => state.auth);

  const handleLogin = async (e) => {
    e.preventDefault();

    try {
      // Make the API request to authenticate the user
      const response = await fetch(`${API_ENDPOINT}user/login`, {
        method: "POST",
        body: JSON.stringify({ email, password }),
        headers: {
          "Content-Type": "application/json",
        },
      });

      if (response.ok) {
        const data = await response.json();
        const {
          first_name,
          last_name,
          email,
          mobile,
          user_role,
          advisor_id,
          admin_id,
          trainer_id,
          token,
          permission,
          id,
        } = data;
        dispatch(
          setUser({
            first_name,
            last_name,
            email,
            mobile,
            user_role,
            advisor_id,
            token,
            admin_id,
            trainer_id,
            permission,
            id,
          })
        );
        dispatch(login(data));

        //console.log(user_role)
        // navigate('/dashboard');

        if (permission && permission.length >= 2) {
          // If user has 2 or more permissions, navigate to /role
          navigate("/role", { state: { permission } });
        } else {
          // If not, navigate to /dashboard
          localStorage.setItem("userRole", user_role);
          dispatch(updateUserRole(user_role));
          navigate("/dashboard");
        }

        /* if (user_role === 'admin') {
          navigate('/dashboard');
        } else if (user_role === 'advisor') {
          navigate('/dashboard');
        } else if (user_role === 'trainer') {
          navigate('/dashboard');
        } else {
          console.log('Unknown role. Contact the administrator.');
        }*/
      } else {
        if (response.status === 401) {
          //setErrorStatus(true)
          showSnackbar(
            "Password is incorrect. Please try correct password.",
            "error"
          );
          //setErrorMessage('Wrong UserName or Password')
        } else if (response.status === 403) {
          showSnackbar("User does not exist", "error");
        } else if (response.status === 404) {
          showSnackbar("User with this email not found", "error");
        } else if (response.status === 410) {
          showSnackbar(
            "Your account is inactive. Please contact admin",
            "error"
          );
        } else {
          // setErrorStatus(true)
          showSnackbar("Server error", "error");
          //setErrorMessage('Network Error')
        }
      }
    } catch (error) {
      console.error("An unexpected error occurred:", error);
      showSnackbar(
        "An unexpected error occurred. Please try again later.",
        "error"
      );
    }
  };

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  return (
    <>
      <form onSubmit={handleLogin}>
        <FormControl fullWidth>
              <InputLabel htmlFor="outlined-adornment-email-login">Email Address / Username</InputLabel>
              <OutlinedInput
                id="outlined-adornment-email-login"
                type="email"
                inputRef={userRef}
                value={email}
                onChange={(e) => dispatch(setEmail(e.target.value))}
                name="email"
                label="Email Address / Username"
                inputProps={{
                
                }}
                sx={{ borderRadius: '10px' }}
              />
             
            </FormControl>

            <Box mt={2}>

            <FormControl fullWidth >
              <InputLabel htmlFor="outlined-adornment-password-login">Password</InputLabel>
              <OutlinedInput
                id="outlined-adornment-password-login"
                type={showPassword ? 'text' : 'password'}
                value={password}
                onChange={(e) => dispatch(setPassword(e.target.value))}
                name="password"
               
                label="Password"
                inputProps={{}}
                sx={{ borderRadius: '10px' ,}}
              />
            </FormControl>
            </Box>

            {errorStatus && (
                <Box mt={2}>
                <Alert severity="error">{errorMessage}</Alert>
                </Box>
            )}

      
            

            <Box sx={{ mt: 4 }}>
              
                <Button fullWidth size="large" type="submit" variant="contained" style={{background:'#019E6F'}} onClick={() => handleLogin}>
                  Sign in
                </Button>
             
            </Box>

        {/* <Box
          sx={{
            background: "#000000",
            backgroundSize: "cover",
            backgroundPosition: "center",
            width: "100%",

          
             height: {
              xs: "100%", // Adjust for smaller screens if necessary
              sm: "150vh",
              md: "125vh",
              lg: "100vh",
              xl: "110vh", // Ensure full viewport height on extra large screens
            },
            zIndex: 1,
            position: "relative",
          }}
        >
          <Box display="flex" justifyContent="space-between"     alignItems="center"  >
       <Fade in timeout={5000}>
            <Box
              position="absolute"
              display="flex"
              justifyContent="center"
              alignItems="center"
              height="100vh"
              flexDirection="column"
              marginLeft={20}
              width="400px"
              zIndex={5}
            >
              <img src="/test_master_logo_light 1.png" alt="Logo" width={180} />
              <Typography
                sx={{
                  background:
                    "linear-gradient(90deg, #F1B95C 0%, #FFEFD5 100%)",
                  WebkitBackgroundClip: "text",
                  WebkitTextFillColor: "transparent",
                  fontSize: "2.5rem", // Example font size, adjust as needed
                  fontFamily: "Poppins",
                  marginTop: "20px",
                  fontWeight: 800,
                }}
              >
                Welcome Back
              </Typography>
              <Typography sx={{ color: "#FFFFFF", fontFamily: "Poppins" }}>
                Login to your account
              </Typography>
              <TextField
                size="small"
                variant="outlined"
                label="Email/Username"
                inputRef={userRef}
                value={email}
                onChange={(e) => dispatch(setEmail(e.target.value))}
                InputProps={{ style: { borderRadius: "25px", width: "400px" , backgroundColor: "black", color: "#F1B95C",} }}
                sx={{
                  "& .MuiOutlinedInput-root": {
                    backgroundColor: "black !important",
                    "& fieldset": {
                    
                      borderRadius: "25px",
                      borderColor: "#F1B95C", 
                     
                    },
                    "&:hover fieldset": {
                      borderColor: "#F1B95C", 
                  
                    },
                    "&.Mui-focused fieldset": {
                      borderColor: "#F1B95C", 
                    },
                  },
                 
                  "& .MuiOutlinedInput-input": {
                    backgroundColor: "black", // Correctly target the input's background
                    color: "#F1B95C", // Optional: Change text color for visibility
                    "&:-webkit-autofill": {
        WebkitBoxShadow: "0 0 0px 1000px black inset", // Forces black background
        WebkitTextFillColor: "#F1B95C", // Ensures autofill text is visible
      },
                  },
                  "& .MuiInputLabel-root.Mui-focused": {
                    color: "#F1B95C", // Label color when focused
                
                 
                  },
                   "& .MuiInputLabel-root":{
                    color: "#F1B95C",
                   
                   },
                  marginTop: "30px",
                }}
              />
              <TextField
                variant="outlined"
                label="Password"
                size="small"
                type={showPassword ? "text" : "password"}
                value={password}
                onChange={(e) => dispatch(setPassword(e.target.value))}
                InputProps={{ style: { borderRadius: "25px", width: "400px" } }}
                sx={{
                  "& .MuiOutlinedInput-root": {
                    "& fieldset": {
                      borderRadius: "25px",
                      borderColor: "#F1B95C", // Apply border-radius to the fieldset
                    },
                    "&:hover fieldset": {
                      borderColor: "#F1B95C", // Border color on hover
                    },
                    "&.Mui-focused fieldset": {
                      borderColor: "#F1B95C", // Keep the border color gray even on focus
                    },
                  },

                  "& .MuiOutlinedInput-input": {
                    backgroundColor: "black", // Correctly target the input's background
                    color: "#FFDFAA",
                    "&:-webkit-autofill": {
                      WebkitBoxShadow: "0 0 0px 1000px black inset", // Forces black background
                      WebkitTextFillColor: "#F1B95C", // Ensures autofill text is visible
                    },
                  },
                  "& .MuiInputLabel-root.Mui-focused": {
                    color: "#F1B95C", // Label color when focused
                  },
                  "& .MuiInputLabel-root":{
                    color: "#F1B95C",
                   },
                  marginTop: "10px",
                }}
              />
              <Button
                size="small"
                type="submit"
                
                onClick={() => handleLogin}
                style={{
                  marginTop: "10px",
                  fontFamily: "Poppins",
                  color: "#FFFFFF",
                  fontWeight: 700,
                  width: "400px",
                  borderRadius: "25px",
                  fontSize: "20px",
                  background:
                    "linear-gradient(232.37deg, #FEDB6B -6.7%, #DEB754 3.14%, #B18736 17.9%, #966923 30.21%, #8C5E1C 36.77%, #774E17 49.07%, #4F300F 75.31%)",
                }}
              >
                Login
              </Button>
            </Box>
            </Fade>
            <Box
              display="flex"
              justifyContent="end"
              alignItems="center"
              height="100vh"
              width="100%"
            >
              <video
                autoPlay
                loop
                muted
                style={{
                  maxWidth: "1800px",
                  width: "80%",

                  borderRadius: "10px",
                  boxShadow: "0 4px 8px rgba(0, 0, 0, 0.5)",
                }}
              >
                <source
                  src="https://res.cloudinary.com/dccj3grx9/video/upload/v1735534934/samples/New Year/Timeline_1_z7sifl.mp4"
                  type="video/mp4"
                  width="100%"
                  height="100%"
                />
                Your browser does not support the video tag.
              </video>
            </Box>
          </Box>
          <Box position="absolute" left={1} top={2}>
            <img src="/Group (1).png" alt="img" width="50%" />
          </Box>
          <Box position="absolute" left="20%" top={2}>
            <img src="/Layer_1.png" alt="img" width="90%" />
          </Box>
         
        </Box> */}
      </form>
      <CustomSnackbar
        open={snackbarOpen}
        onClose={() => setSnackbarOpen(false)}
        message={snackbarMessage}
        severity={snackbarSeverity}
      />
    </>
  );
};

export default LoginForm;
