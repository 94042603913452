
import {
  Backdrop,
  Box,
  Button,
  Checkbox,
  CircularProgress,
  FormControl,
  Grid,
  ListItemText,
  Menu,
  MenuItem,
  Select,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import FilterAltIcon from "@mui/icons-material/FilterAlt";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import ReportCards from "./ReportCards";
import { Country, State, City } from "country-state-city";
import DownloadIcon from "@mui/icons-material/Download";
import { useGetTokenOrLogout } from "../../utils/token";
import { useNavigate } from "react-router-dom";
import { useDateUtils } from "../../utils/formatTime";
const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT;
export default function Report() {
  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 250,
      },
    },
  };
  
  const getTokenOrLogout = useGetTokenOrLogout();
  const navigate = useNavigate();
  const { currentMonthName, lastMonthName, weekRange } = useDateUtils();
  const userId = localStorage.getItem("userId");
  const token = localStorage.getItem("token");
  const userRole = localStorage.getItem("userRole");
  const [anchorEl, setAnchorEl] = useState(null);
  const [selectedOption, setSelectedOption] = useState("today");
  const [selectedOptionLabel, setSelectedOptionLabel] = useState("Today");
  const [isCenterLoading, setIsCenterLoading] = useState(false);
  const [centerRevenueData, setCenterRevenueData] = useState([]);
  const [fromDate, setFromDate] = useState(null);
  const [toDate, setToDate] = useState(null);

 
const [errors,setErrors]=useState({fromDate:false})
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleSelectOption = (option, label) => {
    setSelectedOption(option);
    setSelectedOptionLabel(label);
    setAnchorEl(null); // Close the menu after selecting an option
    setFromDate(null);
    setToDate(null);
  };

  console.log("selectedOption", selectedOption)

  const [showFilter, setShowFilter] = useState(false);
  const [selectedState, setSelectedState] = useState([]);
  const [states, setStates] = useState([]);
  const [cities, setCities] = useState([]);
  const [selectedstateid, setselectedStateid] = useState([]);
  const [selectedcity, setselectedcity] = useState([]);
  const [selectedcityid, setselectedcityid] = useState([]);

  useEffect(() => {
    fetch(`${API_ENDPOINT}oldstate`)
      .then((response) => response.json())
      .then((data) => {
        const filteredState = data.filter((state) => state.country_id === 101);
        setStates(filteredState);
      })
      .catch((error) => {
        console.error("Error fetching state:", error);
      });
  }, []);

  useEffect(() => {
    if (selectedstateid) {
      fetch(`${API_ENDPOINT}oldcity`)
        .then((response) => response.json())
        .then((data) => {
          const filteredCity = data.filter((city) =>
            selectedstateid.includes(city.state)
          );
          setCities(filteredCity);
          //console.log(filteredCity)
        })
        .catch((error) => {
          console.error("Error fetching city:", error);
        });
    }
  }, [selectedstateid]);

  const handleStateChange = (event) => {
    const selectedState = event.target.value;
    setSelectedState(selectedState);
    const selectedStateIds = states
      .filter((state) => selectedState.includes(state.state_name))
      .map((state) => state.id);
    //console.log(selectedStateIds)
    setselectedStateid(selectedStateIds);
  };

  const handleCityChange = (event) => {
    const selectedcity = event.target.value;
    setselectedcity(selectedcity);
    const selectedCityIds = cities
      .filter((city) => selectedcity.includes(city.city_name))
      .map((city) => city.id);
    //console.log(selectedCityIds)
    setselectedcityid(selectedCityIds);
  };

  const toggleFilter = () => {
    setShowFilter(!showFilter);
  };
  const filteredData = centerRevenueData.filter((card) => {
    if (selectedState.length > 0 && !selectedstateid.includes(card.state_id)) {
      return false;
    }
    if (selectedcity.length > 0 && !selectedcityid.includes(card.city_id)) {
      return false;
    }
    return true;
  });

  console.log("filteredData",filteredData)

 const handleFromdateChange =(e)=>{
setFromDate(e.target.value)
setErrors({ ...errors, fromDate: !e.target.value });
 }
 const handleTodateChange =(e)=>{
  setToDate(e.target.value)
  setErrors({ ...errors, toDate: !e.target.value });
   }

  const getRevenueForCenters = async() => {
    if (
      ( selectedOption==="custom" && !fromDate) ||   ( selectedOption==="custom" && !toDate)){
         setErrors({fromDate: !fromDate, toDate:!toDate})
         return;
        }
    setIsCenterLoading(true);
    

    const token = getTokenOrLogout();
    console.log("token",token)
    if (!token) return;
    let headers = { 
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json" // Make sure to keep the content type
    };
    const requestData = {
   
      user_id:parseInt(userId),
      filter: selectedOption,
      to_date: toDate,
      from_date: fromDate,
    };


 

    try {
      setIsCenterLoading(true);
      const response = await fetch(`${API_ENDPOINT}route/getcenterreport`, {
          method: 'POST',
          headers,
          body: JSON.stringify(requestData),
      });

      if (response.status === 200) {
          const data = await response.json();
          // Handle successful response
          setCenterRevenueData(data);
              setIsCenterLoading(false);
      }  else if (response.status === 404) {
        navigate("/page404");
      } else if (response.status === 401) {
        navigate("/page401");
      } else if (response.status === 403) {
        navigate("/page403");
      } else if (response.status === 440) {
        navigate("/page440");
      } else {
        navigate("/page500");
      }}catch (error) {
        console.error("Error fetching data:", error);
        navigate("/page500");
      }
  };

  useEffect(() => {
   
    getRevenueForCenters();
  }, []);

 


  const handleViewreport = (id) => {
    if (
    ( selectedOption==="custom" && !fromDate) ||   ( selectedOption==="custom" && !toDate)){
       setErrors({fromDate: !fromDate, toDate:!toDate})
       return;
      }
    const url = `/view-center-report`;
    window.open(url, "_blank");
     localStorage.setItem("details", JSON.stringify({filteredData,selectedState,selectedOption, fromDate,toDate}));
  };

  return (
    <Box
      className="box"
      sx={{ height: "100%", width: "100%", position: "relative", color: "" }}
    >
      {/* Gradient Background */}
      <Box
        className="rectangle"
        sx={{
          background:
            "linear-gradient(180deg, rgb(255, 246.42, 240.12) 0%, rgba(255, 246.42, 240.12, 0) 100%)",

          height: "100%",
          position: "absolute",
          top: 0,
          left: 0,
          width: "100%",
          zIndex: -1,
        }}
      />

      <Box style={{ textAlign: "left" }}>
        <Grid container spacing={2}>
          <Grid item xs={12} lg={5} md={3} sm={2}>
            <Typography
              sx={{
                fontFamily: "Poppins",
                fontWeight: 500,
                fontSize: "32px",
                color: "#000000",
              }}
            >
              Centers Report
            </Typography>
          </Grid>

          <Grid item xs={12} lg={7} md={9} sm={10}>
            <Box
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "end",
              }}
            >
              <Button
                variant="outlined"
                style={{
                  color: "#252C58",
                  fontFamily: "Poppins",
                  fontSize: "16px",
                  fontWeight: 400,
                  border: "1px solid #252C58",
                  borderRadius: "8px",
                }}
                onClick={handleClick}
                startIcon={<CalendarMonthIcon />}
              >
                {selectedOptionLabel}
              </Button>
              <Menu
                anchorEl={anchorEl}
                open={Boolean(anchorEl)}
                onClose={handleClose}
              >
                <MenuItem onClick={() => handleSelectOption("today", "Today")}>
                  Today
                </MenuItem>
                <MenuItem
                  onClick={() => handleSelectOption("currentweek", "This Week")}
                >
                This Week ({weekRange})
                </MenuItem>
                <MenuItem
                  onClick={() => handleSelectOption("currentmonth", "This Month")}
                >
                  This Month({currentMonthName})
                </MenuItem>

                <MenuItem
                  onClick={() => handleSelectOption("pastmonth", "Last Month")}
                >
               Last Month({lastMonthName})
                </MenuItem>
                <MenuItem
                  onClick={() => handleSelectOption("custom", "Custom")}
                >
                  Custom
                </MenuItem>


              </Menu>
              {selectedOption === "custom" && (
                <div style={{ marginLeft: "10px",display:'flex', flexDirection:'row' }}>
             
             <Box sx={{display:'flex', flexDirection:'column'}}>
                  <TextField
                    size="small"
                    id="date-from"
                    label="From"
                    type="date"
                    value={fromDate}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    InputProps={{
                      style: {   borderRadius: "8px",},
                      inputProps: {
                        max: new Date().toISOString().split("T")[0],
                      },
                    }}
                    onChange={handleFromdateChange}
                  />
                    {errors.fromDate && (
            <Typography color="error" variant="caption">
           From date required
            </Typography>
                    )}
</Box>

<Box sx={{display:'flex', flexDirection:'column'}}>
                  <TextField
                    size="small"
                    id="date-to"
                    label="To"
                    type="date"
                    defaultValue={toDate}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    InputProps={{
                      style: { marginLeft: "3px" ,  borderRadius: "8px",},
                      inputProps: {
                        max: new Date().toISOString().split("T")[0],
                      },
                    }}
                    onChange={handleTodateChange}
                  />
                     {errors.toDate && (
            <Typography color="error" variant="caption">
           To date required
            </Typography>
                    )}
                </Box>
                </div>
              )}

<Button variant="contained" style={{ color: "#FFFFFF",
                  background: "#019E6F",
                  borderRadius: "10px",      marginLeft: "5px",}} onClick={getRevenueForCenters}>Apply Filter</Button>

{userRole==="center_admin" && (
              <Button
                variant="contained"
                style={{
                  marginLeft: "10px",
                  color: "#FFFFFF",
                  background: "#019E6F",
                  borderRadius: "10px",
                }}
                startIcon={<DownloadIcon />}
                onClick={handleViewreport}
              >
                Download Report
              </Button>
               )}
            </Box>
          </Grid>

          <Grid item xs={12} lg={2} md={2} sm={2}>
            <Button
              variant="outlined"
              style={{
                color: "#252C58",
                fontFamily: "Poppins",
                fontSize: "16px",
                fontWeight: 400,
                border: "1px solid #252C58", // Outline color of the button
                borderRadius: "8px", // Adjust border radius as needed
              }}
              onClick={toggleFilter}
              sx={{
                "& .MuiButton-startIcon": {
                  color: "green", // Color of the icon
                  marginRight: "4px", // Adjust icon spacing as needed
                  fontSize: "16px",
                },
              }}
              startIcon={<FilterAltIcon />}
            >
              Filter
            </Button>
          </Grid>
          <Grid item xs={12} lg={12} md={12} sm={12}>
            <Typography
              sx={{
                fontFamily: "Poppins",
                fontWeight: 400,
                fontSize: "22px",
                color: "#858585",
              }}
            >
              {selectedState.length>0
                ? `Showing All ${filteredData.length} SIEC Institutes in ${selectedState}`
                : `Showing All ${filteredData.length} SIEC Institutes`}
            </Typography>
          </Grid>

          <Grid item xs={12} lg={12}>
            {showFilter && (
              <Grid container spacing={2}>
                {" "}
                {/* Add a container with spacing */}
                <Grid item xs={12} lg={3} md={6} sm={6}>
                  {" "}
                  {/* Make it take half the space */}
                  <FormControl sx={{ mb: 1, width: "100%" }}>
                    <Select
                      multiple
                      value={selectedState}
                      onChange={handleStateChange}
                      style={{ borderRadius: "15px" }}
                      renderValue={(selected) => selected.join(", ")}
                      MenuProps={MenuProps}
                    >
                      <MenuItem>Select State</MenuItem>
                      {states.map((item) => (
                        <MenuItem key={item.id} value={item.state_name}>
                          <Checkbox
                            checked={
                              selectedState.indexOf(item.state_name) > -1
                            }
                          />
                          <ListItemText primary={item.state_name} />
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={12} lg={3} md={6} sm={6}>
                  {" "}
                  {/* Make it take half the space */}
                  <Select
                    multiple
                    value={selectedcity}
                    onChange={handleCityChange}
                    sx={{ width: "100%", borderRadius: "15px" }}
                    renderValue={(selected) => selected.join(", ")}
                    MenuProps={MenuProps}
                  >
                    <MenuItem>Select City</MenuItem>
                    {cities.map((item) => (
                      <MenuItem key={item.id} value={item.city_name}>
                        <Checkbox
                          checked={selectedcity.indexOf(item.city_name) > -1}
                        />
                        <ListItemText primary={item.city_name} />
                      </MenuItem>
                    ))}
                  </Select>
                </Grid>
              </Grid>
            )}
          </Grid>
          <Grid xs={12} lg={12} marginTop={2} sx={{ marginLeft: "15px" }}>
            <Backdrop open={isCenterLoading} style={{ zIndex: 9999 }}>
              <Typography
                variant="h4"
                color="error"
                fontWeight={800}
                marginRight={2}
              >
                Fetching
              </Typography>
              <CircularProgress color="primary" />
            </Backdrop>
            <ReportCards
              filteredData={filteredData}
              selectedState={selectedstateid}
              selectedcity={selectedcityid}
            />
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
}
