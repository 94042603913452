import React, { useEffect, useState } from "react";
import ReactApexChart from "react-apexcharts";

export default function AdminEnrolStat({ data }) {
  const [state, setState] = useState({
    series: [
      {
        name: "Total Enrolments",
        data: [], // Initially empty
      },
    ],
    options: {
      chart: {
        type: "bar",
        height: 350,
      },
      plotOptions: {
        bar: {
          horizontal: false,
          columnWidth: "15px",
          borderRadius: 5,
          borderRadiusApplication: "end",
        },
      },
      colors: ["#F1AA00"],
      dataLabels: {
        enabled: false,
      },
      stroke: {
        show: true,
        width: 2,
        colors: ["transparent"],
      },
      xaxis: {
        categories: [], // Initially empty categories
        labels: {
          style: {
            fontSize: "9px", // Change font size here
          },
        },
      },
      yaxis: {
        title: {
          text: "Total Enrolments",
        },
      },
      fill: {
        opacity: 1,
      },
      tooltip: {
        y: {
          formatter: function (val) {
            return `${val}`;
          },
        },
      },
    },
  });

  // Effect to update state when data changes
  useEffect(() => {
    if (data && data.length > 0) {
      // Extract branch names and total enrolments
      const monthName = data.map((month) => month.month_short_name);
      const totalEnrolments = data.map((branch) => branch.total_enrollment);

      // Update the state with new data
      setState((prevState) => ({
        ...prevState,
        series: [
          {
            name: "Total Enrolments",
            data: totalEnrolments, // Use total enrolments data
          },
        ],
        options: {
          ...prevState.options,
          xaxis: {
            categories: monthName, // Set branch names as categories
          },
        },
      }));
    }
  }, [data]); // Re-run effect when `data` prop changes

  return (
    <>
      <ReactApexChart
        options={state.options}
        series={state.series}
        type="bar"
        height={260}
      />
    </>
  );
}
