//Author: Ayesha Shaikh
// Dated : 03 aug 2024
import { Backdrop, Typography } from "@mui/material";
import {
  Document,
  Page,
  PDFViewer,
  StyleSheet,
  Text,
  View,
} from "@react-pdf/renderer";
import React, { useEffect, useState } from "react";
import { useDateUtils } from "../../utils/formatTime";

export default function CenterReportPdf() {
   const { currentMonthName, lastMonthName, weekRange,weekRangeLong } = useDateUtils();
  const [error, setError] = useState(null);
  const [centerData, setCentersData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [selectedstate, setSelectedState] = useState([]);
  const [selectedOption, setSelectedOption] =useState(null)
  const styles = StyleSheet.create({
    document: { padding: 10 },
    page: {
      fontFamily: "Open Sans",

      paddingTop: 30,
      paddingLeft: 60,
      paddingRight: 60,
      lineHeight: 1.5,
      flexDirection: "column",
    },
    table: {
      marginTop: 10,
      width: "100%",
      borderCollapse: "collapse",
      marginBottom: 10,
    },
    tableRow: { flexDirection: "row" },
    tableCell: {
      padding: 5,
      fontSize: 12,
      textAlign: "center",
      flex: 1,
      border: "0.5px solid black",
    },

    tableHeaderCell: {
      fontFamily: "Open Sans",
      padding: 5,
      fontSize: 12,
      textAlign: "center",
      fontWeight: "bold",
      flex: 1,
      border: "0.5px solid black",

      backgroundColor: "#7ff2ba",
      color: "#002e17",
    },
    tableCell1: {
      padding: 5,
      fontSize: 12,
      textAlign: "center",
      flex: 2,
      border: "0.5px solid black",
    },
    tableHeaderCell1: {
      fontFamily: "Open Sans",
      padding: 5,
      fontSize: 12,
      textAlign: "center",
      fontWeight: "bold",
      flex: 2,
      border: "0.5px solid black",

      backgroundColor: "#7ff2ba",
      color: "#002e17",
    },

    tableHeader: { flexDirection: "row" },
    text: {
      fontSize: 17,
    },
    name: {
      marginTop: 10,
      flexDirection: "row",
      alignItems: "center",
    },
    title: {
      fontFamily: "Open Sans",
      fontWeight: "normal",
      fontSize: 14,
    },
    subtitle: {
      fontFamily: "Open Sans",
      fontWeight: "bold",
      fontSize: 14,
    },
    subtitle1: {
      fontFamily: "Open Sans",

      fontSize: 14,
      borderRadius: "50%",

      paddingLeft: "10px",
      paddingRight: "10px",
      backgroundColor: "#7ff2ba",
    },
  });

  // Retrieve the state from localStorage && Post data
  useEffect(() => {
    const storedClassDetails = localStorage.getItem("details");
    if (storedClassDetails) {
      try {
        const parsedDetails = JSON.parse(storedClassDetails);
        const { filteredData, selectedState ,selectedOption,fromDate,toDate} = parsedDetails;

        if (filteredData) {
          const data=filteredData.filter((i)=>i.branch_name !=="Dummy Branch")
          setCentersData(data); // filteredData is already parsed
        }

        if (selectedState) {
          setSelectedState(selectedState); // selectedState is already parsed
        }
        if (selectedOption) {
            const day= selectedOption?selectedOption[0]?.toUpperCase() + selectedOption?.slice(1):""
            if(day==="Today"){
                const todaysdate=new Date().toISOString().split("T")[0]
                const dayDate=`${day} (${todaysdate})`
                setSelectedOption(dayDate)
            }
            else if(day==="Custom"){
                const dayDate=`from ${fromDate} to ${toDate}`
                setSelectedOption(dayDate)

            }

            else if(selectedOption==="currentweek"){
     
 setSelectedOption(weekRangeLong)
            }
            else if(selectedOption==="currentmonth"){
        
 setSelectedOption(currentMonthName)
            }


            else if(selectedOption==="pastmonth"){
              setSelectedOption(lastMonthName)

            }


                
            
           
           
          }
      } catch (error) {
        console.error("Failed to parse details from localStorage:", error);
      }
    }
  }, []);

  console.log(centerData);
const day= selectedOption?selectedOption[0]?.toUpperCase() + selectedOption?.slice(1):""



  return (
    <>
      {loading ? (
        <Backdrop
          open={loading}
          style={{ zIndex: 9999 }}
          backgroundColor="white"
        >
          <Typography
            variant="h2"
            color="error"
            fontWeight={800}
            marginRight={2}
          >
            Generating Report
          </Typography>
          <img
            src="/Hourglass1.gif"
            alt="Error"
            style={{ width: "200px", height: "200px" }}
          />
        </Backdrop>
      ) : error && !loading ? (
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            height: "100%",
          }}
        >
          <img
            src="/browser.png"
            alt="Error"
            style={{ width: "200px", height: "200px" }}
          />
          <Typography variant="h3">{error}</Typography>
          <Typography variant="h5">Please try again later</Typography>
        </div>
      ) : (
        <>
          <PDFViewer style={{ width: "100%", height: "100vh" }}>
            <Document style={styles.document}>
              <Page size="A4" style={styles.page}>
                <View>
                  <Text
                    style={{
                      textAlign: "center",
                      fontWeight: 600,
                      fontSize: "20px",
                      marginBottom: "30px",
                    }}
                  >
                    Centers Report
                  </Text>
                  <View style={styles.name}>
                    <Text style={styles.title}>
                      Please refer the centers report for :{" "}
                    </Text>
                    <Text style={styles.subtitle}>{selectedOption}</Text>
                  </View>

                  <View style={{ marginTop: "30px" }}>
                    <Text style={styles.title}>
                      {" "}
                      {selectedstate.length > 0
                        ? `Showing All ${centerData.length} SIEC Institutes in ${selectedstate}`
                        : `Showing All ${centerData.length} SIEC Institutes`}
                    </Text>
                   
                   {centerData.length>0 && (
                      <View style={styles.table}>
                        <View style={styles.tableRow}>
                          <Text style={styles.tableHeaderCell1}>
                            Branch Name
                          </Text>
                          <Text style={styles.tableHeaderCell}>
                            Total Revenue
                          </Text>
                          <Text style={styles.tableHeaderCell}>
                            Collected Revenue
                          </Text>
                          <Text style={styles.tableHeaderCell}>
                            Pending Revenue
                          </Text>
                          <Text style={styles.tableHeaderCell}>
                            New Enrollments
                          </Text>
                        </View>
                        {centerData.map((task, index) => (
                          <View style={styles.tableRow} key={index}>
                            <Text style={styles.tableCell1}>
                              {task?.branch_name}
                            </Text>
                            <Text style={styles.tableCell}>
                              {task?.total_revenue}
                            </Text>
                            <Text style={styles.tableCell}>
                              {task?.collected_revenue}
                            </Text>
                            <Text style={styles.tableCell}>
                              {task?.pending_revenue}
                            </Text>
                            <Text style={styles.tableCell}>
                              {task?.total_enrollment}
                            </Text>
                          </View>
                        ))}
                      </View>
                   )}
                  </View>
                </View>
              </Page>
            </Document>
          </PDFViewer>
        </>
      )}
    </>
  );
}
