import { Box, Button, createTheme, TextField, ThemeProvider } from '@mui/material'
import React from 'react'
import CustomThemeDashboard from './CustomThemeDashboard'

export default function FilterButtonComp({ selectedFilter,
    handleClickFilter,
    fromDate,
    toDate,setFromDate,setToDate}) {

      const customTheme = createTheme({
        components: {
          MuiButton: {
            styleOverrides: {
              root: {
                borderRadius: '5px', // Matches the filter-button border radius
                color: '#FFFFFF', // Default text color
                fontWeight: 400, // Font weight
                fontSize: '0.6rem', // Font size
                height: '1.2rem', // Button height
                display: 'flex', // Flex layout
                textAlign: 'center', // Center alignment
                textTransform: 'none', // Prevents uppercase transformation
              },
              contained: {
                backgroundColor: '#00B13F', // Example for a contained button
                '&:hover': {
                  backgroundColor: '#008A32', // Hover effect for contained buttons
                },
              },
              outlined: {
                borderColor: '#00B13F', // Border color for outlined buttons
                color: '#00B13F', // Text color
                '&:hover': {
                  backgroundColor: 'rgba(0, 177, 63, 0.1)', // Hover effect for outlined buttons
                },
              },
            },
          },
        },
      });

  return (
    <>
    
     <Box sx={{ display: "flex", flexWrap: "wrap" }}>
     <ThemeProvider theme={customTheme}>
                <Button
                  variant={selectedFilter === "today" ? "contained" : ""}
                  onClick={() => handleClickFilter("today")}
              
                  style={{
                    color: selectedFilter === "today" ? "#FFFFFF" : "#4b4b4b",
                    background: selectedFilter === "today" ? "#00B13F" : "",
                  }}
                >
                  Today
                </Button>
                <Button
                  variant={selectedFilter === "currentweek" ? "contained" : ""}
                  onClick={() => handleClickFilter("currentweek")}
               
                  style={{
                    color:
                      selectedFilter === "currentweek" ? "#FFFFFF" : "#4b4b4b",
                    background:
                      selectedFilter === "currentweek" ? "#00B13F" : "",
                  }}
                >
                  This Week 
                </Button>
                <Button
                  variant={selectedFilter === "currentmonth" ? "contained" : ""}
                  onClick={() => handleClickFilter("currentmonth")}
              
                  style={{
                    color:
                      selectedFilter === "currentmonth" ? "#FFFFFF" : "#4b4b4b",
                    background:
                      selectedFilter === "currentmonth" ? "#00B13F" : "",
                  }}
                >
                  This Month
                </Button>
              

                <Button
                  variant={selectedFilter === "custom" ? "contained" : ""}
                  onClick={() => handleClickFilter("custom")}
                 
                  style={{
                    color: selectedFilter === "custom" ? "#FFFFFF" : "#4b4b4b",
                    background: selectedFilter === "custom" ? "#00B13F" : "",
                  }}
                >
                  Custom
                </Button>
                </ThemeProvider>

                {selectedFilter === "custom" && (
                  <>
                    <ThemeProvider theme={CustomThemeDashboard}>
                      <TextField
                        variant="outlined"
                        size="small"
                        id="date-from"
                        label="From"
                        type="date"
                        defaultValue={fromDate}
                        InputLabelProps={{
                          shrink: true,
                        }}
                        InputProps={{
                          style: {
                            borderRadius: "7px",
                            height: "22px",
                            width: "80px",
                          },
                        }}
                        inputProps={{
                          max: new Date().toISOString().split("T")[0],
                        }}
                        onChange={(e) => setFromDate(e.target.value)}
                        sx={{
                          marginLeft: 2,
                        }}
                      />
                    </ThemeProvider>
                    <ThemeProvider theme={CustomThemeDashboard}>
                      <TextField
                        size="small"
                        id="date-to"
                        label="To"
                        type="date"
                        defaultValue={toDate}
                        InputLabelProps={{
                          shrink: true,
                        }}
                        InputProps={{
                          style: {
                            borderRadius: "7px",
                            height: "22px",
                            width: "80px",
                          },
                        }}
                        inputProps={{
                          max: new Date().toISOString().split("T")[0],
                        }}
                        onChange={(e) => setToDate(e.target.value)}
                        sx={{
                          marginLeft: 2,
                        }}
                      />
                    </ThemeProvider>
                  </>
                )}
              </Box>
            
    </>
  )
}
