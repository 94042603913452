import { Card, useMediaQuery } from '@mui/material'
import React from 'react'

export default function NewCardcomp({children, height, width}) {
  // Define breakpoints for medium, large, and extra-large screens
  const isMdLgXl = useMediaQuery((theme) =>
    theme.breakpoints.up('md')
  );
  return (
    <>
     <Card style={{borderRadius:'10px', padding:'10px',  boxShadow:'0px 4px 8px rgba(0, 0, 0, 0.1)',  height: isMdLgXl ? height : 'auto', width:width }}>
        {children}
     </Card>
    </>
  )
}
