import {
  Box,
  Button,
  Paper,
  styled,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Typography,
} from "@mui/material";
import React, { useState } from "react";
import { Link } from "react-router-dom";
import StyledTableContainer from "../../../components/TanleContainer";

export default function DetailsStats({ data ,isPopup}) {
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState( !isPopup ?6 :14  );

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };
 

  return (
    <>
      <Box>
        <StyledTableContainer component={Paper}>
          <Table style={{background:'#F9F9F9' }}>
            <TableHead>
              <TableRow>
                <TableCell>Center Name</TableCell>
                <TableCell>No. of Enrollment</TableCell>
                <TableCell>Online Enrollment</TableCell>
                <TableCell>Offline Enrollment</TableCell>
                <TableCell>Total Revenue</TableCell>
                <TableCell>Collected</TableCell>
                <TableCell>Pending</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {data?.length === 0 ? (
                <Box
                  display="flex"
                  justifyContent="center"
                  alignItems="center"
                  mt={2}
                >
                  <Typography
                    sx={{
                      fontFamily: "Roboto",
                      fontSize: "11px",
                      color: "#000000",
                    }}
                  >
                    No data available
                  </Typography>
                </Box>
              ) : (
                data?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((row, index) => (
                    <TableRow
                      key={index}
                      style={{
                      
                        width: "6%",
                      }}
                    >
                      <TableCell>{row?.branch_name}</TableCell>
                      <TableCell>{row?.total_enrollment}</TableCell>
                      <TableCell>{row?.online_enrollment}</TableCell>
                      <TableCell>{row?.offline_enrollment}</TableCell>
                      <TableCell>{row?.total_revenue}/-</TableCell>
                      <TableCell>{row?.collected_revenue}/-</TableCell>
                      <TableCell>{row?.pending_revenue}/-</TableCell>
                    </TableRow>
                  ))
              )}
            </TableBody>
          </Table>
        </StyledTableContainer>

{data.length>6 &&  !isPopup && (
        <Box sx={{ textAlign: "center" }}>
          <Link to="/centerreport">
            <Button
              style={{
                fontSize: "12px",
                fontWeight: 400,
                fontFamily: "Roboto",
                color: "#00B13F",
                textAlign: "center",
              }}
            >
              Show Centers
            </Button>
          </Link>
        </Box>
        )}
      </Box>
    </>
  );
}
