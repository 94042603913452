import React from 'react';
import MenuList from '../../MainLayout/Sidebar/menuList';
import { Drawer } from '@mui/material';
import UserProfile from './UserProfile';

function Sidebar() {
    const userRole = localStorage.getItem('userRole');
  return (
    <div>
        <UserProfile />
        <MenuList userRole={userRole} />
    </div>
  );
}

export default Sidebar;
