import { format, getTime, formatDistanceToNow } from 'date-fns';
import { useMemo } from 'react';

// ----------------------------------------------------------------------

export function fDate(date, newFormat) {
  const fm = newFormat || 'dd MMM yyyy';

  return date ? format(new Date(date), fm) : '';
}

export function fDateTime(date, newFormat) {
  const fm = newFormat || 'dd MMM yyyy p';

  return date ? format(new Date(date), fm) : '';
}

export function fTimestamp(date) {
  return date ? getTime(new Date(date)) : '';
}

export function fToNow(date) {
  return date
    ? formatDistanceToNow(new Date(date), {
        addSuffix: true,
      })
    : '';
}



export function formatTime(timeString) {
  const [hourString, minute] = timeString.split(":");
  const hour = +hourString % 24;
  return (hour % 12 || 12) + ":" + minute +" " + (hour < 12 ? "AM" : "PM");
}


export const useDateUtils = () => {
  const currentMonthName = useMemo(() => {
    const currentDate = new Date();
    return currentDate.toLocaleString("default", { month: "long" });
  }, []);

  const lastMonthName = useMemo(() => {
    let currDate = new Date();
    let   lastMonthDate = new Date(currDate.getFullYear(), currDate.getMonth() - 1, 2);
      return lastMonthDate.toLocaleString("default", { month: "long" });
  }, []);

  const weekRange = useMemo(() => {
    const currentDate = new Date();
    const startOfWeek = new Date(currentDate);
    startOfWeek.setDate(currentDate.getDate() - currentDate.getDay());

    const endOfWeek = new Date(currentDate);
    endOfWeek.setDate(currentDate.getDate() + (6 - currentDate.getDay()));

    const formatDate = (date) =>
      `${date.getDate()} ${date.toLocaleString("default", { month: "short" })}`;
    
    return `${formatDate(startOfWeek)} - ${formatDate(endOfWeek)}`;
  }, []);

  const weekRangeLong = useMemo(() => {
    const currentDate = new Date();
    const startOfWeek = new Date(currentDate);
    startOfWeek.setDate(currentDate.getDate() - currentDate.getDay());

    const endOfWeek = new Date(currentDate);
    endOfWeek.setDate(currentDate.getDate() + (6 - currentDate.getDay()));

    const formatDate = (date) =>
      `${date.getDate()} ${date.toLocaleString("default", { month: "long" })}`;
    
    return `${formatDate(startOfWeek)} - ${formatDate(endOfWeek)}`;
  }, []);

  return { currentMonthName, lastMonthName, weekRange,weekRangeLong };
};



