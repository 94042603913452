import { Box, Pagination } from "@mui/material";
import React, { useEffect, useState } from "react";
import ReactApexChart from "react-apexcharts";

export default function EnrolmentStat({ data }) {
  const pageSize = 5; // Number of data points to show per page
  const [currentIndex, setCurrentIndex] = useState(0); // Tracks the current pagination index
  console.log("currentIndex", currentIndex);
  const [state, setState] = useState({
    series: [
      {
        name: "Total Enrolments",
        data: [], // Initially empty
      },
    ],
    options: {
      chart: {
        type: "bar",
        height: 350,
      },
      plotOptions: {
        bar: {
          horizontal: false,
          columnWidth: "15px",
          borderRadius: 5,
          borderRadiusApplication: "end",
        },
      },
      colors: ["#F1AA00"],
      dataLabels: {
        enabled: false,
      },
      stroke: {
        show: true,
        width: 2,
        colors: ["transparent"],
      },
      xaxis: {
        categories: [], // Initially empty categories
        labels: {
          style: {
            fontSize: "9px", // Change font size here
          },
        },
      },
      yaxis: {
        title: {
          text: "Total Enrolments",
        },
      },
      fill: {
        opacity: 1,
      },
      tooltip: {
        y: {
          formatter: function (val) {
            return `${val}`;
          },
        },
      },
    },
  });

  // Effect to update state when data changes
  useEffect(() => {
    if (data && data.length > 0) {
      // Extract branch names and total enrolments
      if (currentIndex >= data.length) {
        setCurrentIndex(0);
      } else {
        const paginatedData = data.slice(currentIndex, currentIndex + pageSize);
        const branchNames = paginatedData.map((branch) => branch.branch_name);

        const totalEnrolments =
          paginatedData.map((branch) => branch.total_enrollment) || 0;

        // Update the state with new data
        setState((prevState) => ({
          ...prevState,
          series: [
            {
              name: "Total Enrolments",
              data: totalEnrolments, // Use total enrolments data
            },
          ],
          options: {
            ...prevState.options,
            xaxis: {
              categories: branchNames, // Set branch names as categories
            },
          },
        }));
      }
    }
  }, [data, currentIndex, pageSize]); // Re-run effect when `data` prop changes

  const totalPages = Math.ceil((data?.length || 0) / pageSize);

  return (
    <>
      <ReactApexChart
        options={state.options}
        series={state.series}
        type="bar"
        height={260}
      />
      <Box>
        <Pagination
          size="small"
          count={totalPages}
          page={Math.ceil(currentIndex / pageSize) + 1}
          onChange={(event, value) => {
            const newIndex = (value - 1) * pageSize;
            setCurrentIndex(newIndex);
          }}
          color="secondary"
          sx={{
            justifyContent: "end",
            display: "flex",
            "& .MuiPaginationItem-root": {
              fontSize: "0.6rem",
            },
          }}
        />
      </Box>
    </>
  );
}
