import {
  Box,
  Button,
  Card,
  CircularProgress,
  createTheme,
  Divider,
  Grid,
  TextField,
  ThemeProvider,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import Content from "../Components/Content";
import { useDateUtils } from "../../../utils/formatTime";
import RevenueCards from "../CenterAdminDashboard/NewRevenueCards";
import DataCards from "./DataCards";

import UpcommingEvents from "../Components/UpcommingEvents";
import NewCardcomp from "../../../components/card/NewCardcomp";
import LeadCalandar from "../../LeadConversation/LeadCalandar";
import MonthlyRevenuPichart from "../CenterAdminDashboard/MonthlyRevenuPichart";
import { useGetTokenOrLogout } from "../../../utils/token";
import LeadToEnrol from "./LeadToEnrol";
import AdvisorNewTarget from "./AdvisorNewTarget";
import DisplayCurrentDateTime from "../Components/DisplayCurrentDateTime";
import CustomThemeDashboard from "../Components/CustomThemeDashboard";
import MonthlyAdvisorRevenuePiechart from "./MonthlyAdvisorRevenuePiechart";
import FilterButtonComp from "../Components/FilterButtonComp";

const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT;
export default function AdvisorNewDashBoard() {
  const userId = localStorage.getItem("userId");
  const token = localStorage.getItem("token");
  const getTokenOrLogout = useGetTokenOrLogout();
  const [selectedFilter, setSelectedFilter] = useState("today");
  const { currentMonthName, lastMonthName, weekRange } = useDateUtils();
  const [revenueLoading, setRevenueLoading] = useState(false);
  const [fromDate, setFromDate] = useState(null);
  const [toDate, setToDate] = useState(null);
  const [dailyData, setDailyData] = useState({});
  const [upcommigevent, setUpcommingEvent] = useState([]);
  const [upcommingLoading, setUpcommingLoding] = useState(false);
  const [isPieChartLoading, setIsPieChartLoading] = useState(false);
  const [pieChartData, setPieChartData] = useState({});
  const [chosenDate, setChosenDate] = useState(new Date());
  const [targetData, setTargetData] = useState({});
  const [targetLoading, setTargetLoading] = useState(false);
  const [chosenDate1, setChosenDate1] = useState(new Date());
  const [loading, setLoading] = useState(false);
  const [leadData, setLeadData] = useState([]);

  const handleClickFilter = (criteria) => {
    setSelectedFilter(criteria);
    setFromDate("");
    setToDate("");
    if (criteria !== "custom") {
      handleApplyRevenuFilter(criteria, null, null);
    }
  };

  const handleDateChange = (date) => {
    setChosenDate(date);

    // getPieChartData(date)
  };

  // Fetch Upcomming Event
  useEffect(() => {
    setUpcommingLoding(true);
    const token = getTokenOrLogout();
    if (!token) return;
    let headers = {
      Authorization: `Bearer ${token}`,
    };
    const fetchData = async () => {
      try {
        const response = await fetch(
          `${API_ENDPOINT}route2.0/ongoing-upcomingevent/${userId}`,
          { headers }
        );
        if (response.ok) {
          const data = await response.json();
          setUpcommingEvent(data);
          setUpcommingLoding(false);
        } else {
          throw new Error(`Request failed with status: ${response.status}`);
          setUpcommingLoding(false);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
        setUpcommingLoding(false);
      }
    };

    fetchData();
  }, []);

  // Fetch Target Data
  useEffect(() => {
    setTargetLoading(true);
    const fetchData = async () => {
      try {
        const response = await fetch(
          `${API_ENDPOINT}advisor-rev-target/getadvtargetandcollectedrevenue/${userId}`
        );
        if (response.ok) {
          const data = await response.json();
          console.log("data", data);
          setTargetData(data);
          setTargetLoading(false);
        } else if (response.status === 404) {
          setTargetData({});
          setTargetLoading(false);
        } else {
          setTargetLoading(false);
          throw new Error(`Request failed with status: ${response.status}`);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
        setTargetLoading(true);
      }
    };

    fetchData();
  }, []);

  const handleApplyRevenuFilter = async (criteria, fromDate, toDate) => {
    setRevenueLoading(true);
    const token = getTokenOrLogout();
    if (!token) return;
    let headers = {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json", // Make sure to keep the content type
    };
    const requestData = {
      user_id: parseInt(userId),

      filter: criteria,
      to_date: toDate || null,
      from_date: fromDate || null,
    };

    try {
      const response = await fetch(
        `${API_ENDPOINT}advisordashboard/chip-data`,
        {
          method: "POST",
          headers,
          body: JSON.stringify(requestData),
        }
      );

      if (response.ok) {
        const result = await response.json();

        setDailyData(result);

        setRevenueLoading(false);
      } else {
        console.log("Error");
        setRevenueLoading(false);
      }
    } catch (error) {
      console.log(error, "Error");
      setRevenueLoading(false);
    }
  };

  useEffect(() => {
    if (selectedFilter !== "custom") {
      handleApplyRevenuFilter(selectedFilter);
    }

    if (selectedFilter === "custom" && fromDate !== "" && toDate !== "") {
      handleApplyRevenuFilter(selectedFilter, fromDate, toDate);
    }
  }, [fromDate, toDate]);

  // Advisor Revenue PiechartData
  const getPieChartData = (date) => {
    setIsPieChartLoading(true);

    const requestData = {
      user_id: userId,
      date: date,
    };

    fetch(`${API_ENDPOINT}advisordashboard/contribution`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify(requestData),
    })
      .then((response) => response.json())
      .then((data) => {
        console.log(data);
        setPieChartData(data);
        setIsPieChartLoading(false);
      })
      .catch((error) => {
        console.error("Error fetching daily data:", error);
        setIsPieChartLoading(false);
      });
  };

  useEffect(() => {
    getPieChartData(chosenDate);
  }, [chosenDate]);

  const handleDateChange1 = (date) => {
    setChosenDate1(date);

    // getPieChartData(date)
  };

  const getLeadConversionData = (date) => {
    const token = getTokenOrLogout();
    if (!token) return;
    let headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    };
    setLoading(true);

    const requestData = {
      user_id: userId,
      date: date,
    };

    fetch(`${API_ENDPOINT}advisordashboard/lead-conversion`, {
      method: "POST",
      headers,
      body: JSON.stringify(requestData),
    })
      .then((response) => response.json())
      .then((data) => {
        console.log(data);
        setLeadData(data);
        setLoading(false);
      })
      .catch((error) => {
        console.error("Error fetching daily data:", error);
        setLoading(false);
      });
  };

  useEffect(() => {
    getLeadConversionData(chosenDate1);
  }, [chosenDate1]);

  return (
    <>
      <Grid container spacing={3}>
        <Grid item xs={12} lg={9} sm={12} md={9}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Content />
            </Grid>

            {/* Filter Button */}
            <Grid item xs={12} sm={12} mt={2}>
              <FilterButtonComp
                selectedFilter={selectedFilter}
                handleClickFilter={handleClickFilter}
                fromDate={fromDate}
                toDate={toDate}
                setFromDate={setFromDate}
                setToDate={setToDate}
              />
            </Grid>

            <Grid item xs={12} container spacing={2} gap={{ xs: 1, xl: 3 }}>
              <Grid item xs={12} sm={3.5} lg={2.9} xl={2.8}>
                <DataCards
                  backGround="#D5F6E1"
                  title="New Enquiries"
                  iconSrc="/Group 1000002878.png"
                  value={
                    revenueLoading ? (
                      <CircularProgress />
                    ) : (
                      dailyData?.new_enquiry || 0
                    )
                  }
                />
              </Grid>

              <Grid item xs={12} sm={3.5} lg={2.9} xl={2.8}>
                <DataCards
                  backGround="#E7F3FF"
                  title="Demos Scheduled"
                  iconSrc="/Group 1000002854.png"
                  value={
                    revenueLoading ? (
                      <CircularProgress />
                    ) : (
                      dailyData?.demo_scheduled || 0
                    )
                  }
                />
              </Grid>

              <Grid item xs={12} sm={3.7} lg={2.9} xl={2.8}>
                <DataCards
                  backGround="#FFEDFB"
                  title="Enrolled Students"
                  iconSrc="/Group 1000002879.png"
                  value={
                    revenueLoading ? (
                      <CircularProgress />
                    ) : (
                      dailyData?.enrolled_student +
                        dailyData?.prev_month_enrollment || 0
                    )
                  }
                />
              </Grid>

              <Grid item xs={12} sm={3.5} lg={2.9} xl={2.8}>
                <DataCards
                  backGround="#FFF6E2"
                  title="Tasks Assigned"
                  iconSrc="/Group 1000002880.png"
                  value={
                    revenueLoading ? (
                      <CircularProgress />
                    ) : (
                      dailyData?.assigned_task || 0
                    )
                  }
                />
              </Grid>
            </Grid>

            <Grid item xs={12}>
            <NewCardcomp  height="395px" >
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={5.8} lg={5.8}>
                    <LeadToEnrol
                      handleDateChange1={handleDateChange1}
                      leadData={leadData}
                      loading={loading}
                    />
                  </Grid>
                  <Divider
                    orientation="vertical"
                    flexItem
                    sx={{ ml: 2 }} // Add margin for spacing
                  />
                  <Grid item xs={12} sm={5.8} lg={5.8}>
                    <AdvisorNewTarget
                      loading={targetLoading}
                      targetData={targetData}
                    />
                  </Grid>
                </Grid>
              </NewCardcomp>
            </Grid>
          </Grid>
        </Grid>

        <Grid item xs={12} lg={3} sm={12} md={3}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <DisplayCurrentDateTime />
            </Grid>
            <Grid item xs={12}>
              <UpcommingEvents
                data={upcommigevent}
                loading={upcommingLoading}
              />
            </Grid>

            <Grid item xs={12}>
              <NewCardcomp  height="100%" >
                <Typography
                   variant='h6'
                   sx={{
                     
                     textAlign: "center",
                   }}
                >
                  {" "}
                  Contribution to Branch Revenue
                </Typography>
                <Box mt={1}>
                  <LeadCalandar onDateChange={handleDateChange} />
                  {isPieChartLoading ? (
                    <Box
                      display="flex"
                      justifyContent="center"
                      alignItems="center"
                      mt={2}
                    >
                      <CircularProgress />
                    </Box>
                  ) : (
                    <MonthlyAdvisorRevenuePiechart
                      loading={isPieChartLoading}
                      pieChartData={pieChartData}
                    />
                  )}
                </Box>
              </NewCardcomp>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
}
