import { Box, Card, CircularProgress, Divider, Stack, Typography } from '@mui/material'
import React, { useEffect, useState } from 'react'
import LeadCalandar from '../../LeadConversation/LeadCalandar'
import { useGetTokenOrLogout } from '../../../utils/token';
import ReactApexChart from 'react-apexcharts';
import CustomPopup from '../../../components/CustomPopup';
import AllLeadSourceData from './AllLeadSourceData';

const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT;
export default function LeadSourcePichartData() {
    const userId=localStorage.getItem("userId")
           const getTokenOrLogout = useGetTokenOrLogout();
    const [chosenDate, setChosenDate] = useState(new Date());
      const [isPieChartLoading, setIsPieChartLoading] = useState(false);
      const [pieChartData, setPieChartData] = useState({});
      const [openPopup,setOpenPopup] =useState(false)
  const [leadSorceData,setLeadSorceData] =useState([])
  const [selectId,setSelectedId] =useState(null)
  const [sourcename,setSourceName] =useState("")
  const [SubsourceLoading,setSubsourceLoading] =useState(false)


  const handleDateChange = (date) => {
    setChosenDate(date);
};

  // Fetch Pi chart data
  const handleFetchLeadSorceData = async (date) => {
    setIsPieChartLoading(true);
    const token = getTokenOrLogout();
    if (!token) return;
    let headers = {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json", // Make sure to keep the content type
    };
    const requestData = {
      user_id: parseInt(userId),

      date: date,
    };

    try {
      const response = await fetch(
        `${API_ENDPOINT}auditdashboard/donut-chart`,
        {
          method: "POST",
          headers,
          body: JSON.stringify(requestData),
        }
      );

      if (response.ok) {
        const result = await response.json();

        setPieChartData(result);

        setIsPieChartLoading(false);
      } else {
        console.log("Error");
        setIsPieChartLoading(false);
      }
    } catch (error) {
      console.log(error, "Error");
      setIsPieChartLoading(false);
    }
  };

    useEffect(() => {
      handleFetchLeadSorceData(chosenDate);
    }, [chosenDate]);


    const [chartData, setChartData] = useState({
        series: [],
        options: {
          chart: {
            type: "donut",
          },
          plotOptions: {
            pie: {
              donut: {
                size: "50%", // Adjust the size to control the width of the slices
              },
            },
          },
          //   labels: percentages,
          colors: ["#019E6F", "#FABA3A"],
          responsive: [
            {
              breakpoint: 480,
              options: {
                chart: {
                  width: 200,
                },
              },
            },
          ],
          tooltip: {
            enabled: false,
          },
          legend: {
            show: false,
          },
        },
      });
    
      const selectedKeys = ["online", "offline"];
    
      const arr = Object.entries(pieChartData)
        .filter(([key]) => selectedKeys.includes(key))
        .map(([, value]) => value);
    
      console.log(arr);
    
      useEffect(() => {
        setChartData((prevData) => ({
          ...prevData,
          series: arr,
        }));
      }, [pieChartData]);

      const handleOpenPopup =(id, sourceName)=>{
        setOpenPopup(true)
        setSelectedId(id)
        setSourceName(sourceName)
        handleFetchSubLeadSorceData(id)
      }

      const handleClosePopup =()=>{
        setOpenPopup(false)
      }

      const handleFetchSubLeadSorceData = async (selectId) => {
        setSubsourceLoading(true);
        const token = getTokenOrLogout();
        if (!token) return;
        let headers = {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json", // Make sure to keep the content type
        };
        const requestData = {
          user_id: parseInt(userId),
    
          date: chosenDate,
          lead_source_id:selectId
        };
    
        try {
          const response = await fetch(
            `${API_ENDPOINT}auditdashboard/donut-chart-breakdown`,
            {
              method: "POST",
              headers,
              body: JSON.stringify(requestData),
            }
          );
    
          if (response.ok) {
            const result = await response.json();
    
            setLeadSorceData(result);
    
            setSubsourceLoading(false);
          } else {
            console.log("Error");
            setSubsourceLoading(false);
          }
        } catch (error) {
          console.log(error, "Error");
          setSubsourceLoading(false);
        }
      };

      // useEffect(() => {
      //   if(selectId!==null) {
      //   handleFetchSubLeadSorceData(selectId)
      //   }
      // }, [selectId,chosenDate])
      
      const total=pieChartData.online+pieChartData.offline
  return (
<>

 
                <Typography
                variant='h6'
                  sx={{
                    
                    textAlign: "center",
                  }}
                >
                  {" "}
               Lead Source BreakDown
                </Typography>
                <Box mt={1}>
                  <LeadCalandar onDateChange={handleDateChange} />
                  <>
      {isPieChartLoading ? (
        <Box display="flex" justifyContent="center" alignItems="center" mt={2}>
          <CircularProgress />
        </Box>
      ) : 
       (
        <>
          <Stack
            display="flex"
            flexDirection="column"
            justifyContent="center"
            alignItems="center"
          >
           { pieChartData.online===0 && pieChartData.offline===0  ? (
            <Box  display='flex' justifyContent='center' alignItems='center' height={150}>
 <Typography className="picharttexttotal" fontWeight={600}>No Data Available</Typography>
            </Box>
           
           ) : (
            <ReactApexChart
              options={chartData.options}
              series={chartData.series}
              type="donut"
            width="200px"
              
            />
          )

        }
          </Stack>
          <Divider />
          <Stack
            display="flex"
            justifyContent="space-between"
            flexDirection="row"
            mt={1}
          >
            <Typography variant='h6' sx={{color:"#4B5563"}}>Total </Typography>
            <Typography variant='h6' sx={{color:"#4B5563"}}>
          {  total}
            </Typography>
          </Stack>

          <Stack
            display="flex"
            flexDirection="row"
            alignItems="center"
            justifyContent="space-between"
            mt={1}
          >
            <Stack>
              <Box
                sx={{
                  bgcolor: "#01BE85",
                  width: 10,
                  height: 10,
                  borderRadius: "50%",
                }}
              />
            </Stack>
            <Stack
              display="flex"
              flexDirection="row"
              alignItems="center"
              width="100%"
              justifyContent="space-between"
              marginLeft={1}
            >
              <Typography variant='h7' sx={{cursor:"pointer"}} onClick={()=>handleOpenPopup(1,"Online")}>
               Online{" "}
              </Typography>
              <Typography variant='h7'>
                {pieChartData?.online}
              </Typography>
            </Stack>
          </Stack>
          <Stack
            display="flex"
            flexDirection="row"
            alignItems="center"
            justifyContent="space-between"
            mt={1}
          >
            <Stack>
              <Box
                sx={{
                  bgcolor: "#FABA3A",
                  width: 10,
                  height: 10,
                  borderRadius: "50%",
                }}
              />
            </Stack>
            <Stack
              display="flex"
              flexDirection="row"
              alignItems="center"
              width="100%"
              justifyContent="space-between"
              marginLeft={1}
            >
              <Typography variant='h7' sx={{cursor:"pointer"}} onClick={()=>handleOpenPopup(2,"Offline")} >Offline</Typography>
              <Typography variant='h7'>
                {pieChartData?.offline}
              </Typography>
            </Stack>
          </Stack>
        </>
      )}
    </>
                </Box>


                 <CustomPopup
                            open={openPopup}
                            onClose={handleClosePopup}
                         
                            component={<AllLeadSourceData onClose={handleClosePopup} data={leadSorceData} chosenDate={chosenDate} sourcename={sourcename} SubsourceLoading={SubsourceLoading}/>}
                            maxWidth="sm"
                            showDivider={false}
                          />
            
          
</>
  )
}
