import { Grid, Paper, styled, Table, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow, Typography } from '@mui/material';
import React, { useState } from 'react'
import { fDate } from '../../../utils/formatTime';
import StyledTableContainer from '../../../components/TanleContainer';

export default function AllEventcomp({data}) {
 const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);


 

  const startIndex = page * rowsPerPage;
  const endIndex = startIndex + rowsPerPage;
  const paginatedData= data.slice(startIndex, endIndex);
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  return (
<>
<StyledTableContainer component={Paper} background="#abf7b1">
              <Table
                // sx={{ minWidth: 650, tableLayout: "fixed" }}
                aria-label="simple table"
                stickyHeader
              >
                <TableHead>
                  <TableRow>
                    <TableCell>Campaign  Name</TableCell>
                    <TableCell>Start Date</TableCell>
                    <TableCell>End Date</TableCell>
             
                  </TableRow>
                </TableHead>

                {data?.length === 0 ? (
                  <Grid
                    item
                    xs={12}
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <Typography variant="h6">No data available</Typography>
                  </Grid>
                ) : (
                    paginatedData?.map((row, index) => (
                    <TableBody>
                      <TableRow
                        key={row.name}
                        style={{
                          backgroundColor:
                            index % 2 === 0 ? "#f0f0f0" : "inherit",
                        }}
                      >
                        <TableCell
                          component="th"
                          scope="row"
                          sx={{ borderLeft: "0.1px solid #C3C3C3" }}
                        >
                         {row?.campaign_name}
                        </TableCell>
                        <TableCell>  {fDate (row?.campaign_start_date)} </TableCell>
                        <TableCell     sx={{ borderRight: "0.1px solid #C3C3C3" }}>            {fDate (row?.campaign_end_date)} </TableCell>

            
                      </TableRow>
                    </TableBody>
                  ))
                )}
              </Table>
              <TablePagination
              size='0rem'
          rowsPerPageOptions={[5, 10, 25]}
          component="div"
          count={data.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
          sx={{
            '.MuiTablePagination-toolbar': { fontSize: '0.75rem' }, // Adjust toolbar text size
            '.MuiTablePagination-selectLabel, .MuiTablePagination-displayedRows': {
              fontSize: '0.75rem', // Adjust labels and displayed rows text size
            },
            '.MuiTablePagination-select': {
              fontSize: '0.75rem', // Adjust dropdown text size
            },
          }}
        />
            </StyledTableContainer>

</>
  )
}
