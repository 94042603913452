import { Box, CircularProgress, Divider, Stack, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react'
import ReactApexChart from 'react-apexcharts';

export default function OfflineOnlinePaymentPiechart({ loading, pieChartData}) {
     const [chartData, setChartData] = useState({
        series: [70,20],
        options: {
          chart: {
            type: "donut",
          },
          plotOptions: {
            pie: {
              donut: {
                size: "50%", // Adjust the size to control the width of the slices
              },
            },
          },
          //   labels: percentages,
          colors: ["#019E6F", "#FABA3A"],
          responsive: [
            {
              breakpoint: 480,
              options: {
                chart: {
                  width: 200,
                },
              },
            },
          ],
          tooltip: {
            enabled: false,
          },
          legend: {
            show: false,
          },
        },
      });
    
      const selectedKeys = ["offline_payment", "online_payment"];
    
      const arr = Object.entries(pieChartData)
        .filter(([key]) => selectedKeys.includes(key))
        .map(([, value]) => value);
    
      console.log(arr);
    
      useEffect(() => {
        setChartData((prevData) => ({
          ...prevData,
          series: arr,
        }));
      }, [pieChartData]);
  return (
<>
{loading ? (
        <Box display="flex" justifyContent="center" alignItems="center" mt={2}>
          <CircularProgress />
        </Box>
      ) : 
       (
        <>
          <Stack
            display="flex"
            flexDirection="column"
            justifyContent="center"
            alignItems="center"
          >
           { pieChartData.offline_payment===0 && pieChartData.online_payment===0  ? (
            <Box  display='flex' justifyContent='center' alignItems='center' height={150}>
 <Typography className="picharttexttotal" fontWeight={600}>No Data Available</Typography>
            </Box>
           
           ) : (
            <ReactApexChart
              options={chartData.options}
              series={chartData.series}
              type="donut"
            width="200px"
              
            />
          )

        }
          </Stack>
          <Divider />
          <Stack
            display="flex"
            justifyContent="space-between"
            flexDirection="row"
            mt={1}
          >
            <Typography variant='h6' sx={{color:"#4B5563"}}>Total Payment</Typography>
            <Typography variant='h6' sx={{color:"#4B5563"}}>
              {pieChartData?.total_payment}
            </Typography>
          </Stack>

          <Stack
            display="flex"
            flexDirection="row"
            alignItems="center"
            justifyContent="space-between"
            mt={1}
          >
            <Stack>
              <Box
                sx={{
                  bgcolor: "#01BE85",
                  width: 10,
                  height: 10,
                  borderRadius: "50%",
                }}
              />
            </Stack>
            <Stack
              display="flex"
              flexDirection="row"
              alignItems="center"
              width="100%"
              justifyContent="space-between"
              marginLeft={1}
            >
              <Typography variant='h7'>
               Offline Payment{" "}
              </Typography>
              <Typography variant='h7'>
                {pieChartData?.offline_payment}
              </Typography>
            </Stack>
          </Stack>
          <Stack
            display="flex"
            flexDirection="row"
            alignItems="center"
            justifyContent="space-between"
            mt={1}
          >
            <Stack>
              <Box
                sx={{
                  bgcolor: "#FABA3A",
                  width: 10,
                  height: 10,
                  borderRadius: "50%",
                }}
              />
            </Stack>
            <Stack
              display="flex"
              flexDirection="row"
              alignItems="center"
              width="100%"
              justifyContent="space-between"
              marginLeft={1}
            >
              <Typography variant='h7'>Online Payment</Typography>
              <Typography variant='h7'>
                {pieChartData?.online_payment}
              </Typography>
            </Stack>
          </Stack>
        </>
      )}
</>
  )
}
