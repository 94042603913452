import { createTheme } from "@mui/material";

const ToggleButtontheme = createTheme({
    components: {
      MuiToggleButton: {
        styleOverrides: {
          root: {
            fontSize: "0.7rem", // Set the desired font size
            fontWeight: 400,
            textTransform: "none",
          },
        },
      },
    },
  });
  export default  ToggleButtontheme;