import React from 'react';
import Navbar from './Navbar/Navbar';
import './newlayout.css'; // Create a CSS file for styling
import Sidebar from './Sidebar/Sidebar';


function NewLayout({ children }) {
  return (
    <div className="layout">
      {/* Navbar */}
      <header className="layout-navbar">
        <Navbar />
      </header>
      
      {/* Sidebar and Main Content */}
      <div className="layout-body">
        <aside className="layout-sidebar">
        <Sidebar />
        </aside>
        
        <main className="layout-content">
          {children}
        </main>
      </div>
    </div>
  );
}

export default NewLayout;
