import React from "react";
import { Grid, Box, Typography } from "@mui/material";

export default function CircularComponent({ data1,data2,data3 }) {
  return (
    <Grid
      item
      xs={12}
      display="flex"
      alignItems="center"
      justifyContent="center"
      direction="column"
    >
      <Box
        sx={{
          position: "relative",
          width: "180px",
          height: "180px",
          borderRadius: "50%",
          overflow: "hidden",
          padding: 0,
        }}
      >
        <Box
          sx={{
            position: "absolute",
            width: "240px",
            height: "240px",
            borderRadius: "50%",
            overflow: "hidden",
            left: "-30px",
            top: "-40px",
            padding: 0,
          }}
        >
          {/* First Item */}
          <Box
            component="li"
            sx={{
              position: "absolute",
              top: 0,
              left: 0,
              width: "100%",
              height: "100%",
              listStyle: "none",
            }}
          >
            <Box
              sx={{
                position: "absolute",
                top: 0,
                right: 0,
                width: "50%",
                height: "50%",
                transformOrigin: "0% 100%",
                transform: "rotate(0deg) skewY(30deg)",
                background: "#01BE85",
                border: "1px solid white",
              }}
            />
            <Box
            
              sx={{
                position: "absolute",
                zIndex: 30,
                left: "12%",
                top: "35%",
                textAlign: "center",
                width: "80px",
                color: "#fff",
                fontSize: "15px",
                fontWeight: 500,
                fontFamily: "Roboto",
              }}
            >
              <Typography variant="h4" color='#ffffff'>{data1}</Typography>
            </Box>
          </Box>

          {/* Second Item */}
          <Box
            component="li"
            sx={{
              position: "absolute",
              top: 0,
              left: 0,
              width: "100%",
              height: "100%",
              listStyle: "none",
            }}
          >
            <Box
              sx={{
                position: "absolute",
                top: 0,
                right: 0,
                width: "50%",
                height: "50%",
                transformOrigin: "0% 100%",
                transform: "rotate(120deg) skewY(30deg)",
                background: "#FABA3A",
                border: "1px solid white",
              }}
            />
            <Box
         
              sx={{
                position: "absolute",
                zIndex: 30,
                right: "16%",
                top: "35%",
                textAlign: "center",
                width: "80px",
                color: "#fff",
                fontSize: "15px",
                fontWeight: 500,
                fontFamily: "Roboto",
              }}
            >
              <Typography variant="h4" color='#ffffff'>{data2}</Typography>
            </Box>
          </Box>

          {/* Third Item */}
          <Box
            component="li"
            sx={{
              position: "absolute",
              top: 0,
              left: 0,
              width: "100%",
              height: "100%",
              listStyle: "none",
            }}
          >
            <Box
              sx={{
                position: "absolute",
                top: 0,
                right: 0,
                width: "50%",
                height: "50%",
                transformOrigin: "0% 100%",
                transform: "rotate(240deg) skewY(30deg)",
                background: "#FF4B4B",
                border: "1px solid white",
              }}
            />
            <Box
              component="div"
           
              sx={{
                position: "absolute",
                zIndex: 30,
                bottom: "26%",
                left: "33%",
                textAlign: "center",
                width: "80px",
                color: "#fff",
                fontSize: "20px",
                fontWeight: 500,
                fontFamily: "Roboto",
              }}
            >
              <Typography variant="h4" color='#ffffff'>{data3}</Typography>
            </Box>
          </Box>
        </Box>
      </Box>
    </Grid>
  );
}
