import React, { useEffect, useState } from 'react'
import CustomSnackbar from '../../components/ui-component/snackbar/CustomSnackbar';
import { useNavigate } from 'react-router-dom';
import { useDateUtils } from '../../../utils/formatTime';
import { Autocomplete, Backdrop, Button, CircularProgress, Grid, Paper, Stack, styled, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField, Typography } from '@mui/material';
import DownloadIcon from '@mui/icons-material/Download';
import SearchIcon from '@mui/icons-material/Search';
import MainCard from "../../../components/Maincard";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT;
export default function AdvisorTaskWiseReport() {
  const userRole = localStorage.getItem("userRole");
  const userId = localStorage.getItem("userId");
  const navigate = useNavigate();
  const [monthList, setMonthList] = useState([]);
  const [monthname, setMonthName] = useState(null);
  const [selectedYear, setSelectedYear] = useState({ year:new Date().getFullYear() });
  const [branchList, setBranchList] = useState([]);
  const [advisorList, setAdvisorList] = useState([]);
  const [advisorName, setAdvisorName] = useState([
    { id: "all", first_name: "Select All" },
  ]);
  const [branchName, setBranchName] = useState([
    { id: "all", branch_name: "Select All" },
  ]);
  const [selectedBranchId, setSelectedBranchId] = useState(["all"]);
  const [revenueData, setRevenueData] = useState([]);
  const { currentMonthName } = useDateUtils();
  const [loading, setLoading] = useState(false);
  const [currentMonthIndex, setCurrentMonthIndex] = useState(0);
  const [snackbarOpen, setSnackbarOpen] = React.useState(false);
  const [snackbarMessage, setSnackbarMessage] = React.useState("");
  const [snackbarSeverity, setSnackbarSeverity] = React.useState("success");
  const showSnackbar = (message, severity) => {
    setSnackbarMessage(message);
    setSnackbarSeverity(severity);
    setSnackbarOpen(true);
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(`${API_ENDPOINT}month`);
        if (response.ok) {
          const data = await response.json();

          setMonthList(data);
          const currentIndex = data.findIndex(
            (month) => month.month_full_name === currentMonthName
          );

          setCurrentMonthIndex(currentIndex !== -1 ? currentIndex : 0);
          setMonthName(data[currentIndex].month_full_name);
        } else {
          throw new Error(`Request failed with status: ${response.status}`);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };
    fetchData();
  }, []);

  console.log("monthname", monthname);

  const handleNextMonth = () => {
    if (currentMonthIndex < monthList.length - 1) {
      const nextIndex = currentMonthIndex + 1;
      setCurrentMonthIndex(nextIndex);
      const monthName = monthList[nextIndex].month_full_name;
      setMonthName(monthName);
      handleSearchData(monthName);
    }
  };

  const handlePreviousMonth = () => {
    if (currentMonthIndex > 0) {
      const prevIndex = currentMonthIndex - 1;
      setCurrentMonthIndex(prevIndex);
      const monthName = monthList[prevIndex].month_full_name;
      setMonthName(monthName);
      handleSearchData(monthName);
    }
  };

  const years = Array.from({ length: 5 }, (_, index) => ({
    year: new Date().getFullYear() - 3 + index,
  }));

  const handleselectedYear = async (value) => {
    setSelectedYear(value);
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(`${API_ENDPOINT}branch`);
        if (response.ok) {
          const data = await response.json();
          setBranchList(data);
        } else {
          throw new Error(`Request failed with status: ${response.status}`);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };
    fetchData();
  }, []);

  const [allBranchList, setAllBranchlist] = useState([
    { id: "all", branch_name: "Select All" },
    ...(branchList || []),
  ]);
  useEffect(() => {
    if (Array.isArray(branchList)) {
      setAllBranchlist([
        { id: "all", branch_name: "Select All" },
        ...branchList,
      ]);
    }
  }, [branchList]);

  const handleBranchChange = (event, value) => {
    console.log("valueis", value);

    if (value.some((item) => item.id === "all")) {
      // If "Select All" is selected, set all branches
      setBranchName([{ id: "all", branch_name: "Select All" }]);
      setSelectedBranchId(value.map((i) => i.id));
    } else {
      // If individual branches are selected, remove "Select All"
      setBranchName(value);
      setSelectedBranchId(value.map((i) => i.id));
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(`${API_ENDPOINT}route/getalluser`);
        if (response.ok) {
          const data = await response.json();

          setAdvisorList(data);
        } else {
          throw new Error(`Request failed with status: ${response.status}`);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };
    fetchData();
  }, []);

  const [allAdvisorList, setAllAdvisorList] = useState([
    { id: "all", first_name: "Select All" },
    ...(advisorList || []),
  ]);

  useEffect(() => {
    if (Array.isArray(advisorList)) {
      setAllAdvisorList([
        { id: "all", first_name: "Select All" },
        ...advisorList,
      ]);
    }
  }, [advisorList]);

  const handleUserChange = (event, value) => {
    setAdvisorName(value);

    if (value.some((item) => item.id === "all")) {
      // If "Select All" is selected, set all branches
      setAdvisorName([{ id: "all", first_name: "Select All" }]);
    } else {
      // If individual branches are selected, remove "Select All"
      setAdvisorName(value);
    }
  };

  // Select Advisor Automatic base on Branch
  useEffect(() => {
    if (!Array.isArray(advisorList)) {
      console.error("advisorList is not an array:", advisorList);
      return;
    }

    if (selectedBranchId.includes("all")) {
      setAdvisorName([{ first_name: "Select All", id: "all" }]);
    } else {
      const branchViseAdvisor = advisorList.filter((advisor) =>
        selectedBranchId.includes(advisor.branch)
      );
      setAdvisorName(branchViseAdvisor);
    }
  }, [selectedBranchId, advisorList]);

  const StyledTableContainer = styled(TableContainer)(({ theme }) => ({
    marginTop: "20px",
    "& .MuiTableCell-head": {
      backgroundColor: "#abf7b1",
      fontWeight: 600,
      padding: "8px 16px",
      fontSize: "17px",
      color: "#000",
      textAlign: "center",
      fontFamily: "Poppins",
       borderRight: "0.1px solid #C3C3C3",
    },
    "& .MuiTableCell-body": {
      textAlign: "center",
      padding: "8px 16px",
      overflow: "hidden", // Hide overflowing content
      textOverflow: "ellipsis", // Show ellipsis when content overflows
    
      borderRight: "0.1px solid #C3C3C3",
      whiteSpace: "normal", // Allow text to wrap inside the cell
      wordWrap: "break-word",
    },
  }));

  const selectedAdvisor = advisorName.map((i) => i.id);

  const handleSearchData = async (month) => {
    setLoading(true);

    const requestData = {
      user_id: parseInt(userId),
      year: selectedYear?.year,
      report_for:
        userRole === "center_admin" && selectedAdvisor.includes("all")
          ? "all"
          : userRole === "center_admin" && !selectedAdvisor.includes("all")
          ? selectedAdvisor
          : null,
      month: month,
    };

    try {
      const response = await fetch(
        `${API_ENDPOINT}advisor-rev-target/advisor-report`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(requestData),
        }
      );

      if (response.ok) {
        const result = await response.json();
        console.log(result)
        const data=result?.data

       

       
      
        setRevenueData(Object.entries(data));
        setLoading(false);
      } else if (response.status === 404) {
        navigate("/page404");
      } else if (response.status === 401) {
        navigate("/page401");
      } else if (response.status === 403) {
        showSnackbar("Please select user", "error");
        setRevenueData([]);
        setLoading(false);
      } else if (response.status === 440) {
        navigate("/page440");
      } else if (response.status === 400) {
        showSnackbar("Please select year", "error");
        setRevenueData([]);
        setLoading(false);
      } else {
        navigate("/page500");
      }
    } catch (error) {
      navigate("/page500");
    }
  };

  const labelMap = {
    TotalTask: "Total Task",
    CallsConnected: "Calls Connected",
    CallsMade: "Calls Made",
    VirtualAppointment: "Virtual Appointment",
    BranchVisit: "Branch Visit",
    RevenueCollected: "Revenue Collected",
    DemoCompleted: "Demo Completed",
    Enrollment: "Enrollment",
    LeadAssigned: "Lead Assigned",
    LeadConverted: "Lead Converted",
  };

  useEffect(() => {
    handleSearchData(currentMonthName);
  }, []);

  const handleView = () => {
    const url = `/view-task-wise-report-pdf`;
    window.open(url, "_blank");
     const targetDataObject = {
      revenueData,
      advisorName,
      selectedYear,
      monthname
    };
    localStorage.setItem("targetdata", JSON.stringify(targetDataObject));
  };

  return (
<>
<Backdrop open={loading} style={{ zIndex: 9999 }}>
        <Stack direction="row" spacing={1}>
          <Typography
            variant="h4"
            color="secondary"
            fontWeight={800}
            marginRight={2}
          >
            Fetching
          </Typography>
          <CircularProgress color="primary" />
        </Stack>
      </Backdrop>

      <Grid container spacing={2}>
        <Grid item xs={12} sm={6}>
          <Paper
            sx={{
              width: "350px",
              fontSize: "22px",
              fontWeight: 600,
              height: "43px",
              borderRadius: "25px",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <img
              src="/task.png"
              alt="img"
              style={{ marginRight: "5px", width: "30px" }}
            />
        Task Wise  Report
          </Paper>
        </Grid>
        {userRole === "center_admin" && (
          <Grid item xs={12} sm={6} textAlign="end">
            <Button
              color="secondary"
              size="small"
              variant="contained"
              style={{ borderRadius: "25px" }}
              startIcon={<DownloadIcon />}
               onClick={handleView}
            >
              Download Report
            </Button>
          </Grid>
        )}

<Grid item xs={12}>
          <MainCard>
            <Grid container spacing={2}>
              {userRole === "center_admin" && (
                <>
                  <Grid item xs={12} sm={3}>
                    <Typography >Select Branch</Typography>
                    <Autocomplete
                      size="small"
                      value={branchName}
                      multiple
                      onChange={handleBranchChange}
                      options={allBranchList}
                      getOptionLabel={(option) =>
                        option.id === "all"
                          ? "All Selected"
                          : option.branch_name
                      }
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          InputProps={{
                            ...params.InputProps,
                            style: { borderRadius: "14px" }, // Add borderRadius here
                          }}
                         
                          size="small"
                        />
                      )}
                     
                      
                      isOptionEqualToValue={(option, value) =>
                        option.id === value.id
                      }
                      renderOption={(props, option, { selected }) => (
                        <li
                          {...props}
                          style={{
                            backgroundColor: selected ? "#e0f7fa" : "inherit",
                          }}
                        >
                          {option.branch_name}
                        </li>
                      )}
                    />
                  </Grid>

                  <Grid item xs={12} sm={3}>
                    <Typography >Select User</Typography>
                    <Autocomplete
                      size="small"
                      value={advisorName}
                      multiple
                      onChange={handleUserChange}
                      options={allAdvisorList}
                      getOptionLabel={(option) =>
                        option.id === "all"
                          ? "All Selected"
                          : `${option.first_name ? option.first_name : ""} ${
                              option.last_name ? option.last_name : ""
                            }`
                      }
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          InputProps={{
                            ...params.InputProps,
                            style: { borderRadius: "14px" }, // Add borderRadius here
                          }}
                         
                        />
                      )}
                      
                      isOptionEqualToValue={(option, value) =>
                        option.id === value.id
                      }
                      renderOption={(props, option, { selected }) => {
                        console.log("selected", selected);
                        return (
                          <li
                            {...props}
                            style={{
                              backgroundColor: selected ? "#e0f7fa" : "inherit",
                            }}
                          >
                            {option.first_name} {option.last_name}
                          </li>
                        );
                      }}
                    />
                  </Grid>
                </>
              )}

              <Grid item xs={12} sm={3}>
                <Typography >Select Year</Typography>
                <Autocomplete
                  value={selectedYear}
                  onChange={(event, value) => handleselectedYear(value)}
                  size="small"
                  options={years}
                  getOptionLabel={(option) => option.year}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      InputProps={{
                        ...params.InputProps,
                        style: { borderRadius: "14px" }, // Add borderRadius here
                      }}
                      size="small"
                     
                    />
                  )}
                
                />
              </Grid>
              <Grid item xs={12} sm={3}>
                <Typography sx={{ visibility: "hidden" }}>
                  Spacing purpose
                </Typography>
                <Button
                  variant="contained"
                  color="secondary"
                  size="small"
                  style={{
                    borderRadius: "25px",
               
                  }}
                  onClick={() => handleSearchData(monthname)}
                  startIcon={<SearchIcon />}
                >
                  Search
                </Button>
</Grid>
</Grid>
</MainCard>
</Grid>
<Grid item xs={12} >
  <MainCard>
  <Stack
              display="flex"
              flexDirection="row"
              style={{ display: "flex", justifyContent: "space-between" }}
            >
              <Typography
                style={{
                  cursor: "pointer",
                  display: "inline-flex", // Align content inside
                  justifyContent: "center",
                  alignItems: "center",
                  width: "30px", // Set width and height for circle
                  height: "30px",
                  borderRadius: "50%", // Makes the element circular
                  backgroundColor: "#00C853", // Optional: add background color
                  border: "1px solid #ccc", // Optional: add border

                  opacity: currentMonthIndex === 0 ? 0.5 : 1,
                }}
                onClick={handlePreviousMonth}
              >
                <ArrowBackIcon style={{ color: "white" }} />
              </Typography>

              <Typography sx={{ fontWeight: 600, fontSize: "20px" }}>
                {monthname}
              </Typography>
              <Typography
                style={{
                  cursor: "pointer",
                  display: "inline-flex", // Align content inside
                  justifyContent: "center",
                  alignItems: "center",
                  width: "30px", // Set width and height for circle
                  height: "30px",
                  borderRadius: "50%", // Makes the element circular
                  backgroundColor: "#00C853", // Optional: add background color
                  border: "1px solid #ccc", // Optional: add border
                  opacity: currentMonthIndex === monthList.length - 1 ? 0.5 : 1,
                }}
                onClick={handleNextMonth}
              >
                <ArrowForwardIcon style={{ color: "white" }} />
              </Typography>
            </Stack>
            <StyledTableContainer component={Paper}>
              <Table
                sx={{ minWidth: 650, tableLayout: "fixed" }}
                aria-label="simple table"
                stickyHeader
              >
                <TableHead>
                  <TableRow>
                    <TableCell   sx={{ borderLeft: "0.1px solid #C3C3C3" }}>Task Category</TableCell>
                    <TableCell>Measure</TableCell>
                   
                  </TableRow>
                </TableHead>
                {/* {revenueData.length === 0 ? (
                <TableRow>
              <TableCell colSpan={2} >
                <Typography variant="h5">No data available</Typography>
              </TableCell>
            </TableRow>
                ) : ( */}
                  {revenueData.map(([key, value], index) => (
                    <TableBody>
                      <TableRow
                        key={key}
                        style={{
                          backgroundColor:
                            index % 2 === 0 ? "#f0f0f0" : "inherit",
                        }}
                      >
                        <TableCell
                          component="th"
                          scope="row"
                          sx={{ borderLeft: "0.1px solid #C3C3C3" }}
                        >
                            {labelMap[key] || key}
                        </TableCell>
                        <TableCell>{value}</TableCell>
                        
                      </TableRow>
                    </TableBody>
                  ))
                }
              </Table>
            </StyledTableContainer>
  </MainCard>
</Grid>
        </Grid>

        <CustomSnackbar
        open={snackbarOpen}
        onClose={() => setSnackbarOpen(false)}
        message={snackbarMessage}
        severity={snackbarSeverity}
      />

</>
  )
}
