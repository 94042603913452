import {
  Box,
  Button,
  Card,
  Checkbox,
  CircularProgress,
  Grid,
  ListItemText,
  Menu,
  MenuItem,
  Pagination,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import ReactApexChart from "react-apexcharts";
import NewCardcomp from "../../../components/card/NewCardcomp";

const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT;
export default function BranchTaskTrackBarChart({ taskData, taskLoading }) {
  console.log("taskData", taskData);
  const pageSize = 5; // Number of data points to show per page
  const [currentIndex, setCurrentIndex] = useState(0); // Tracks the current pagination index
  const [branchList, setBranchList] = useState([]);
  const [selectedBranches, setSelectedBranches] = useState([]);
  const [anchorEl, setAnchorEl] = useState(null);
  const [filterdData, setFilterdData] = useState([]);

  const handleFilterButtonClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleFilterClose = () => {
    setAnchorEl(null);
  };

  useEffect(() => {
    // Fetch menu items using the fetch function
    fetch(`${API_ENDPOINT}branch`)
      .then((response) => response.json())
      .then((data) => {
        setBranchList(data);
        console.log(data);
      })
      .catch((error) => {
        console.error("Error fetching branches:", error);
      });
  }, []);
  const allBranchList = [
    {
      id: 0,
      branch_name:
        selectedBranches?.length === branchList?.length &&
        branchList?.length > 0
          ? "Deselect All"
          : "Select All",
    },
    ...branchList,
  ];

  const handleBranchSelection = (branchId) => {
    if (branchId === 0) {
      // "Select All" logic
      if (selectedBranches.length === branchList.length) {
        // Deselect all if already all selected
        setSelectedBranches([]);
      } else {
        // Select all branches
        const branchIds = branchList.map((branch) => branch.id);
        setSelectedBranches(branchIds);
      }
    } else {
      // Select/Deselect individual branch
      setSelectedBranches((prevSelectedBranches) => {
        if (prevSelectedBranches.includes(branchId)) {
          return prevSelectedBranches.filter((id) => id !== branchId);
        } else {
          return [...prevSelectedBranches, branchId];
        }
      });
    }
  };

  useEffect(() => {
    const filterdData = taskData.filter((item) =>
      selectedBranches.includes(item.id)
    );
    setFilterdData(filterdData);
  }, [selectedBranches, taskData]);

  const [chartData, setChartData] = useState({
    categories: [],
    notStarted: [],
    overdue: [],
  });
  const [state, setState] = useState({
    series: [
      { name: "Not Started Tasks ", data: [] },
      { name: "Overdue Tasks", data: [] },
    ],
    options: {
      chart: { type: "bar", height: 350 },
      plotOptions: {
        bar: {
          horizontal: false,
          columnWidth: "15px",
          borderRadiusApplication: "end",
          barSpacing: 35,
          borderRadius: 4,
          borderRadiusApplication: "end",
        },
      },
      colors: ["#00B13F", "#F1AA00"],
      dataLabels: { enabled: false },
      stroke: { show: true, width: 2, colors: ["transparent"] },
      xaxis: {
        categories: [],
        labels: { style: { fontSize: "9px" } },
      },
      legend: {
        show: true,
        position: "bottom", // Adjust this to 'bottom', 'left', or 'right' as needed
        horizontalAlign: "center", // Align the legend horizontally
        offsetY: 10, // Adjust the vertical spacing
        fontSize: "10px",
        labels: {
          colors: "#000", // Legend label color
        },
      },
      yaxis: { title: { text: "Tasks" } },
      fill: { opacity: 1 },
      tooltip: {
        y: { formatter: (val) => `${val}` },
      },
    },
  });

  useEffect(() => {
    const activeData = selectedBranches.length === 0 ? taskData : filterdData;

    if (activeData && activeData.length > 0) {
      const categories = activeData.map((item) => item.branch_name);
      const notStarted = activeData.map((item) => item.not_started);
      const overdue = activeData.map((item) => item.over_due);

      setChartData({ categories, notStarted, overdue });
      if (currentIndex >= activeData.length) {
        setCurrentIndex(0); // Reset to the first page
      }
    }
  }, [selectedBranches, taskData, filterdData]);

  useEffect(() => {
    const currentData = {
      categories: chartData.categories.slice(
        currentIndex,
        currentIndex + pageSize
      ),
      notStarted: chartData.notStarted.slice(
        currentIndex,
        currentIndex + pageSize
      ),
      overdue: chartData.overdue.slice(currentIndex, currentIndex + pageSize),
    };

    setState((prevState) => ({
      ...prevState,
      series: [
        { name: "Not Started Tasks", data: currentData.notStarted },
        { name: "Overdue Tasks", data: currentData.overdue },
      ],
      options: {
        ...prevState.options,
        xaxis: {
          ...prevState.options.xaxis,
          categories: currentData.categories,
        },
      },
    }));
  }, [chartData, currentIndex]);

  const totalPages = Math.ceil(chartData.categories.length / pageSize);

  return (
    <>
      <NewCardcomp>
        <Typography
          variant="subtitle2"
          sx={{ color: "#000000", textAlign: "center" }}
        >
          Tasks Status Breakdown
        </Typography>
      </NewCardcomp>
      {taskLoading ? (
        <Box display="flex" justifyContent="center" alignItems="center" mt={2}>
          <CircularProgress />
        </Box>
      ) : (
        <Box mt={2} sx={{ background: "#F9F9F9", borderRadius: "14px" }}>
          <Grid container>
            <Grid item xs={12} textAlign="end" mt={1} mr={1}>
              <Box style={{ position: "relative" }}>
                <Button
                  variant="contained"
                  size="small"
                  style={{
                    fontSize: "9px",
                    fontWeight: 400,
                    fontFamily: "Roboto",
                    color: "#1F1F1F",
                    background: "white",

                    zIndex: 1,
                    borderRadius: "5px",
                  }}
                  startIcon={<img src="/dashboardimages/Group 8922 (1).png" />}
                  onClick={handleFilterButtonClick}
                >
                  Show All Centers
                </Button>

                <Menu
                  anchorEl={anchorEl}
                  open={Boolean(anchorEl)}
                  onClose={handleFilterClose}
                  sx={{
                    "& .MuiPaper-root": {
                      maxHeight: "300px", // Maximum height for scrolling
                      overflowY: "auto", // Enable scrolling
                      minWidth: "200px", // Ensure menu width is reasonable
                    },
                  }}
                  anchorOrigin={{
                    vertical: "bottom", // Align below the button
                    horizontal: "left", // Align left edge of the menu to the button
                  }}
                  transformOrigin={{
                    vertical: "top", // Start menu alignment from the top
                    horizontal: "left", // Align left edge of the menu
                  }}
                >
                  {allBranchList.map((branch) => (
                    <MenuItem
                      key={branch.id}
                      onClick={() => handleBranchSelection(branch.id)}
                      sx={{
                        fontSize: "10px",
                        height: "40px",
                      }}
                    >
                      <Checkbox
                        size="small"
                        checked={
                          branch.id === 0
                            ? selectedBranches.length === branchList.length &&
                              branchList.length > 0
                            : selectedBranches.includes(branch.id)
                        }
                        indeterminate={
                          branch.id === 0 &&
                          selectedBranches.length > 0 &&
                          selectedBranches.length < branchList.length
                        }
                        onClick={(event) => {
                          event.stopPropagation(); // Prevent MenuItem click from firing
                          handleBranchSelection(branch.id);
                        }}
                        sx={{
                          "& .MuiSvgIcon-root": { fontSize: "16px" },
                        }}
                      />
                      <ListItemText
                        primary={branch.branch_name}
                        primaryTypographyProps={{ style: { fontSize: "10px" } }}
                      />
                    </MenuItem>
                  ))}
                </Menu>
              </Box>
            </Grid>

            <Grid item xs={12}>
              <ReactApexChart
                options={state.options}
                series={state.series}
                type="bar"
                height={260}
              />
            </Grid>
            <Grid item xs={12}>
              <Box>
                <Pagination
                  size="small"
                  count={totalPages}
                  page={Math.ceil(currentIndex / pageSize) + 1}
                  onChange={(event, value) => {
                    const newIndex = (value - 1) * pageSize;
                    setCurrentIndex(newIndex);
                  }}
                  color="secondary"
                  sx={{
                    justifyContent: "end",
                    display: "flex",
                    "& .MuiPaginationItem-root": {
                      fontSize: "0.6rem",
                    },
                  }}
                />
              </Box>
            </Grid>
          </Grid>
        </Box>
      )}
    </>
  );
}
