import {
  Box,
  Button,
  Checkbox,
  CircularProgress,
  Grid,
  ListItemText,
  Menu,
  MenuItem,
  Stack,
  ThemeProvider,
  ToggleButton,
  ToggleButtonGroup,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import AdminDetailsStat from "./AdminDetailsStat";
import AdminRevenuTabs from "../../AdminDashboardRevenue/AdminRevenuTabs";
import AdminRevenueStat from "./AdminRevenueStat";
import AdminEnrolStat from "./AdminEnrolStat";
import CustomPopup from "../../../components/CustomPopup";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import NewCardcomp from "../../../components/card/NewCardcomp";
import { useGetTokenOrLogout } from "../../../utils/token";
import ToggleButtontheme from "../Components/TogglebuttonTheme";
const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT;
export default function AdminRevenueAndEnrolData({

  
  isPopup = false,
  statsFilteris,
}) {
  const userId=localStorage.getItem("userId")
     const getTokenOrLogout = useGetTokenOrLogout();
  const [statsFilter, setStatsFilter] = useState(
    !isPopup ? "details" : statsFilteris
  );
  const [yearlyEnrolAndRevenuData,setYearlyEnrolAndRevenuData] =useState([])
  const [expandPopup, setExpandPopup] = useState(false);
  const [monthList, setMonthList] = useState([]);
  const [selectedMonth, setSelectedMonth] = useState([]);
  const [anchorEl, setAnchorEl] = useState(null);
  const [filterdData, setFilterdData] = useState([]);
  const currentYear = new Date().getFullYear();
  const [choosenYear, setChoonYear] = useState(currentYear);
  const [loading, setLoading] =useState(false)
  const handleApplyStatsFilter = (criteria) => {
    setStatsFilter(criteria);
  };

  const handlopenExpand = () => {
    setExpandPopup(!expandPopup);
  };
  const handlCloseExpand = () => {
    setExpandPopup(!expandPopup);
  };

  const handleFilterButtonClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleFilterClose = () => {
    setAnchorEl(null);
  };

  useEffect(() => {
    // Fetch menu items using the fetch function
    fetch(`${API_ENDPOINT}month`)
      .then((response) => response.json())
      .then((data) => {
        setMonthList(data);
        console.log(data);
      })
      .catch((error) => {
        console.error("Error fetching branches:", error);
      });
  }, []);

  const allMonthList = [
    {
      id: 0,
      month_full_name:
        selectedMonth?.length === monthList?.length && monthList?.length > 0
          ? "Deselect All"
          : "Select All",
    },
    ...monthList,
  ];

  const handleBranchSelection = (monthId) => {
    if (monthId === 0) {
      // "Select All" logic
      if (selectedMonth.length === monthList.length) {
        // Deselect all if already all selected
        setSelectedMonth([]);
      } else {
        // Select all branches
        const branchIds = monthList.map((month) => month.id);
        setSelectedMonth(branchIds);
      }
    } else {
      // Select/Deselect individual branch
      setSelectedMonth((prevSelectemonth) => {
        if (prevSelectemonth.includes(monthId)) {
          return prevSelectemonth.filter((id) => id !== monthId);
        } else {
          return [...prevSelectemonth, monthId];
        }
      });
    }
  };

  useEffect(() => {
    const filterdData = yearlyEnrolAndRevenuData.filter((item) =>
      selectedMonth.includes(item.id)
    );
    setFilterdData(filterdData);
  }, [selectedMonth, yearlyEnrolAndRevenuData]);

  const handlePrevYear = () => {
    if (choosenYear > new Date().getFullYear() - 5) {
      setChoonYear(choosenYear - 1);
    }
  };

  const handleNextYear = () => {
    if (choosenYear < new Date().getFullYear()) {
      setChoonYear(choosenYear + 1);
    }
  };

    // fetch Yearlyy enrol And Revenue Data
    const fetchYearlyyenrolAndRevenue = async (choosenYear) => {
      setLoading(true);
      const token = getTokenOrLogout();
      if (!token) return;
      let headers = {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json", // Make sure to keep the content type
      };
      const requestData = {
        user_id: parseInt(userId),
        year:choosenYear
        
      };
  
      try {
        const response = await fetch(
          `${API_ENDPOINT}admindashboard/revenue-enroll-stats`,
          {
            method: "POST",
            headers,
            body: JSON.stringify(requestData),
          }
        );
  
        if (response.ok) {
          const result = await response.json();
  
          setYearlyEnrolAndRevenuData(result);
  
          setLoading(false);
        } else {
          console.log("Error");
          setLoading(false);
        }
      } catch (error) {
        console.log(error, "Error");
        setLoading(false);
      }
    };

    useEffect(() => {
      fetchYearlyyenrolAndRevenue(choosenYear)
    }, [choosenYear])
    
  return (
    <>
      <Grid container>
        <Grid item xs={12}>
          <Grid container alignItems="center">
            <Grid item xs={12} sm={4} lg={3}>
              <NewCardcomp>
                <Stack
                  display="flex"
                  flexDirection="row"
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  <Typography
                    onClick={handlePrevYear}
                    style={{
                      cursor:
                        choosenYear > currentYear - 5
                          ? "pointer"
                          : "not-allowed",
                      opacity: choosenYear > currentYear - 5 ? 1 : 0.5,
                    }}
                  >
                    <ArrowBackIosIcon
                      fontSize="small"
                      style={{ fontSize: "15px" }}
                    />
                  </Typography>
                  <Typography
                    style={{
                      fontFamily: "Roboto",
                      fontWeight: 500,
                      fontSize: "13px",
                      color: "#000000",
                    }}
                  >
                    {choosenYear}
                  </Typography>
                  <Typography
                    onClick={handleNextYear}
                    style={{
                      cursor:
                        choosenYear < currentYear ? "pointer" : "not-allowed",
                      opacity: choosenYear < currentYear ? 1 : 0.5,
                    }}
                  >
                    <ArrowForwardIosIcon
                      fontSize="small"
                      style={{ fontSize: "15px" }}
                    />
                  </Typography>
                </Stack>
              </NewCardcomp>
            </Grid>

            <Grid item xs={12} sm={8} textAlign="end" lg={9}>
              <Stack direction="row" justifyContent="flex-end" mt={1} gap={1}>
              <ThemeProvider theme={ToggleButtontheme}>
                <ToggleButtonGroup
                  size="small"
                  exclusive
                  style={{ height: "20px" }}
                >
                  <ToggleButton
              
                    onClick={() => handleApplyStatsFilter("revenuestat")}
                    style={{
                      background:
                        statsFilter === "revenuestat" ? "#00B13F" : "",
                      color:
                        statsFilter === "revenuestat" ? "#FFFFFF" : "#4b4b4b",
                    }}
                  >
                    Revenue Stats
                  </ToggleButton>
                  <ToggleButton
                   
                    onClick={() => handleApplyStatsFilter("enrolstat")}
                    style={{
                      background: statsFilter === "enrolstat" ? "#00B13F" : "",
                      color:
                        statsFilter === "enrolstat" ? "#FFFFFF" : "#4b4b4b",
                    }}
                  >
                    Enrolment Stats
                  </ToggleButton>
                  <ToggleButton
                
                    onClick={() => handleApplyStatsFilter("details")}
                    style={{
                      background: statsFilter === "details" ? "#00B13F" : "",
                      color: statsFilter === "details" ? "#FFFFFF" : "#4b4b4b",
                    }}
                  >
                    Details
                  </ToggleButton>
                </ToggleButtonGroup>
                </ThemeProvider>

                {!isPopup && (
                  <img
                    src="/dashboardimages/expand1.png"
                    alt="Expand"
                    style={{ cursor: "pointer" }}
                    onClick={handlopenExpand}
                  />
                )}
              </Stack>
            </Grid>
          </Grid>
        </Grid>

        {loading ? (
          <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            mt={2}
            width="100%"
          >
            <CircularProgress size="2rem" />
          </Box>
        ) : (
          <Box
            sx={{
              background: "#F9F9F9",
              width: "100%",
              borderRadius: "14px",
            }}
            mt={1}
          >
            <Grid item xs={12} textAlign="end" mt={1} mr={1}>
              <div style={{ position: "relative" }}>
                <Button
                  variant="contained"
                  size="small"
                  style={{
                    fontSize: "9px",
                    fontWeight: 400,
                    fontFamily: "Roboto",
                    color: "#1F1F1F",
                    background: "white",

                    zIndex: 1,
                    borderRadius: "5px",
                  }}
                  startIcon={<img src="/dashboardimages/Group 8922 (1).png" />}
                  onClick={handleFilterButtonClick}
                >
              Months Filter
                </Button>

                <Menu
                  anchorEl={anchorEl}
                  open={Boolean(anchorEl)}
                  onClose={handleFilterClose}
                  sx={{
                    "& .MuiPaper-root": {
                      maxHeight: "300px", // Maximum height for scrolling
                      overflowY: "auto", // Enable scrolling
                      minWidth: "200px", // Ensure menu width is reasonable
                    },
                  }}
                  anchorOrigin={{
                    vertical: "bottom", // Align below the button
                    horizontal: "left", // Align left edge of the menu to the button
                  }}
                  transformOrigin={{
                    vertical: "top", // Start menu alignment from the top
                    horizontal: "left", // Align left edge of the menu
                  }}
                >
                  {allMonthList.map((month) => (
                    <MenuItem
                      key={month.id}
                      onClick={() => handleBranchSelection(month.id)}
                      sx={{
                        fontSize: "10px",
                        height: "40px",
                      }}
                    >
                      <Checkbox
                        size="small"
                        checked={
                          month.id === 0
                            ? selectedMonth.length === monthList.length &&
                              monthList.length > 0
                            : selectedMonth.includes(month.id)
                        }
                        indeterminate={
                          month.id === 0 &&
                          selectedMonth.length > 0 &&
                          selectedMonth.length < monthList.length
                        }
                        onClick={(event) => {
                          event.stopPropagation(); // Prevent MenuItem click from firing
                          handleBranchSelection(month.id);
                        }}
                        sx={{
                          "& .MuiSvgIcon-root": { fontSize: "16px" },
                        }}
                      />
                      <ListItemText
                        primary={month.month_full_name}
                        primaryTypographyProps={{ style: { fontSize: "10px" } }}
                      />
                    </MenuItem>
                  ))}
                </Menu>
              </div>
            </Grid>

            <Grid item xs={12}>
              {statsFilter === "details" && (
                <AdminDetailsStat
                  data={
                    selectedMonth.length === 0 ? yearlyEnrolAndRevenuData : filterdData
                  }
                  isPopup={isPopup}
                  handlopenExpand={handlopenExpand}
                  choosenYear={choosenYear}
                />
              )}
              {statsFilter === "revenuestat" && (
                <AdminRevenueStat
                  data={
                    selectedMonth.length === 0 ? yearlyEnrolAndRevenuData : filterdData
                  }
                />
              )}
              {statsFilter === "enrolstat" && (
                <AdminEnrolStat
                  data={
                    selectedMonth.length === 0 ? yearlyEnrolAndRevenuData : filterdData
                  }
                />
              )}
            </Grid>
          </Box>
        )}
      </Grid>

      <CustomPopup
        open={expandPopup}
        onClose={handlCloseExpand}
        component={
          <AdminRevenueAndEnrolData
            onClose={handlCloseExpand}
            centerRevenueData={yearlyEnrolAndRevenuData}
            isPopup={true}
            statsFilteris={statsFilter}
          />
        }
        maxWidth="md"
        showDivider={false}
      />
    </>
  );
}
