import { Box, Card, CircularProgress, Grid, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import NewCardcomp from "../../../components/card/NewCardcomp";
import ReactApexChart from "react-apexcharts";
import LeadCalandar from "../../LeadConversation/LeadCalandar";
import { useGetTokenOrLogout } from "../../../utils/token";
import CircularComponent from "../Components/CircularComp";


const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT;
export default function DemoToEnrolment() {
    const userId= localStorage.getItem("userId")
         
          const getTokenOrLogout = useGetTokenOrLogout();
     const [chosenDate, setChosenDate] = useState(new Date());
     const [loading,setLoading] =useState(false)
     const [enrolData,setEnrolData] =useState([])

  const handleDateChange1 = (date) => {
    setChosenDate(date);
};

    // Trainer Demo to Enrol PiechartData
    const getPieChartData = (date) => {
      const token = getTokenOrLogout();
  if (!token) return;
  let headers = {
    Authorization: `Bearer ${token}`,
    "Content-Type": "application/json", // Make sure to keep the content type
  };
      setLoading(true);
  
      const requestData = {
        user_id: userId,
        date: date,
      };
  
      fetch(`${API_ENDPOINT}trainerdashboard/lead-enrollent`, {
        method: "POST",
        headers,
        body: JSON.stringify(requestData),
      })
        .then((response) => response.json())
        .then((data) => {
          console.log(data);
          setEnrolData(data);
          setLoading(false);
        })
        .catch((error) => {
          console.error("Error fetching daily data:", error);
          setLoading(false);
        });
    };

   useEffect(() => {
    
      getPieChartData(chosenDate);
     
    }, [chosenDate]);
  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <NewCardcomp>
            {" "}
            <Typography variant="h5" textAlign='center'>
              Demo To Enrolment Conversion Breakdown
            </Typography>
          </NewCardcomp>
        </Grid>

        <Grid item xs={12}>
          <NewCardcomp height= "300px">
           
               <Grid container spacing={1}>
                       <Grid item xs={12}>
                             <LeadCalandar onDateChange={handleDateChange1}  />
                           </Grid>
                         {loading ? (
                           <Box
                             display="flex"
                             justifyContent="center"
                             alignItems="center"
                             mt={2}
                             width='100%'
                           >
                             <CircularProgress />
                           </Box>
                         ) : (
                           <>
                        
                          <CircularComponent data1={enrolData?.demo_assigned} data2={enrolData?.demo_enrollment} data3={enrolData?.demo_prev_enrollment}/>
           
                             <Grid
                               item
                               xs={6}
                               display="flex"
                               direction="row"
                               alignItems="center"
                               gap={1}
                             >
                               <Box
                                 sx={{
                                   bgcolor: "#FF4B4B",
                                   width: 10,
                                   height: 10,
                                   borderRadius: "50%",
                                 }}
                               ></Box>
                               <Typography variant="h7">
                                 Demos Assigned
                               </Typography>
                             </Grid>
           
                             <Grid item xs={6}>
                               <Typography variant="h7">
                                 {enrolData?.demo_assigned}
                               </Typography>
                             </Grid>
           
                             <Grid
                               item
                               xs={6}
                               display="flex"
                               direction="row"
                               alignItems="center"
                               gap={1}
                             >
                               <Box
                                 sx={{
                                   bgcolor: "#01BE85",
                                   width: 10,
                                   height: 10,
                                   borderRadius: "50%",
                                 }}
                               ></Box>
                               <Typography variant="h7">
                             Demos Converted
                               </Typography>
                             </Grid>
                             <Grid item xs={6}>
                               <Typography variant="h7">
                                 {enrolData?.demo_enrollment}
                               </Typography>
                             </Grid>
           
                             <Grid
                               item
                               xs={6}
                               display="flex"
                               direction="row"
                               alignItems="center"
                               gap={1}
                             >
                               <Box
                                 sx={{
                                   bgcolor: "#FABA3A",
                                   width: 10,
                                   height: 10,
                                   borderRadius: "50%",
                                 }}
                               ></Box>
                               <Typography variant="h7">
                               Demos to converted <br/>
                                (Previous Assignments)
                               </Typography>
                             </Grid>
                             <Grid item xs={6} >
                               <Typography variant="h7">
                                 {enrolData?.demo_prev_enrollment}
                               </Typography>
                             </Grid>
                           </>
                         )}
                       </Grid>
           
          </NewCardcomp>
        </Grid>
      </Grid>
    </>
  );
}
