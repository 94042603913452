import React, { useEffect, useState } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  IconButton,
  Grid,
  Typography,
  TextField,
  RadioGroup,
  FormControlLabel,
  Radio,
  FormControl,
  Select,
  MenuItem,
  Snackbar,
  Backdrop,
  CircularProgress,
} from "@mui/material";
import PaymentForm from "./PaymentForm";
import CloseIcon from "@mui/icons-material/Close";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import CancelIcon from "@mui/icons-material/Cancel";
import { useNavigate } from "react-router-dom";

const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT;

export default function PaymentPopup({
  rowData,
  open,
  handleClose,
  content,
  title,
}) {
  console.log(rowData);
  const navigate = useNavigate();
  const userId = localStorage.getItem("userId");
  const [paymentmodes, setPaymentModes] = useState([]);
  const [amountRemaining, setAmountRemaining] = useState(null);
  const [statusname, setstatusname] = useState("pending");
  const [selectedType, setSelectedType] = useState("fullPayment");
  const [isPartialPayment, setIsPartialPayment] = useState(false);
  const [showInstallmentFields, setShowInstallmentFields] = useState(false);
  const [pendingamount, setPendingAmount] = useState(null);
  const [totalamount, setTotalAmount] = useState(0);
  const [installmentAmount, setInstallmentAmount] = useState(null);
  const [paymentMode, setPaymentMode] = useState(null);
  const [dueDate, setDueDate] = useState(null);
  const [isFormValid, setIsFormValid] = useState(false);
  const [showSuccessPopup, setShowSuccessPopup] = useState(false);
  const [showFailedPopup, setShowFailedPopup] = useState(false);
  const [isloading, setisloading] = useState(false);

  const [errormessage, setErrormessage] = useState("Error sending payment details! Try Again")

  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 250,
      },
    },
  };

  useEffect(() => {
    setAmountRemaining(rowData?.pending_amount);
  }, [rowData]);
  useEffect(() => {
    // Calculate total amount and pending amount when component mounts or when selectedType changes
    const calculateAmounts = () => {
      if (selectedType === "fullPayment") {
        setTotalAmount(amountRemaining);
        setPendingAmount(null);
      } else {
        setTotalAmount(installmentAmount);
        setPendingAmount(amountRemaining - installmentAmount);
      }
    };
    calculateAmounts();
  }, [selectedType, amountRemaining, installmentAmount]);

  const handleCloseSuccessPopup = () => {
    setShowSuccessPopup(false);
  };

  const handleCloseFailedPopup = () => {
    setShowFailedPopup(false);
  };

  const handleChange = (event) => {
    setstatusname(event.target.value);
  };

  const handlePaymentTypeChange = (event) => {
    setSelectedType(event.target.value);
    if (event.target.value === "installment") {
      setShowInstallmentFields(true);
    } else {
      setShowInstallmentFields(false);
    }
    setIsPartialPayment(!isPartialPayment);
    setInstallmentAmount(null);
    setPendingAmount(null);
    setDueDate(null);

    setIsFormValid(false);
  };

  const handlePaymentModeChange = (event) => {
    setPaymentMode(event.target.value);
  };

  const handleDueDateChange = (event) => {
    setDueDate(event.target.value);
    validateForm();
  };

  const handleInstallmentAmountChange = (event) => {
    const enteredAmount = event.target.value;

    if (enteredAmount > amountRemaining) {
      setInstallmentAmount(amountRemaining);
    } else {
      setInstallmentAmount(enteredAmount);
    }

    validateForm();
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(`${API_ENDPOINT}paymentmode`);
        if (response.ok) {
          const data = await response.json();
          const activePayments = data.filter((item) => item.is_active === true);
          console.log(activePayments);
          setPaymentModes(activePayments);

          if (activePayments?.length > 0) {
            setPaymentMode(activePayments[0].id);
          }
        } else {
          throw new Error(`Request failed with status: ${response.status}`);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, []);

  const validateForm = () => {
    const isInstallmentAmountValid = installmentAmount !== null;
    const isPendingAmountValid = pendingamount !== null;
    const isDueDateValid = dueDate !== null;
    const isPaymentModeValid = paymentMode !== null;

    console.log(isInstallmentAmountValid, isDueDateValid, isPaymentModeValid);

    setIsFormValid(
      isInstallmentAmountValid && isDueDateValid && isPaymentModeValid
    );
  };
  console.log(isFormValid);

  useEffect(() => {
    validateForm();
  }, [dueDate, installmentAmount, paymentMode]);

  const handleProceedToPayment = () => {
    const paymentDetails = {
      is_new_payment:false,
      enquiry_id: rowData.enquiry_id,
      payment_mode_id: parseInt(paymentMode),
      is_discount: false,
      is_partial_payment: isPartialPayment,
      pending_amount: pendingamount,
      next_payment_date: dueDate,
      package_id: rowData.package_id.id,
      payment_status: statusname,
      user_id: userId,
      class_id: rowData.class_id.id,
      enrollment_date: rowData.enrollment_date,
      branch_enrolled_id: rowData.branch_enrolled_id,
      package_amount: rowData.package_amount,
      amount_to_be_paid: amountRemaining,
      amount_paid: isPartialPayment ? installmentAmount : amountRemaining,
      discount_amount: null,
    };

    console.log(paymentDetails);

    setisloading(true);
    // Make a POST request to enroll the student
    fetch(`${API_ENDPOINT}lmprovesionpayment/generatependingpayment`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(paymentDetails),
    })
      .then((response) => {
        if (response.ok) {
          // Handle success, e.g., show a success message
          console.log("Student enrolled successfully");
          // alert('Payment Details sent successfully')
          setShowSuccessPopup(true);
          setisloading(false);
          handleClose();
        } else if(response.status === 403){
          setisloading(false);
          setErrormessage("Unauthorized to create enrollment. Please log in with your advisor account")
          setShowFailedPopup(true) 
          
        } else if(response.status === 409){
          setisloading(false);
          setErrormessage("This enquiry has an existing active package with this package selection")
          setShowFailedPopup(true) 
         
        } 
        else if (response.status === 410){
          setisloading(false);
          setErrormessage("This enquiry has an existing pending payment with the same package")
          setShowFailedPopup(true) 
          
        }
        else {
          // Handle failure, e.g., show an error message
          console.error("Failed to enroll student");
          setShowFailedPopup(true);
          //alert('Error sending payment details')
          setisloading(false);
        }
      })
      .catch((error) => {
        console.error("Error enrolling student:", error);
        // alert('Error sending payment details')
        setShowFailedPopup(true);
        setisloading(false);
      });

    //setPaymentStatus('paid');
  };
  return (
    <>
      <Dialog open={open} onClose={handleClose} fullWidth maxWidth="sm">
        <Backdrop open={isloading} style={{ zIndex: 9999 }}>
          <Typography
            variant="h4"
            color="secondary"
            fontWeight={800}
            marginRight={2}
          >
            Submitting Payment Details ....
          </Typography>
          <CircularProgress color="secondary"/>
        </Backdrop>
        <DialogTitle
          sx={{
            textAlign: "center",
            fontSize: "24px",
            fontWeight: "600",
            paddingRight: "40px",
            fontFamily: "Poppins", // Add padding for the close button
          }}
        >
          {title}
          <IconButton
            aria-label="close"
            style={{ position: "absolute", right: "8px", top: 0 }}
            onClick={handleClose}
          >
            <CloseIcon fontSize="medium" />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <Grid container>
            <Grid item xs={12} lg={4} md={12} sm={12}>
              <Typography
                sx={{
                  fontSize: "16px",
                  fontWeight: 800,
                  fontFamily: "Poppins",
                }}
              >
                Amount Remaining to be paid
              </Typography>
              <TextField
                value={amountRemaining}
                variant="standard"
                sx={{ mb: 1, width: "100%" }}
                type="number"
                InputProps={{
                  style: { borderRadius: "15px" },
                  readOnly: true,
                }}
              />
            </Grid>

            <Grid item xs={12} lg={12} md={12} sm={12}>
              <Typography
                sx={{
                  fontSize: "16px",
                  fontWeight: 800,
                  fontFamily: "Poppins",
                }}
              >
                Payment Type
              </Typography>
              <FormControl>
                <RadioGroup
                  row
                  aria-labelledby="demo-row-radio-buttons-group-label"
                  name="row-radio-buttons-group"
                  value={selectedType}
                  onChange={handlePaymentTypeChange}
                >
                  <FormControlLabel
                    value="fullPayment"
                    control={<Radio />}
                    label="Full Payment"
                  />
                  <FormControlLabel
                    value="installment"
                    control={<Radio />}
                    label="Installments"
                  />
                </RadioGroup>
              </FormControl>
            </Grid>
            {showInstallmentFields && (
              <>
                <Grid item xs={12} lg={12} md={12} sm={12}>
                  <Typography
                    sx={{
                      fontSize: "16px",
                      fontWeight: 800,
                      fontFamily: "Poppins",
                    }}
                  >
                    Installment Amount
                  </Typography>
                  <TextField
                    sx={{ mb: 1, width: "100%" }}
                    variant="outlined"
                    type="number"
                    value={installmentAmount}
                    onChange={handleInstallmentAmountChange}
                    InputProps={{
                      style: { borderRadius: "15px" },
                    }}
                  />
                </Grid>

                <Grid container spacing={2}>
                  <Grid item xs={12} lg={6} md={12} sm={12}>
                    <Typography
                      sx={{
                        fontSize: "16px",
                        fontWeight: 800,
                        fontFamily: "Poppins",
                      }}
                    >
                      Pending Amount
                    </Typography>
                    <TextField
                      value={pendingamount}
                      sx={{ mb: 1, width: "100%" }}
                      variant="standard"
                      type="number"
                      InputProps={{
                        style: { borderRadius: "15px" },
                        readOnly: true,
                      }}
                    />
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    lg={6}
                    md={12}
                    sm={12}
                    sx={{ marginBottom: 2 }}
                  >
                    <Typography
                      sx={{
                        fontSize: "16px",
                        fontWeight: 800,
                        fontFamily: "Poppins",
                      }}
                    >
                      Due Date
                    </Typography>
                    <TextField
                      sx={{ mb: 1, width: "100%" }}
                      variant="outlined"
                      type="Date"
                      value={dueDate}
                      onChange={handleDueDateChange}
                      InputProps={{
                        style: { borderRadius: "15px" },
                        inputProps: {
                          min: new Date().toISOString().split("T")[0],
                        },
                      }}
                    />
                  </Grid>
                </Grid>
              </>
            )}

            <Grid item xs={12} lg={4} md={12} sm={12}>
              <Typography
                sx={{
                  fontSize: "16px",
                  fontWeight: 800,
                  fontFamily: "Poppins",
                }}
              >
                Total To be Paid
              </Typography>
              <TextField
                value={totalamount}
                sx={{ mb: 1, width: "100%" }}
                variant="standard"
                type="number"
                InputProps={{
                  style: { borderRadius: "15px" },
                  readOnly: true,
                }}
              />
            </Grid>
            <Grid item xs={12} lg={12} md={12} sm={12}>
              <Typography
                sx={{
                  fontSize: "16px",
                  fontWeight: 800,
                  fontFamily: "Poppins",
                }}
              >
                Payment mode
              </Typography>
              <FormControl>
                <RadioGroup
                  row
                  aria-labelledby="demo-row-radio-buttons-group-label"
                  name="row-radio-buttons-group"
                  value={paymentMode}
                  onChange={handlePaymentModeChange}
                >
                  {paymentmodes.map((mode, index) => (
                    <FormControlLabel
                      key={mode.id}
                      value={mode.id}
                      control={<Radio />}
                      label={mode.payment_mode}
                    />
                  ))}
                </RadioGroup>
              </FormControl>
            </Grid>

            <Grid item xs={12} lg={12} md={12} sm={12}>
              <FormControl fullWidth>
                <Typography
                  sx={{
                    fontSize: "16px",
                    fontWeight: 800,
                    fontFamily: "Poppins",
                  }}
                >
                  Payment Status
                </Typography>
                <Select
                  value={statusname}
                  style={{ borderRadius: "15px" }}
                  onChange={handleChange}
                >
                  <MenuItem value="pending">Pending</MenuItem>
                </Select>
              </FormControl>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Button
            color="secondary"
            variant="contained"
            onClick={handleProceedToPayment}
            disabled={selectedType === "installment" && !isFormValid}
          >
            Submit Payment Details
          </Button>
        </DialogActions>
      </Dialog>

      <Snackbar
        anchorOrigin={{ horizontal: "center", vertical: "top" }}
        open={showSuccessPopup}
        autoHideDuration={6000}
        onClose={handleCloseSuccessPopup}
        message="Payment request sent successfully! Email will be sent after confirmation of payment"
        action={
          <Button
            color="inherit"
            size="small"
            onClick={handleCloseSuccessPopup}
          >
            <CheckCircleOutlineIcon fontSize="small" />
          </Button>
        }
      />

      <Snackbar
        anchorOrigin={{ horizontal: "center", vertical: "top" }}
        open={showFailedPopup}
        autoHideDuration={6000}
        onClose={handleCloseFailedPopup}
        message={errormessage}
        action={
          <Button color="inherit" size="small" onClick={handleCloseFailedPopup}>
            <CancelIcon fontSize="small" />
          </Button>
        }
      />
    </>
  );
}
