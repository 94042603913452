import {
  Box,
  Button,
  Chip,
  CircularProgress,
  Dialog,
  DialogContent,
  Grid,
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import React, { useState } from "react";
import DropOutRequest from "../dropout/DropOutRequest";
import CreatePopup from "../../../../../components/dialog/CreatePopup";
import { fDate } from "../../../../../utils/formatTime";
import ClassextentionForm from "./ClassextentionForm ";
import CustomPopup from "../../../../../components/CustomPopup";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import EventIcon from "@mui/icons-material/Event";
import CustomSnackbar from "../../../../../newTestPrep/components/ui-component/snackbar/CustomSnackbar";
import DatePicker from "react-datepicker";
const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT;
function ViewClassHistory({ classes, loading, setClasses,      isReadOnly }) {
  console.log("classes", classes);
  const userId = localStorage.getItem("userId");
  const userRole=localStorage.getItem("userRole")
  const [data, setData] = useState(classes);
  const [isDropOutRequest, setIsDropOutRequest] = useState(false);
  const [classpopup, setClasspopup] = useState(false);
  const [selecteddata, setSelecteddata] = useState([]);
  const [popupOpen, setPopupOpen] = useState(false);
  const [startDate, setStartDate] = useState(null);
  const [classIdPopup, setClassIdPopup] = useState(null);
  // Function to handle opening the popup with DatePicker
  const [classId, setClassId] = useState(null);
  const [selectedRow, setSelectedRow] = useState({});
  const [selectedEnrollmentDate, setSelectedEnrollmentDate] = useState(null);
  const [datesWithSlots, setDatesWithSlots] = useState([]);
  const [snackbarOpen, setSnackbarOpen] = React.useState(false);
  const [snackbarMessage, setSnackbarMessage] = React.useState("");
  const [snackbarSeverity, setSnackbarSeverity] = React.useState("success");

  const showSnackbar = (message, severity) => {
    setSnackbarMessage(message);
    setSnackbarSeverity(severity);
    setSnackbarOpen(true);
  };
  const handleOpenPopup = (classId) => {
    setClassIdPopup(classId);
    setPopupOpen(true);
  };

  // Function to handle closing the popup
  const handleClosePopup = () => {
    setClassIdPopup(null);
    setPopupOpen(false);
  };

  const openpopup = (data) => {
    if(userRole!=="advisor"){
      showSnackbar ("Please login as an advisor role,to extend class." ,'error')
      return
     }
    setClasspopup(true);
    setSelecteddata(data);
  };
  console.log(selecteddata);
  const closePopup = () => {
    setClasspopup(false);
  };

  const handleOpenDropOutRequest = () => {
    setIsDropOutRequest(true);
  };

  const handleCloseDropOutRequest = () => {
    setIsDropOutRequest(false);
  };

  const filterDate = (date) => {
    const dateStr = date.toLocaleDateString(); // Use toLocaleDateString

    // Check if the date is present in the fetched data
    return (
      datesWithSlots.some(
        (d) => dateStr === new Date(d.date).toLocaleDateString()
      ) && datesWithSlots.slots !== 0
    );
  };

  const customDayClass = (date) => {
    const dateStr = date.toLocaleDateString(); // Use toLocaleDateString

    const dateWithSlots2 = datesWithSlots.find(
      (d) => dateStr === new Date(d.date).toLocaleDateString()
    );

    if (dateWithSlots2) {
      return dateWithSlots2.slots === 0
        ? "react-datepicker__day--highlighted-custom-3 react-datepicker__day--disabled"
        : "react-datepicker__day--highlighted-custom-4";
    }

    return "";
  };

  const handleSubmitEnrollmentDate = async () => {
    try {
      if (!selectedEnrollmentDate) {
        showSnackbar("Please select an enrollment date", "error");
        return;
      }

      // Add one day to the selected date
      const nextDay = new Date(selectedEnrollmentDate);
      nextDay.setDate(nextDay.getDate() + 1);

      const formattedDate = nextDay.toISOString().split("T")[0];

      const response = await fetch(
        `${API_ENDPOINT}lmstdclassdetail/updatesession/${selectedRow.id}`,
        {
          method: "PUT",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            enrollment_date: formattedDate,
            user_id: userId,
          }),
        }
      );
      if (!response.ok) {
        showSnackbar("Error updating enrollment date", "error");
        throw new Error(`${response.status}`);
      }
      // Handle success response
      showSnackbar("Enrollment date updated successfully", "success");

      // Remove selectedRow from payments array
      const updatedPayments = classes.filter(
        (item) => item.id !== selectedRow.id
      );
      setClasses(updatedPayments);

      setPopupOpen(false);
      setSelectedRow({});
      setClassId(null);
      setSelectedEnrollmentDate(null);
    } catch (error) {
      console.error("Error updating enrollment date:", error);
      showSnackbar("Error updating enrollment date", "error");
    }
  };

  const handleAddEnrollmentDate = async (row) => {
    setSelectedRow(row);
    setClassId(row.class_id?.id); // Set the class ID

    try {
      const response = await fetch(
        `${API_ENDPOINT}route/searchseat/${row.class_id?.id}`
      );
      if (!response.ok) {
        throw new Error("Failed to fetch class slots");
      }
      const data = await response.json();
      if (data.length === 0) {
        showSnackbar("No dates available for this class", "error");
      } else {
        setDatesWithSlots(data); // Set the fetched class slots in state
        handleOpenPopup(row.class_id?.id); // Open the popup with the DatePicker
      }
    } catch (error) {
      console.error("Error fetching class slots:", error);
      showSnackbar("Error fetching class slots", "error");
    }
  };

  const [extendPopupOpen, setExtendPopupOpen] = useState(false);
  const [newTentativeDate, setNewTentativeDate] = useState(null);

  const handleExtendTentativeDate = (row) => {
    setExtendPopupOpen(true);

    setSelectedRow(row);
    setClassId(row.class_id?.id);
  };

  const handleCloseExtendPopup = () => {
    setExtendPopupOpen(false);
    setNewTentativeDate(null);
  };

  const handleSubmitTentativeDate = async () => {
    try {
      if (!newTentativeDate) {
        showSnackbar("Please select a new tentative date", "error");
        return;
      }

      const response = await fetch(
        `${API_ENDPOINT}lmstdclassdetail/updatesession/${selectedRow.id}`,
        {
          method: "PUT",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            is_tentative_enroll_date: true,
            tentative_enroll_date: newTentativeDate,
            user_id: userId,
          }),
        }
      );
      if (!response.ok) {
        showSnackbar("Error updating tentative date", "error");
        throw new Error(`${response.status}`);
      }
      // Handle success response
      showSnackbar("Tentative date updated successfully", "success");

      // Remove selectedRow from payments array
      const updatedPayments = classes.filter(
        (item) => item.id !== selectedRow.id
      );
      setClasses(updatedPayments);
      handleCloseExtendPopup();
      setPopupOpen(false);
      setSelectedRow({});
      setClassId(null);
      setNewTentativeDate(null);
    } catch (error) {
      console.error("Error updating tentative date:", error);
      showSnackbar("Error updating tentative date", "error");
    }
  };

  return (
    <>
    <Typography textAlign='left' variant="h4" fontWeight={700}>Class History</Typography>
      {loading ? (
        <Grid container justifyContent="center" alignItems="center">
          <CircularProgress />
        </Grid>
      ) : (
        <>
          {classes.length === 0 ? (
            <Grid
              item
              xs={12}
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Typography variant="h5">No class history available</Typography>
            </Grid>
          ) : (
            <TableContainer
              component={Paper}
              sx={{ borderRadius: "15px", padding: "10px" }}
            >
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell
                      sx={{
                        color: "#000",
                        fontFamily: "Poppins",
                        fontSize: "18px",
                        fontStyle: "normal",
                        fontWeight: 600,
                      }}
                    >
                      Class Details
                    </TableCell>
                    <TableCell
                      sx={{
                        color: "#000",
                        fontFamily: "Poppins",
                        fontSize: "18px",
                        fontStyle: "normal",
                        fontWeight: 600,
                      }}
                    >
                      Branch
                    </TableCell>
                    <TableCell
                      sx={{
                        color: "#000",
                        fontFamily: "Poppins",
                        fontSize: "18px",
                        fontStyle: "normal",
                        fontWeight: 600,
                      }}
                    >
                      Package Details
                    </TableCell>
                 
                      <TableCell
                     
                        sx={{
                          color: "#000",
                          fontFamily: "Poppins",
                          fontSize: "18px",
                          fontStyle: "normal",
                          fontWeight: 600,
                        }}
                      >
                     
                           Tentative Enrollment Date
                       
                      </TableCell>
                  
                      <TableCell
                     
                     sx={{
                       color: "#000",
                       fontFamily: "Poppins",
                       fontSize: "18px",
                       fontStyle: "normal",
                       fontWeight: 600,
                     }}
                   >
                  
                       Enrollment Date
                    
                   </TableCell>
                    <TableCell
                      sx={{
                        color: "#000",
                        fontFamily: "Poppins",
                        fontSize: "18px",
                        fontStyle: "normal",
                        fontWeight: 600,
                      }}
                    >
                      End Date
                    </TableCell>
                    <TableCell
                      sx={{
                        color: "#000",
                        fontFamily: "Poppins",
                        fontSize: "18px",
                        fontStyle: "normal",
                        fontWeight: 600,
                      }}
                    >
                      Active Sessions
                    </TableCell>
                    <TableCell
                      sx={{
                        color: "#000",
                        fontFamily: "Poppins",
                        fontSize: "18px",
                        fontStyle: "normal",
                        fontWeight: 600,
                      }}
                    >
                      Remaining Sessions
                    </TableCell>
                    <TableCell
                      sx={{
                        color: "#000",
                        fontFamily: "Poppins",
                        fontSize: "18px",
                        fontStyle: "normal",
                        fontWeight: 600,
                      }}
                    >
                      Student Status
                    </TableCell>
                    <TableCell
                      sx={{
                        color: "#000",
                        fontFamily: "Poppins",
                        fontSize: "18px",
                        fontStyle: "normal",
                        fontWeight: 600,
                      }}
                    >
                      Action
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  <>
                    {classes.map((row, index) => (
                      <TableRow
                        key={index}
                        style={{
                          backgroundColor:
                            index % 2 === 0 ? "#f0f0f0" : "inherit",
                          width: "6%",
                        }}
                      >
                        <TableCell>{row?.class_id?.name}</TableCell>
                        <TableCell>{row?.branch_id?.branch_name}</TableCell>
                        <TableCell>
                          {row?.package_id?.package_name}
                          <br />
                          {row?.package_days} session(s)
                        </TableCell>
                       
                          <TableCell>
                            {fDate(row?.tentative_enroll_date)}
                          </TableCell>
                      
                          <TableCell>{fDate(row?.enrollment_date)}</TableCell>
                      
                        <TableCell>{fDate(row?.end_date)}</TableCell>
                        <TableCell>{row?.active_total_sessions}</TableCell>
                        <TableCell>{row?.remaining_sessions}</TableCell>
                        <TableCell>
                          <Chip
                          size="small"
                            label={row?.status_of_student}
                            color={
                              row?.status_of_student === "active"
                                ? "secondary"
                                : "error"
                            }
                          />
                        </TableCell>

                        <TableCell>
                       {row?.is_active===1 && (row?.package_days<=row?.active_total_sessions) && ( 
                              <Button
                                  size="small"
                                variant="contained"
                                color="primary"
                                onClick={() => openpopup(row)}
                              >
                                Class Extension
                              </Button>
                       )}
                          {row?.enrollment_date === null && (
                            <>
                              <Tooltip title="Add Enrollment Date">
                                <IconButton
                                disabled={isReadOnly}
                                  onClick={() => {
                                    // Set the class ID
                                    handleAddEnrollmentDate(row); // Trigger the function to open the popup
                                  }}
                                  size="small"
                                  color="secondary"
                                >
                                  <AddCircleOutlineIcon />
                                </IconButton>
                              </Tooltip>
                              <Tooltip title="Extend Tentative Enrollment Date">
                                <IconButton
                                  onClick={() => handleExtendTentativeDate(row)}
                                  size="small"
                                  color="error"
                                >
                                  <EventIcon />
                                </IconButton>
                              </Tooltip>
                            </>
                          )}
                        </TableCell>
                      </TableRow>
                    ))}
                  </>
                </TableBody>
              </Table>
            </TableContainer>
          )}
        </>
      )}
      <CreatePopup
        open={isDropOutRequest}
        onClose={handleCloseDropOutRequest}
        title="Drop out request"
        component={<DropOutRequest />}
      />

      <CustomPopup
        open={classpopup}
        onClose={closePopup}
        title="Class Extention Form"
        maxWidth="sm"
        showDivider="true"
        component={
          <ClassextentionForm
            onClose={closePopup}
            selecteddata={selecteddata}
          />
        }
      />

      <CustomSnackbar
        open={snackbarOpen}
        onClose={() => setSnackbarOpen(false)}
        message={snackbarMessage}
        severity={snackbarSeverity}
      />

      <Dialog open={popupOpen} onClose={handleClosePopup}>
        <DialogContent>
          <Typography variant="h4">Select Enrollment Date</Typography>
          {datesWithSlots.length === 0 ? (
            <Typography>No dates available</Typography>
          ) : (
            <Box m={2}>
              <DatePicker
                showIcon
                isClearable
                closeOnScroll={true}
                startDate={startDate}
                filterDate={filterDate}
                selected={selectedEnrollmentDate}
                onChange={(date) => {
                  setSelectedEnrollmentDate(date);
                }}
                dateFormat="dd/MM/yyyy"
                dayClassName={customDayClass}
                // Set the fetched class slots as inline bookings
                inline
                includeDates={datesWithSlots.map((slot) => new Date(slot.date))}
              />
            </Box>
          )}
          <Button onClick={handleSubmitEnrollmentDate} variant="contained">
            Add Enrolment Date
          </Button>
          <Button onClick={() => setPopupOpen(false)} variant="outlined">
            Cancel
          </Button>
        </DialogContent>
      </Dialog>

      <Dialog open={extendPopupOpen} onClose={handleCloseExtendPopup}>
        <DialogContent>
          <Typography variant="h4">Extend Tentative Enrollment Date</Typography>
          <TextField
            label="New Tentative Enrollment Date"
            type="date"
            value={newTentativeDate}
            onChange={(e) => setNewTentativeDate(e.target.value)}
            InputLabelProps={{
              shrink: true,
            }}
            InputProps={{
              style: { borderRadius: "15px" },
              inputProps: { min: new Date().toISOString().split('T')[0] },
            }}
            fullWidth
            margin="normal"
          />
          <Button variant="contained" onClick={handleSubmitTentativeDate}>
            Submit
          </Button>
          <Button variant="outlined" onClick={handleCloseExtendPopup}>
            Cancel
          </Button>
        </DialogContent>
      </Dialog>
    </>
  );
}

export default ViewClassHistory;
