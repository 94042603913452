import { Box, Card, CircularProgress, Grid, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import NewCardcomp from "../../../components/card/NewCardcomp";
import LeadCalandar from "../../LeadConversation/LeadCalandar";
import { useGetTokenOrLogout } from "../../../utils/token";
import CircularComponent from "../Components/CircularComp";

const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT;
export default function LeadToEnrol({handleDateChange1, loading, leadData}) {



 
  
  

 

  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <NewCardcomp>
            <Typography variant="subtitle2" sx={{color:"#000000",textAlign:'center'}}>
            Lead To Enrollment Conversion Breakdown
            </Typography>
          </NewCardcomp>
        </Grid>

        <Grid item xs={12}>
          <NewCardcomp height= "300px" >
            <Grid container spacing={1}>
            <Grid item xs={12}>
                  <LeadCalandar onDateChange={handleDateChange1}  />
                </Grid>
              {loading ? (
                <Box
                  display="flex"
                  justifyContent="center"
                  alignItems="center"
                  mt={2}
                  width='100%'
                >
                  <CircularProgress />
                </Box>
              ) : (
                <>
             
                <CircularComponent data1={leadData?.new_enquiry} data2={leadData?.enrolled_student} data3 ={leadData?.prev_month_enrollment}/>

                  <Grid
                    item
                    xs={6}
                    display="flex"
                    direction="row"
                    alignItems="center"
                    gap={1}
                  >
                    <Box
                      sx={{
                        bgcolor: "#FF4B4B",
                        width: 10,
                        height: 10,
                        borderRadius: "50%",
                      }}
                    ></Box>
                    <Typography variant="h7">
                      Leads Assigned
                    </Typography>
                  </Grid>

                  <Grid item xs={6}>
                    <Typography variant="h7">
                      {leadData?.new_enquiry}
                    </Typography>
                  </Grid>

                  <Grid
                    item
                    xs={6}
                    display="flex"
                    direction="row"
                    alignItems="center"
                    gap={1}
                  >
                    <Box
                      sx={{
                        bgcolor: "#01BE85",
                        width: 10,
                        height: 10,
                        borderRadius: "50%",
                      }}
                    ></Box>
                    <Typography variant="h7">
                      Leads Converted
                    </Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <Typography variant="h7">
                      {leadData?.enrolled_student}
                    </Typography>
                  </Grid>

                  <Grid
                    item
                    xs={6}
                    display="flex"
                    direction="row"
                    alignItems="center"
                    gap={1}
                  >
                    <Box
                      sx={{
                        bgcolor: "#FABA3A",
                        width: 10,
                        height: 10,
                        borderRadius: "50%",
                      }}
                    ></Box>
                    <Typography variant="h7">
                      Leads Converted <br/>(Previous Assignments)
                    </Typography>
                  </Grid>
                  <Grid item xs={6} >
                    <Typography variant="h7">
                      {leadData?.prev_month_enrollment}
                    </Typography>
                  </Grid>
                </>
              )}
            </Grid>
          </NewCardcomp>
        </Grid>
      </Grid>
    </>
  );
}
