// material-ui
import { Typography } from '@mui/material';

// project imports
import NavGroup from './NavGroup';
import menuItem from '../../../../menu-items/admin';

// ==============================|| SIDEBAR MENU LIST ||============================== //


  const userRolePermissions = {
    center_admin: ['default', 'test-types', 'packages', 'enquiries', 'demos', 'students', 'attendance', 'tasks', 'admin-area', 'import', 'expense', 'accounts','paid-accounts','lesson-plan', 'classes', 'reports', 'raiseticket', 'campaign', 'discount', 'payments', 'approvecashdeposits', 'approveonlinepayments', 'futureenrolments', 'requestcallbacks', 'studentleaverequests', 'eventzone', 'revenuereports','meeting', 'classsearch','work-report','employeetarget','advisor-target-report','test-type-advisor-revenue', 'advisor-task-revenue-report'],
    admin: ['default', 'test-types', 'enquiries','tasks', 'demos', 'students', 'attendance', 'admin-area', 'expense', 'accounts','raiseticket','classes','reports', 'lesson-plan', 'campaign', 'discount', 'packages', 'payments', 'futureenrolments', 'requestcallbacks', 'studentleaverequests','meeting', 'leaverequests', 'classsearch','work-report','walkin-qr'],
    trainer: ['default', 'demos', 'students','tasks', 'attendance', 'lesson-plan', 'classes', 'raiseticket', 'enquiries', 'discount', 'packages', 'payments', 'futureenrolments', 'requestcallbacks', 'studentleaverequests','reports','meeting', 'leaverequests','work-report'],
    advisor: ['default', 'enquiries', 'demos','tasks', 'students', 'raiseticket', 'discount', 'packages', 'payments','eventzone', 'futureenrolments', 'requestcallbacks','reports', 'classsearch','meeting','work-report', 'walkin-qr','holiday-request'],
    data_entry: ['default', 'enquiries','import', 'tasks','raiseticket', 'campaign','importedleads', 'eventzone', 'reports','recyclerequets','addtemplate','meeting','work-report','employees-profile', 'walkin-qr','employee-status','holiday-request'],
    accountant: ['default', 'accounts','paid-accounts', 'expense','raiseticket', 'approvecashdeposits', 'approveonlinepayments','meeting','work-report'],
    developer: ['default', 'test-types', 'packages', 'enquiries', 'demos', 'students', 'attendance', 'tasks', 'admin-area', 'import', 'expense', 'accounts','viewticket', 'lesson-plan', 'classes', 'reports', 'campaign', 'discount', 'payments', 'approvecashdeposits', 'approveonlinepayments', 'futureenrolments', 'requestcallbacks', 'studentleaverequests', 'eventzone', 'revenuereports','meeting', 'classsearch','work-report'],
  };
  
  const MenuList = ({ userRole }) => {
   
    const filteredItems = menuItem.items.map((groupItem) => {
      if (groupItem.type === 'group' && groupItem.children) {
        // Filter children based on user role permissions
        const filteredChildren = groupItem.children.filter((childItem) =>
          userRolePermissions[userRole]?.includes(childItem.id)
        );
  
        return {
          ...groupItem,
          children: filteredChildren,
        };
      }
      return groupItem;
    });
  
    const navItems = filteredItems.map((item) => {
      switch (item.type) {
        case 'group':
          return <NavGroup key={item.id} item={item} />;
        default:
          return (
            <Typography key={item.id} variant="h6" color="error" align="center">
              Menu Items Error
            </Typography>
          );
      }
    });
  
    return <>{navItems}</>;
  };
  

export default MenuList;