import { Avatar, ButtonBase, useTheme } from '@mui/material'
import React, { useState } from 'react'
import Iconify from '../../../../../components/iconify'
import TaskReportTabs from '../../../../../section/tasks/TaskTableReport'
import CreatePopup from '../../../../../components/dialog/CreatePopup'
import ChecklistIcon from '@mui/icons-material/Checklist';

function MyTasReport() {


    const theme = useTheme()

    const [isTaskReportOpen, setTaskReportOpen] = useState(false); 
    const [maxWidth, setMaxWidth] = useState('lg');
   

  const handleTaskReportOpen = () => {
    setTaskReportOpen(true);
  };

  const handleTaskReportClose = () => {
    setTaskReportOpen(false);
  }

  return (
    <>
    {/*<ButtonBase sx={{ borderRadius: '12px', overflow: 'hidden' }}>
          <Avatar
            variant="rounded"
            sx={{
              ...theme.typography.commonAvatar,
              ...theme.typography.mediumAvatar,
              transition: 'all .2s ease-in-out',
              background: theme.palette.secondary.dark,
              color: '#fff',
              //color: theme.palette.secondary.light,
              '&:hover': {
                background: theme.palette.secondary.light,
                color: theme.palette.secondary.dark
              }
            }}
            onClick={handleTaskReportOpen}
            color="inherit"
          >
           <Iconify icon="grommet-icons:task" />
          </Avatar>
        </ButtonBase>*/}

        <ButtonBase sx={{ borderRadius: '12px', overflow: 'hidden' }}>
          <Avatar
            variant="rounded"
            sx={{
              width: 40, 
              height: 40, 
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              backgroundColor: 'transparent', 
              '&:hover': {
                backgroundColor: 'transparent', 
              }
            }}
            onClick={handleTaskReportOpen}
            color="inherit"
          >
            <ChecklistIcon stroke={1.5} size="1.3rem" sx={{ color: '#787878' }}  />
          </Avatar>
        </ButtonBase>

            <CreatePopup
            open={isTaskReportOpen}
            onClose={handleTaskReportClose}
            maxWidth={maxWidth}
            //title='My Task Report'
            component={
            <TaskReportTabs onClose={handleTaskReportClose}/> 
            }
            />

</>


        
  )
}

export default MyTasReport