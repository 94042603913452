import React from 'react';
import './Logo.css';

function Logo() {
  return (
    <div >
      <img 
        src="/logo/test_master_logo png - Copy 1.png" 
        alt="Logo" 
    
      />
    </div>
  );
}

export default Logo;
