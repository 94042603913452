import React, { useEffect, useState } from "react";
import Content from "../Components/Content";
import { Card, CircularProgress, Grid, ThemeProvider } from "@mui/material";
import FilterButtonComp from "../Components/FilterButtonComp";
import DataCards from "../AdvisorNewDashBoard/DataCards";
import BranchTaskTrackBarChart from "./BranchTaskTrackBarChart";
import DisplayCurrentDateTime from "../Components/DisplayCurrentDateTime";
import UpcommingEvents from "../Components/UpcommingEvents";
import { useGetTokenOrLogout } from "../../../utils/token";
import LeadCalandar from "../../LeadConversation/LeadCalandar";
import LeadSourcePichartData from "./LeadSourcePichartData";
import NewCardcomp from "../../../components/card/NewCardcomp";

const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT;
export default function DataEntryNewDashBoard() {
  const [selectedFilter, setSelectedFilter] = useState("today");

  const userId = localStorage.getItem("userId");
  const getTokenOrLogout = useGetTokenOrLogout();
  const [fromDate, setFromDate] = useState(null);
  const [toDate, setToDate] = useState(null);
  const [cardsData, setCardsData] = useState([]);
  const [cardLoading, setCardLoading] = useState(false);
  const [taskBreakdownData, setdaskBreakDownData] = useState([]);
  const [taskLoading, setTaskLoading] = useState(false);

  const handleClickFilter = (criteria) => {
    setSelectedFilter(criteria);
    setFromDate("");
    setToDate("");
    if (criteria !== "custom") {
      handleFetchcardData(criteria, null, null);
      handletaskBreakdownData(criteria, null, null);
    }
  };

  // Fetch Card Data
  const handleFetchcardData = async (criteria, fromDate, toDate) => {
    setCardLoading(true);
    const token = getTokenOrLogout();
    if (!token) return;
    let headers = {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json", // Make sure to keep the content type
    };
    const requestData = {
      user_id: parseInt(userId),

      filter: criteria,
      to_date: toDate || null,
      from_date: fromDate || null,
    };

    try {
      const response = await fetch(`${API_ENDPOINT}auditdashboard/chip-data`, {
        method: "POST",
        headers,
        body: JSON.stringify(requestData),
      });

      if (response.ok) {
        const result = await response.json();

        setCardsData(result);

        setCardLoading(false);
      } else {
        console.log("Error");
        setCardLoading(false);
      }
    } catch (error) {
      console.log(error, "Error");
      setCardLoading(false);
    }
  };

  // Fetch Bar chart data
  const handletaskBreakdownData = async (criteria, fromDate, toDate) => {
    setTaskLoading(true);
    const token = getTokenOrLogout();
    if (!token) return;
    let headers = {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json", // Make sure to keep the content type
    };
    const requestData = {
      user_id: parseInt(userId),

      filter: criteria,
      to_date: toDate || null,
      from_date: fromDate || null,
    };

    try {
      const response = await fetch(
        `${API_ENDPOINT}auditdashboard/task-breakdown`,
        {
          method: "POST",
          headers,
          body: JSON.stringify(requestData),
        }
      );

      if (response.ok) {
        const result = await response.json();

        setdaskBreakDownData(result);

        setTaskLoading(false);
      } else {
        console.log("Error");
        setTaskLoading(false);
      }
    } catch (error) {
      console.log(error, "Error");
      setTaskLoading(false);
    }
  };

  useEffect(() => {
    if (selectedFilter !== "custom") {
      handleFetchcardData(selectedFilter);
      handletaskBreakdownData(selectedFilter);
    }

    if (selectedFilter === "custom" && fromDate !== "" && toDate !== "") {
      handleFetchcardData(selectedFilter, fromDate, toDate);
      handletaskBreakdownData(selectedFilter, fromDate, toDate);
    }
  }, [fromDate, toDate]);

  return (
    <>
      <Grid container spacing={3}>
        {/* Left Side */}
        <Grid item xs={12} lg={9} sm={12} md={9}>
          <Grid container spacing={2}>
            {/* content */}
            <Grid item xs={12}>
              <Content />
            </Grid>

            {/* Filter Button */}
            <Grid item xs={12} sm={12} mt={2}>
              <FilterButtonComp
                selectedFilter={selectedFilter}
                handleClickFilter={handleClickFilter}
                fromDate={fromDate}
                toDate={toDate}
                setFromDate={setFromDate}
                setToDate={setToDate}
              />
            </Grid>

            {/* Data Cards */}

            <Grid item xs={12} container spacing={2} gap={1}>
              <Grid item xs={12} sm={3.8} lg={2.9}>
                <DataCards
                  backGround="#D5F6E1"
                  title="New Enquiries"
                  iconSrc="/Group 1000002878.png"
                  value={
                    cardLoading ? (
                      <CircularProgress />
                    ) : (
                      cardsData?.new_enquiry || 0
                    )
                  }
                />
              </Grid>

              <Grid item xs={12} sm={3.8} lg={2.9}>
                <DataCards
                  backGround="#E7F3FF"
                  title="Not Attempted"
                  iconSrc="/Group 1000002854.png"
                  value={
                    cardLoading ? (
                      <CircularProgress />
                    ) : (
                      cardsData?.not_connected_enquiry || 0
                    )
                  }
                />
              </Grid>

              <Grid item xs={12} sm={3.8} lg={2.9}>
                <DataCards
                  backGround="#FFEDFB"
                  title="Tasks Assigned"
                  iconSrc="/Group 1000002879.png"
                  value={
                    cardLoading ? (
                      <CircularProgress />
                    ) : (
                      cardsData?.assigned_task || 0
                    )
                  }
                />
              </Grid>

              <Grid item xs={12} sm={3.8} lg={2.9}>
                <DataCards
                  backGround="#FFF6E2"
                  title="Tasks Completed"
                  iconSrc="/Group 1000002880.png"
                  value={
                    cardLoading ? (
                      <CircularProgress />
                    ) : (
                      cardsData?.completed_task || 0
                    )
                  }
                />
              </Grid>
            </Grid>

            {/* Branch Task BarChart */}

            <Grid item xs={12}>
               <NewCardcomp   height= "420px" >
                <BranchTaskTrackBarChart
                  taskLoading={taskLoading}
                  taskData={taskBreakdownData}
                />
              </NewCardcomp>
            </Grid>
          </Grid>
        </Grid>

        {/* Right Section */}

        <Grid item xs={12} lg={3} sm={12} md={3}>
          {/* Dispay Day And  Time */}
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <DisplayCurrentDateTime />
            </Grid>

            {/* Display Running And upcomming event */}
            <Grid item xs={12}>
              <UpcommingEvents />
            </Grid>

            <Grid item xs={12}>
            <NewCardcomp   height= "300px" >
                <LeadSourcePichartData />
              </NewCardcomp>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
}
