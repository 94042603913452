import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  firstName: '',
  lastName: '',
  contact: '',
  role: '',
  token: '',
  email: '',
  advisorId: '',
  adminId: '', 
  trainerId: '',
  userId: '',
  permission: []
};

const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    setUser: (state, action) => {
        const { first_name, last_name, email, mobile, user_role, advisor_id, trainer_id, admin_id, token, permission, id } = action.payload;
        state.firstName = first_name;
        state.lastName = last_name;
        state.contact = mobile;
        state.email = email;
        state.role = user_role;
        state.token = token;
        state.userId = id;
        state.permission = permission;
        //localStorage.setItem('userRole', user_role);
        localStorage.setItem('userId', id );
        localStorage.setItem('token', token );
        localStorage.setItem('firstName', first_name );
        localStorage.setItem('userRole', user_role);
    },
    clearUserData: (state) => {
        state.firstName = '';
        state.contact = '';
        state.role = '';
        state.token = '';
        state.email = '';
        state.advisorId = '';
        state.adminId = '';
        state.trainerId = '';
        state.userId = '';
  
        // Remove all role-specific IDs from local storage
        localStorage.removeItem('advisorId');
        localStorage.removeItem('adminId');
        localStorage.removeItem('trainerId');

        localStorage.removeItem('userRole');

        localStorage.removeItem('userId');
        localStorage.removeItem('token');

        localStorage.clear();
      },
      // New action to update user_role
    updateUserRole: (state, action) => {
      state.role = action.payload;
      localStorage.setItem('userRole', action.payload);
    },
    
  },
});

export const { setUser, clearUserData, updateUserRole } = userSlice.actions;

export default userSlice.reducer;