import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import MainCard from "../../components/ui-component/card/MainCard";
import {
  Autocomplete,
  Backdrop,
  Box,
  Button,
  Chip,
  CircularProgress,
  Collapse,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Select,
  Stack,
  TableContainer,
  TextField,
  Typography,
  styled,

 
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  Paper,
} from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import { useEffect, useState } from "react";
import FilterListIcon from "@mui/icons-material/FilterList";
import { fDate } from "../../../utils/formatTime";
import CustomSnackbar from "../../components/ui-component/snackbar/CustomSnackbar";
import { useGetTokenOrLogout } from "../../../utils/token";
import { useNavigate } from "react-router-dom";
import CloseIcon from "@mui/icons-material/Close";
//import Chip from "../../components/ui-component/chip/Chip";

const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT;

function ViewAttendance() {
  const getTokenOrLogout = useGetTokenOrLogout();
  const navigate = useNavigate();
  const userId = localStorage.getItem("userId");
  console.log(userId);
  const [selectedStudent, setSelectedStudent] = useState(null);
  const [isDialogOpen, setDialogOpen] = useState(false);
  const [isDialogOpen2, setDialogOpen2] = useState(false);

  const [studentData, setStudentData] = useState([]);
  const [selectedStudentAttendance, setSelectedStudentAttendance] = useState(
    []
  );
  const [branchlist, setbranchlist] = useState([]);
  const [trainerlist, settrainerlist] = useState([]);
  const [selectedBranchIds, setSelectedBranchIds] = useState([]);
  const [trainerid, setselectedtrainerid] = useState([]);
  const [classlist, setclasslist] = useState([]);
  const [statusOptions,setStatusOptions] = useState( [
    { value: "active", label: "Active" },
    { value: "inactive", label: "Inactive" },
  ])
  // console.log(" branch id is",selectedBranchIds)
  const [selectedStatus, setSelectedStatus] = useState("");
  const [selectedClassId, setSelectedClassId] = useState(null);
  const [selectedStudentId, setSelectedStudentId] = useState(null);
  const [selectedDate, setSelectedDate] = useState("");
  const [enrollmentDateFrom, setEnrollmentDateFrom] =useState("")
const [enrollmentDateTo,setEnrollmentDateTo] =useState("")
const [branch,setBranch] =useState([])
const [trainerName,setTrainerName] =useState([])
const [className,setClassName] =useState([])
const [status, setStus] =useState([])
const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const handleStatusChange = (event) => {
    setSelectedStatus(event.target.value);
  };

 
  const [isFilterOpen, setFilterOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
const [studentRowId, setStudentRowId] =useState(null)

  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [snackbarSeverity, setSnackbarSeverity] = useState("success");
  const [originalData,setOriginalData] =useState([])

  const showSnackbar = (message, severity) => {
    setSnackbarMessage(message);
    setSnackbarSeverity(severity);
    setSnackbarOpen(true);
  };

  const toggleFilterSection = () => {
    setFilterOpen(!isFilterOpen);
  };

  const allBranchList =[{branch_name:"Select All"} ,...branchlist]
 const handleBranchChange= (event,value)=>{
  if(value.some((option)=>option.branch_name==="Select All")){
    setBranch(branchlist)
    }
    else{
      setBranch(value)
    }
 }

const allTrainerList=[{first_name:"Select All"},...trainerlist]

 const handleChangeTrainer =(event, value)=>{
if(value.some((option)=>option.first_name==="Select All")){
setTrainerName(trainerlist)
}
else{
  setTrainerName(value)
}
 }

 const allClassListList=[{name:"Select All"},...classlist]

 const handleChangeClass =(event, value)=>{
if(value.some((option)=>option.name==="Select All")){
setClassName(classlist)
}
else{
  setClassName(value)
}
 }
 
  const handleApplyFilter = async (event) => {
    setIsLoading(true);

    setPage(0)
    const token = getTokenOrLogout();
    if (!token) return;
    let headers = {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json", // Make sure to keep the content type
    };

    const requestData = {
     branch_id:branch.map((i)=>i.id),
    trainer_id:trainerName.map((i)=>i.id),
    class_id:className.map((i)=>i.id),
    status:status.map((i)=>i.value),
    user_id:parseInt(userId),
    from_date:enrollmentDateFrom || null,
    to_date:enrollmentDateTo || null
    };

    try {
      const response = await fetch(
        `${API_ENDPOINT}route2.0/attendancefilter`,
        {
          method: "POST",
          headers,
          body: JSON.stringify(requestData),
        }
      );

      if (response.ok) {
        const result = await response.json();
        if (result.length === 0) {
          showSnackbar("No data Available", "error");
          setIsLoading(false);
        }
      
        setStudentData(result)
      
        setIsLoading(false);
      } else if (response.status === 404) {
        
        navigate("/page404");
      } else if (response.status === 401) {
        navigate("/page401");
      } else if (response.status === 403) {
        navigate("/page403");
      } else if (response.status === 440) {
        navigate("/page440");
      } else {
        navigate("/page500");
      }
    } catch (error) {
      console.error("Error fetching data:", error);
      navigate("/page500");
    }
  };

  const resetFilters = () => {
    setBranch([])
    setClassName([])
    setTrainerName([])
    setStus([])
    setEnrollmentDateFrom("")
    setEnrollmentDateTo("")
    setStudentData(originalData);
  };

  function handleEditAttendance(date, status) {
    try {
      setSelectedDate(date);
      setSelectedStatus(status);
      setDialogOpen2(true);
    } catch (error) {
      console.error("Error editing attendance:", error);
      showSnackbar("Error editing attendance:", "error");
    }
  }

  const handleViewAttendance = async (studentId, classId,rowId) => {
    console.log("studentId",studentId)
    console.log("studentId",studentData)
    console.log('studentId',rowId)
    setStudentRowId(rowId)
    try {
      const response = await fetch(`${API_ENDPOINT}route/viewattendance`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ student_id: studentId, class_id: classId ,student_class_details_id:rowId}),
      });

      if (!response.ok) {
        throw new Error("Failed to fetch attendance data");
      }

      const attendanceData = await response.json();

  
      // Assuming you want to set the selected student data, if needed
      const student = studentData.find((student) => student.student_id?.id === studentId);
      setSelectedStudent(student);

      setSelectedStudentAttendance(attendanceData);
      setDialogOpen(true);

      setSelectedStudentId(studentId);
      setSelectedClassId(classId);
    } catch (error) {
      console.error("Error fetching attendance data:", error);
    }
  };

  const handleCloseDialog = () => {
    setDialogOpen(false);
  };

  const handleCloseDialog2 = () => {
    setDialogOpen2(false);
  };

  useEffect(() => {
    setIsLoading(true);
    const token = getTokenOrLogout();
    if (!token) return;
    let headers = {
      Authorization: `Bearer ${token}`,
    };
    const fetchDemoData = async () => {
      try {
        // Fetch demo data based on the user ID
        const response = await fetch(
          `${API_ENDPOINT}route/studentattlist/${userId}`,
          { headers }
        );

        if (response.ok) {
          const data = await response.json();
          console.log(data);

          setStudentData(data);
        
          setOriginalData(data)

          setIsLoading(false);
        }
        // Handle non-successful responses
        else if (response.status === 404) {
          navigate("/page404");
        } else if (response.status === 401) {
          navigate("/page401");
        } else if (response.status === 403) {
          navigate("/page403");
        } else if (response.status === 440) {
          navigate("/page440");
        } else {
          navigate("/page500");
        }
      } catch {
        navigate("/page500");
      }
    };

    // Only fetch data if userId is available
    if (userId) {
      fetchDemoData();
    }
  }, [userId]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(
          `${API_ENDPOINT}route/fetchbranch/${userId}`
        );
        if (response.ok) {
          const data = await response.json();
          console.log(data);
          setbranchlist(data);
        } else {
          throw new Error(`Request failed with status: ${response.status}`);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(
          `${API_ENDPOINT}route2.0/gettrainers/${userId}`
        );
        if (response.ok) {
          const data = await response.json();
          console.log(data);
          settrainerlist(data);
        } else {
          throw new Error(`Request failed with status: ${response.status}`);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, []);

  const handleFetchClass = async (event) => {
  

    

    const requestData = {
      
        user_id:parseInt(userId),
        branch_id:branch.map((i)=>i.id),
        trainer_id:trainerName.map((i)=>i.id)
        
    };

    try {
      const response = await fetch(
        `${API_ENDPOINT}route2.0/classbybranchandtrainer`,
        {
          method: "POST",
          headers :{
"Content-Type": "application/json",
          },
          body: JSON.stringify(requestData),
        }
      );

      if (response.ok) {
        const result = await response.json();
        setclasslist(result)
      } else {
        console.error("Error fetching data:");
      }
    } catch (error) {
      console.error("Error fetching data:", error);
      console.error("Error fetching data:", error);
    }
  };

  useEffect(() => {
    handleFetchClass()
  }, [branch,trainerName]);

 

  const StyledGridOverlay = styled("div")(({ theme }) => ({
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    height: "100%",
    "& .ant-empty-img-1": {
      fill: theme.palette.mode === "light" ? "#aeb8c2" : "#262626",
    },
    "& .ant-empty-img-2": {
      fill: theme.palette.mode === "light" ? "#f5f5f7" : "#595959",
    },
    "& .ant-empty-img-3": {
      fill: theme.palette.mode === "light" ? "#dce0e6" : "#434343",
    },
    "& .ant-empty-img-4": {
      fill: theme.palette.mode === "light" ? "#fff" : "#1c1c1c",
    },
    "& .ant-empty-img-5": {
      fillOpacity: theme.palette.mode === "light" ? "0.8" : "0.08",
      fill: theme.palette.mode === "light" ? "#f5f5f5" : "#fff",
    },
  }));

  function CustomNoRowsOverlay() {
    return (
      <StyledGridOverlay>
        
        <svg
          style={{ flexShrink: 0 }}
          width="240"
          height="200"
          viewBox="0 0 184 152"
          aria-hidden
          focusable="false"
        >
          <g fill="none" fillRule="evenodd">
            <g transform="translate(24 31.67)">
              <ellipse
                className="ant-empty-img-5"
                cx="67.797"
                cy="106.89"
                rx="67.797"
                ry="12.668"
              />
              <path
                className="ant-empty-img-1"
                d="M122.034 69.674L98.109 40.229c-1.148-1.386-2.826-2.225-4.593-2.225h-51.44c-1.766 0-3.444.839-4.592 2.225L13.56 69.674v15.383h108.475V69.674z"
              />
              <path
                className="ant-empty-img-2"
                d="M33.83 0h67.933a4 4 0 0 1 4 4v93.344a4 4 0 0 1-4 4H33.83a4 4 0 0 1-4-4V4a4 4 0 0 1 4-4z"
              />
              <path
                className="ant-empty-img-3"
                d="M42.678 9.953h50.237a2 2 0 0 1 2 2V36.91a2 2 0 0 1-2 2H42.678a2 2 0 0 1-2-2V11.953a2 2 0 0 1 2-2zM42.94 49.767h49.713a2.262 2.262 0 1 1 0 4.524H42.94a2.262 2.262 0 0 1 0-4.524zM42.94 61.53h49.713a2.262 2.262 0 1 1 0 4.525H42.94a2.262 2.262 0 0 1 0-4.525zM121.813 105.032c-.775 3.071-3.497 5.36-6.735 5.36H20.515c-3.238 0-5.96-2.29-6.734-5.36a7.309 7.309 0 0 1-.222-1.79V69.675h26.318c2.907 0 5.25 2.448 5.25 5.42v.04c0 2.971 2.37 5.37 5.277 5.37h34.785c2.907 0 5.277-2.421 5.277-5.393V75.1c0-2.972 2.343-5.426 5.25-5.426h26.318v33.569c0 .617-.077 1.216-.221 1.789z"
              />
            </g>
            <path
              className="ant-empty-img-3"
              d="M149.121 33.292l-6.83 2.65a1 1 0 0 1-1.317-1.23l1.937-6.207c-2.589-2.944-4.109-6.534-4.109-10.408C138.802 8.102 148.92 0 161.402 0 173.881 0 184 8.102 184 18.097c0 9.995-10.118 18.097-22.599 18.097-4.528 0-8.744-1.066-12.28-2.902z"
            />
            <g className="ant-empty-img-4" transform="translate(149.65 15.383)">
              <ellipse cx="20.654" cy="3.167" rx="2.849" ry="2.815" />
              <path d="M5.698 5.63H0L2.898.704zM9.259.704h4.985V5.63H9.259z" />
            </g>
          </g>
        </svg>
        <Box sx={{ mt: 1 }}>No Data Available</Box>
      </StyledGridOverlay>
    );
  }

  const attendanceColumns = [
    { field: "date", headerName: "Date", flex: 1 ,    headerClassName: "header-cell",},
    {
      headerClassName: "header-cell",
      field: "status",
      headerName: "Status",
      flex: 1,
      renderCell: (params) => {
        let chipColor = "";
        let chipLabel = "";

        switch (params.value) {
          case "1":
            chipColor = "secondary";
            chipLabel = "Present";
            break;
          case "0":
            chipColor = "error";
            chipLabel = "Absent";
            break;
          case "-1":
            chipColor = "primary";
            chipLabel = "Absent with permission";
            break;
          default:
            chipColor = "orange";
            chipLabel = "Unknown";
        }

        return (
          <Chip
          size="small"
            label={chipLabel}
            color={chipColor === "default-color" ? "default" : chipColor}
          />
        );
      },
    },
    {
      headerClassName: "header-cell",
      field: "action",
      headerName: "Action",
      flex: 1,
      renderCell: (params) => (
        <>
          <Button
          size="small"
            variant="contained"
            color="error"
            startIcon={<EditIcon />}
            onClick={() =>
              handleEditAttendance(params.row.date, params.row.status)
            }
          >
            Edit
          </Button>
        </>
      ),
    },
  ];

  function handleEditAttendanceConfirmed(status) {
    try {
      const data = {
        date: selectedDate,
        class_id: selectedClassId,
        attendance_val: status,
        student_class_details_id:studentRowId
      };

      if (selectedStudentId) {
        fetch(
          `${API_ENDPOINT}attendance/updateattendanceby/${selectedStudentId}`,
          {
            method: "PUT",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify(data),
          }
        )
          .then((response) => {
            if (response.ok) {
              showSnackbar("Attendance edited successfully", "success");
              handleCloseDialog2();
              handleCloseDialog();
            } else {
              showSnackbar("Failed to edit attendance", "error");
            }
          })
          .catch((error) => {
            console.error("Error editing attendance:", error);
            showSnackbar("Error editing attendance:", "error");
          });
      }
    } catch (error) {
      console.error("Error editing attendance:", error);
      showSnackbar("Error editing attendance:", "error");
    }
  }

  function CustomExportButton({ onClick }) {
    return (
      <Button onClick={onClick} variant="contained" color="secondary">
        Export CSV
      </Button>
    );
  }

  console.log("selectedStudent",selectedStudent)
  const StyledTableContainer = styled(TableContainer)(({ theme }) => ({
    marginTop: "20px",
    "& .MuiTableCell-head": {
      backgroundColor: "#abf7b1",
      fontWeight: 600,
      padding: "8px 16px",
      fontSize: "17px",
      color: "#000",
      textAlign: "center",
      fontFamily: "Times New Roman",
    
    },
    "& .MuiTableCell-body": {
      textAlign: "center",
      padding: "8px 16px",
      overflow: "hidden", // Hide overflowing content
      textOverflow: "ellipsis", // Show ellipsis when content overflows

      whiteSpace: "normal", // Allow text to wrap inside the cell
      wordWrap: "break-word",
    },
  }));

  
  const startIndex = page * rowsPerPage;
  const endIndex = startIndex + rowsPerPage;
  const paginatedData= studentData.slice(startIndex, endIndex);
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  return (
    <>
      <Backdrop open={isLoading} style={{ zIndex: 9999 }}>
        <Stack direction="column" spacing={1}>
          <Typography
            variant="h4"
            color="secondary"
            fontWeight={800}
            marginRight={2}
          >
            Fetching
          </Typography>
          <CircularProgress color="primary" />
        </Stack>
      </Backdrop>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <MainCard>
            <Box display="flex" justifyContent="space-between">
              <Box>
                <Typography variant="h3">Student Attendance</Typography>
              </Box>
              <Box>
                <IconButton onClick={toggleFilterSection}>
                  <FilterListIcon />
                </IconButton>
              </Box>
            </Box>
            <Collapse in={isFilterOpen}>
              <Grid container spacing={2}>
             


                <Grid item xs={12} sm={6} md={4} lg={3}>
                  <Typography>Enrollment Date From</Typography>
                  <TextField
                    InputProps={{
                      style: { borderRadius: "15px" }, // Add borderRadius here
                    }}
                    size="small"
               onChange={(e)=>setEnrollmentDateFrom(e.target.value)}
                    value={enrollmentDateFrom}
                    
                    fullWidth
                    type="date"
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={4} lg={3}>
                <Typography>Enrollment Date To</Typography>
                  <TextField
                    InputProps={{
                      style: { borderRadius: "15px" }, // Add borderRadius here
                    }}
                    size="small"
                    onChange={(e)=>setEnrollmentDateTo(e.target.value)}
                    value={enrollmentDateTo}
     
                    fullWidth
                    type="date"
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={4} lg={3}>
               
<Typography>Branch</Typography>
                    <Autocomplete
                                size="small"
                                value={branch}
                                multiple
                                limitTags={2}
                                onChange={handleBranchChange}
                                options={allBranchList}
                                getOptionLabel={(option) => option.branch_name}
                                renderInput={(params) => (
                                  <TextField
                                    {...params}
                                    InputProps={{
                                      ...params.InputProps,
                                      style: { borderRadius: "15px" }, // Add borderRadius here
                                    }}
                                  />
                                )}
                              />
                </Grid>

                <Grid item xs={12} sm={6} md={4} lg={3}>
                

<Typography>Select Trainer </Typography>
                    <Autocomplete
                                size="small"
                                value={trainerName}
                                multiple
                                limitTags={2}
                                onChange={handleChangeTrainer}
                                options={allTrainerList}
                                getOptionLabel={(option) =>  `${option.first_name ?option.first_name :""} ${option.last_name?option.last_name:""}`}
                                renderInput={(params) => (
                                  <TextField
                                    {...params}
                                    InputProps={{
                                      ...params.InputProps,
                                      style: { borderRadius: "15px" }, // Add borderRadius here
                                    }}
                                  />
                                )}
                              />
                </Grid>

                <Grid item xs={12} sm={6} md={4} lg={3}>
                
                  <Typography>Select Class </Typography>
                    <Autocomplete
                                size="small"
                                value={className}
                                multiple
                                limitTags={2}
                                onChange={handleChangeClass}
                                options={allClassListList}
                                getOptionLabel={(option) => option.name}
                                renderInput={(params) => (
                                  <TextField
                                    {...params}
                                    InputProps={{
                                      ...params.InputProps,
                                      style: { borderRadius: "15px" }, // Add borderRadius here
                                    }}
                                  />
                                )}
                              />
                </Grid>
                <Grid item xs={12} sm={6} md={4} lg={3}>
                 

<Typography>Select Status </Typography>
                    <Autocomplete
                                size="small"
                                value={status}
                                multiple
                                onChange={(e,value)=>setStus(value)}
                                options={statusOptions}
                                getOptionLabel={(option) => option.label}
                                renderInput={(params) => (
                                  <TextField
                                    {...params}
                                    InputProps={{
                                      ...params.InputProps,
                                      style: { borderRadius: "15px" }, // Add borderRadius here
                                    }}
                                  />
                                )}
                              />
                </Grid>
                <Grid
                  item
                  xs={12}
                  sm={12}
                  lg={12}
                  md={12}
                  sx={{ textAlign: "end" }}
                >
                  <Button
                    variant="contained"
                    style={{
                      marginRight: "7px",
                      borderRadius: "25px",
                    }}
                    onClick={handleApplyFilter}
                  >
                    {" "}
                    Apply Filter
                  </Button>

                  <Button
                    variant="contained"
                    color="error"
                    style={{ borderRadius: "25px" }}
                    onClick={resetFilters}
                  >
                    {" "}
                    Reset Filter
                  </Button>
                </Grid>
              </Grid>
            </Collapse>
          </MainCard>
        </Grid>

        <Grid item xs={12}>
          <MainCard>
            <Box
              sx={{
                height: "100%",
                width: "100%",
                "& .super-app-theme--header": {
                  backgroundColor: "#abf7b1",
                  fontWeight: "bold",
                },
              }}
            >
              
<StyledTableContainer component={Paper}>
              <Table
                sx={{ minWidth: 650, tableLayout: "fixed" }}
                aria-label="simple table"
                stickyHeader
              >
                <TableHead>
                  <TableRow>
                    <TableCell style={{width:'7%'}}>Enroll Id</TableCell>
                    <TableCell style={{width:'10%'}}>Student Name</TableCell>
                    <TableCell style={{width:'10%'}}>Enrollment Date</TableCell>
                    <TableCell style={{width:'10%'}}>Branch</TableCell>
                    <TableCell style={{width:'10%'}}>Trainer</TableCell>
                    <TableCell style={{width:'15%'}}>Class</TableCell>
                    <TableCell style={{width:'7%'}}>Status</TableCell>
                    <TableCell style={{width:'6%'}}>Present Days</TableCell>
                    <TableCell style={{width:'6%'}}>Absent Days</TableCell>
                    <TableCell style={{width:'6%'}}>Days Skipped</TableCell>
                    <TableCell sx={{}}>Action</TableCell>
                   
                  </TableRow>
                </TableHead>

                {paginatedData?.length === 0 ? (
                  <TableRow>
                    <TableCell colSpan={11}>
                    <Typography variant="h5">No data available</Typography>
                    </TableCell>
                  </TableRow>
                  
                 
                ) : (
                  paginatedData?.map((row, index) => {
                  
                  

                    return (
                      <TableBody>
                        <TableRow
                          key={row.name}
                          style={{
                            backgroundColor:
                              index % 2 === 0 ? "#f0f0f0" : "inherit",
                          }}
                        >
                          <TableCell
                            component="th"
                            scope="row"
                            style={{ borderLeft: "0.1px solid #C3C3C3" }}
                          >
                            {row?.student_id?.lead_no}
                          </TableCell>
                          <TableCell>
                          {row?.enquiry_id?.first_name}{" "}
                          {row?.enquiry_id?.last_name}
                          </TableCell>
                          <TableCell> {row?.enrollment_date}</TableCell>

                          <TableCell>
                          {row?.branch_id?.branch_name}
                          </TableCell>
                          <TableCell
                           
                          >
                           {row?.class_id?.trainer_id?.first_name}{" "}
                           {row?.class_id?.trainer_id?.last_name}
                          </TableCell>
<TableCell>{row?.class_id?.name}</TableCell>
<TableCell>{row?.status_of_student}</TableCell>
<TableCell>{row?.present_days}</TableCell>
<TableCell>{row?.absent_days}</TableCell>
<TableCell>{row?.skipped_days}</TableCell>
<TableCell style={{ borderRight: "0.1px solid #C3C3C3" }}> <Button
size="small"
          variant="contained"
          color="secondary"
          onClick={() =>
            handleViewAttendance(
             row?.student_id?.id,
           row?.class_id?.id,
              row?.id,
            )
          }
        >
          View Attendance
          
        </Button></TableCell>
                        </TableRow>
                      </TableBody>
                    );
                  })
                )}
              </Table>
            </StyledTableContainer>
 <TablePagination
          rowsPerPageOptions={[5, 10, 25]}
          component="div"
          count={studentData.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
            </Box>
          </MainCard>
        </Grid>
      </Grid>

      <Dialog
        open={isDialogOpen}
        onClose={handleCloseDialog}
        maxWidth="lg"
        fullWidth
      >
        <IconButton
          edge="end"
        
          onClick={handleCloseDialog}
          aria-label="close"
          sx={{ position: "absolute", right: 15, top: 2 }}
        >
          <CloseIcon />
          </IconButton>
        <DialogContent>
          {selectedStudent && (
            <>
              <Typography variant="h4" gutterBottom>
                {`${selectedStudent?.enquiry_id?.first_name}'s Attendance`}
              </Typography>
              <Typography variant="subtitle1" gutterBottom>
                Enrollment No: {selectedStudent?.lead_no}
              </Typography>
              <Typography variant="subtitle1" gutterBottom>
                Class: {selectedStudent?.class_id?.name}
              </Typography>

             <Box  sx={{
            height: "100%",
            width: "100%",
            "& .header-cell": {
              backgroundColor: "#abf7b1",
              fontWeight: 600,
              borderRight: "0.1px solid #C3C3C3",
            },
          }}>
             
              <DataGrid
                   getRowId={() => Math.floor(Math.random() * 100000000)}
                          disableColumnSorting
                          rows={selectedStudentAttendance}
                columns={attendanceColumns}
                          autoHeight
                          slots={{
                            noRowsOverlay: CustomNoRowsOverlay,
                          }}
                          initialState={{
                            pagination: {
                              paginationModel: {
                                pageSize: 25,
                              },
                            },
                          }}
                          sx={{ marginTop: "20px" }}
                          pageSizeOptions={[25, 50, 100]}
                        />
                          </Box>
            </>
          
          )}
        </DialogContent>
      </Dialog>

      <Dialog
        open={isDialogOpen2}
        onClose={handleCloseDialog2}
        maxWidth="xs"
        fullWidth
      >
        <DialogTitle variant="h4">Select Status
        <IconButton
          edge="end"
        
          onClick={handleCloseDialog2}
          aria-label="close"
          sx={{ position: "absolute", right: 15, top: 2 }}
        >
          <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <FormControl fullWidth>
      
            <Select value={selectedStatus} onChange={handleStatusChange}>
              <MenuItem value={"1"}>Present</MenuItem>
              <MenuItem value={"0"}>Absent</MenuItem>
              <MenuItem value={"-1"}>Absent with permission</MenuItem>
            </Select>
          </FormControl>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => handleEditAttendanceConfirmed(selectedStatus)}
            color="secondary"
            variant="contained"
          >
            Confirm
          </Button>
          <Button onClick={handleCloseDialog2} color="primary">
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
      <CustomSnackbar
        open={snackbarOpen}
        onClose={() => setSnackbarOpen(false)}
        message={snackbarMessage}
        severity={snackbarSeverity}
      />
    </>
  );
}

export default ViewAttendance;
