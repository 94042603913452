import {
  Box,
  Button,
  Chip,
  CircularProgress,
  Divider,
  Grid,
  MenuItem,
  Select,
  Stack,
  TextField,
  Typography,
  Menu,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import MainCard from "../../components/Maincard";
import AdminRevenueCard from "../AdminRevenue/AdminRevenueCard";
import ReactApexChart from "react-apexcharts";
import LeadCalandar from "../LeadConversation/LeadCalandar";
import AdvisorRevenueTable from "./AdvisorRevenueTable";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import CustomSnackbar from "../../newTestPrep/components/ui-component/snackbar/CustomSnackbar";
import { useNavigate, useParams } from "react-router-dom";
import { useGetTokenOrLogout } from "../../utils/token";
import { useDateUtils } from "../../utils/formatTime";
export default function AdvisorRevenue() {
  const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT;
  const { id } = useParams();

  const getTokenOrLogout = useGetTokenOrLogout();
  const navigate = useNavigate();
  const { currentMonthName, lastMonthName, weekRange } = useDateUtils();

  console.log(id);
  const [chosenDate, setChosenDate] = useState(new Date());
  const [profileData, setProfileData] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [targetLoading, setTargetLoading] =useState(true)
  const [monthlyData, setMonthlyData] = useState({});
  const [dailyData, setDailyData] = useState({});
  const [isPieChartLoading, setIsPieChartLoading] = useState(false);
  const [pieChartData, setPieChartData] = useState({});
  const [filterType, setFilterType] = useState("today");
  const [fromDate, setFromDate] = useState(null);
  const [toDate, setToDate] = useState(null);
  const [revenuedata, setRevenuedata] = useState([]);

  const [fromDate1, setFromDate1] = useState(null);
  const [toDate1, setToDate1] = useState(null);
  const [anchorEl, setAnchorEl] = useState(null);
  const [selectedOption, setSelectedOption] = useState("today");
  const [selectedOptionLabel, setSelectedOptionLabel] = useState("Today");
  const [snackbarOpen, setSnackbarOpen] = React.useState(false);
  const [snackbarMessage, setSnackbarMessage] = React.useState("");
  const [snackbarSeverity, setSnackbarSeverity] = React.useState("success");
  const [collectedLoading, setCollectedloading] = useState(false);
  const [userLoading, setUserloading] = useState(false);

  const showSnackbar = (message, severity) => {
    setSnackbarMessage(message);
    setSnackbarSeverity(severity);
    setSnackbarOpen(true);
  };
  console.log(chosenDate);
  const capitalizeFirstLetter = (str) => {
    return str?.charAt(0).toUpperCase() + str?.slice(1);
  };

  const handleFilterChange = (event) => {
    setFilterType(event.target.value);
  };

  const handleFromDateChange = (event) => {
    setFromDate(event.target.value);
  };

  const handleToDateChange = (event) => {
    setToDate(event.target.value);
  };

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleSelectOption = (option, label) => {
    console.log(option);
    setSelectedOption(option);
    setSelectedOptionLabel(label);
    setAnchorEl(null); // Close the menu after selecting an option
    setFromDate1("");
    setToDate1("");
  };

  const fetchProfileData = () => {
    setUserloading(true);
    fetch(`${API_ENDPOINT}route/profile/${id}`)
      .then((res) => {
        if (res.status === 200) {
          return res.json();
        } else if (res.status === 404) {
          throw new Error("Couldn't find profile!!");
        } else if (res.status === 400) {
          throw new Error("Oops! This is a student!!");
        } else {
          throw new Error(`Unexpected status code: ${res.status}`);
        }
      })
      .then((data) => {
        console.log(data);
        setProfileData(data);
        setUserloading(false);
      })
      .catch((error) => console.error("Error fetching advisor data:", error));

    console.log(profileData);
    setUserloading(false);
  };

  const fetchAdvisorMonthlyData = async () => {
    setTargetLoading(true);
   
   

    try {
      const response = await fetch(
        `${API_ENDPOINT}route2.0/gettargetandrevenue/${id}`
    
      );
      if (response.ok) {
        const data = await response.json();
        console.log("data", data);

        setMonthlyData(data);
        setTargetLoading(false);
      } else if (response.status === 404) {
        navigate("/page404");
      } else if (response.status === 401) {
        navigate("/page401");
      } else if (response.status === 403) {
        navigate("/page403");
      } else if (response.status === 440) {
        navigate("/page440");
      } else {
        navigate("/page500");
      }
    } catch (error) {
      navigate("/page500");
    }
  };

  console.log(monthlyData);
  const handleDateChange = (date) => {
    setChosenDate(date);
  };

  
  const fetchAdvisorDailyData = async (event) => {
    setIsLoading(true);
 
    let headers = {
      
      "Content-Type": "application/json", // Make sure to keep the content type
    };

    const requestData = {
      user_id: id,

      filter: selectedOption,
      to_date: toDate1,
      from_date: fromDate1,
    };

    try {
      const response = await fetch(`${API_ENDPOINT}route2.0/gettodayrevenue`, {
        method: "POST",
        headers,
        body: JSON.stringify(requestData),
      });

      if (response.ok) {
        const result = await response.json();
        setDailyData(result);
        setIsLoading(false);
      } else if (response.status === 404) {
        navigate("/page404");
      } else if (response.status === 401) {
        navigate("/page401");
      } else if (response.status === 403) {
        navigate("/page403");
      } else if (response.status === 440) {
        navigate("/page440");
      } else {
        navigate("/page500");
      }
    } catch (error) {
      console.error("Error fetching data:", error);
      navigate("/page500");
    }
  };

  const handleApplyFilter1 = () => {
    if (toDate1 < fromDate1) {
      // Display error message
      console.error("From date should be less than to date");
      showSnackbar("From date should be less than to date", "error");
      return; // Stop execution if dates are invalid
    }
    // Fetch daily data if dates are valid
    fetchAdvisorDailyData();
  };



  const getCollectedRevenue = async (event) => {
    setCollectedloading(true);
  
    let headers = {
     
      "Content-Type": "application/json", // Make sure to keep the content type
    };

     const requestData = {
      user_id: id,
      filter: filterType,
      to_date: toDate,
      from_date: fromDate,
    };


    try {
      const response = await fetch(`${API_ENDPOINT}route2.0/collectedrevenue`, {
        method: "POST",
        headers,
        body: JSON.stringify(requestData),
      });

      if (response.ok) {
        const data = await response.json();
        setRevenuedata(data);
        console.log(data);
        setCollectedloading(false);
      } else if (response.status === 404) {
        navigate("/page404");
      } else if (response.status === 401) {
        navigate("/page401");
      } else if (response.status === 403) {
        navigate("/page403");
      } else if (response.status === 440) {
        navigate("/page440");
      } else {
        navigate("/page500");
      }
    } catch (error) {
      console.error("Error fetching data:", error);
      navigate("/page500");
    }
  };

  const handleApplyFilter = () => {
    if (toDate < fromDate) {
      // Display error message
      console.error("From date should be less than to date");
      showSnackbar("From date should be less than to date", "error");
      return; // Stop execution if dates are invalid
    }
    getCollectedRevenue();
  };

  const [chartData, setChartData] = useState({
    series: [],
    options: {
      chart: {
        type: "donut",
      },
      plotOptions: {
        pie: {
          donut: {
            size: "50%", // Adjust the size to control the width of the slices
          },
        },
      },
      //   labels: percentages,
      colors: ["#019E6F", "#FABA3A"],
      responsive: [
        {
          breakpoint: 480,
          options: {
            chart: {
              width: 200,
            },
          },
        },
      ],
      tooltip: {
        enabled: false,
      },
      legend: {
        show: false,
      },
    },
  });

  const selectedKeys = ["pending_revenue", "collected_revenue"];

  const arr = Object.entries(pieChartData)
    .filter(([key]) => selectedKeys.includes(key))
    .map(([, value]) => value);

  console.log(arr,"arr");

  useEffect(() => {
    setChartData((prevData) => ({
      ...prevData,
      series: arr,
    }));
  }, [pieChartData]);

  const getPieChartData = async(date) => {
    setIsPieChartLoading(true);

    console.log("date", date);
    
 
    let headers = {
   
      "Content-Type": "application/json", // Make sure to keep the content type
    };
    const requestData = {
      user_id: id,
      date: date,
    };


   

    try {
      const response = await fetch(`${API_ENDPOINT}route2.0/piechart`, {
        method: "POST",
        headers,
        body: JSON.stringify(requestData),
      });

      if (response.ok) {
        const data = await response.json();
           setPieChartData(data);
        setIsPieChartLoading(false);
      } else if (response.status === 404) {
        navigate("/page404");
      } else if (response.status === 401) {
        navigate("/page401");
      } else if (response.status === 403) {
        navigate("/page403");
      } else if (response.status === 440) {
        navigate("/page440");
      } else {
        navigate("/page500");
      }
    } catch (error) {
      console.error("Error fetching data:", error);
      navigate("/page500");
    }
  };

  useEffect(() => {
    fetchAdvisorDailyData();
    fetchProfileData();
    fetchAdvisorMonthlyData();
    getCollectedRevenue();
  }, []);

  useEffect(() => {
    getPieChartData(chosenDate);
  }, [chosenDate]);

  const { first_name, last_name, role, branch_id } = profileData || {};
  console.log(last_name, branch_id);
  return (
    <>
      <Box
        className="box"
        sx={{
          height: "1086px",
          width: "100%",
          position: "relative",
          color: "",
        }}
      >
        <Box
          className="rectangle"
          sx={{
            background:
              "linear-gradient(248deg, #FFF6F0 -41.57%, rgba(255, 246, 240, 0.00) 97.88%)",
            height: "100%",
            position: "absolute",
            top: 0,
            left: 0,
            width: "100%",
            zIndex: -1,
          }}
        />

        <Box
          style={{ textAlign: "left" }}
          sx={{
            position: "absolute",
            // top: '40%',
            // left: '40%',
            // transform: 'translate(-50%, -50%)',
            textAlign: "center",
            zIndex: 1,
          }}
        >
          <Grid container spacing={2}>
            <Grid item xs={12} sm={12} lg={4}>
              <MainCard>
                {userLoading ? (
                  <Box
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                    mt={2}
                  >
                    <CircularProgress />
                  </Box>
                ) : (
                  <>
                    <Typography variant="h4">
                      {first_name ? capitalizeFirstLetter(first_name) : ""}{" "}
                      {last_name ? capitalizeFirstLetter(last_name) : ""}{" "}
                      <Chip
                        label="Advisor"
                        color="primary"
                        sx={{ marginRight: "10px" }}
                      />
                      Branch : {branch_id?.branch_name}
                      <Typography variant="h4"> </Typography>
                    </Typography>
                  </>
                )}
              </MainCard>
            </Grid>

            <Grid item xs={12} sm={6} lg={4} md={6}>
              <MainCard sx={{ background: "#fcdcb6" }}>
                <Stack display="flex" flexDirection="row" alignItems="center">
                  <img src="/newinroll.png" />
                  <Typography
                    sx={{
                      marginLeft: "10px",
                      fontFamily: "Poppins",
                      fontSize: "18px",
                      fontWeight: "600",
                    }}
                  >
                    Target Revenue this month :
                    {targetLoading ? (
                    <CircularProgress />
                  ) : monthlyData?.target_revenue!=="not given" ? (
                    `₹ ${monthlyData.target_revenue}`
                  ) : (
                    "Not Given"
                  )}
                  </Typography>
                </Stack>
              </MainCard>
            </Grid>

            <Grid item xs={12} sm={6} lg={4} md={6}>
              <MainCard sx={{ background: "#b6fcbd" }}>
                <Stack display="flex" flexDirection="row" alignItems="center">
                  <img src="/totalrevenue.png" />
                  <Typography
                    sx={{
                      marginLeft: "10px",
                      fontFamily: "Poppins",
                      fontSize: "18px",
                      fontWeight: "600",
                    }}
                  >
                    Collected Revenue this month: ₹{" "}
                    {targetLoading ? (
                      <CircularProgress />
                    ) : (
                      monthlyData?.total_revenue
                    )}
                  </Typography>
                </Stack>
              </MainCard>
            </Grid>

            <Grid
              item
              xs={12}
              lg={12}
              md={12}
              sm={12}
              container
              justifyContent="flex-end"
            >
              <div style={{ display: "flex", alignItems: "center" }}>
                <Button
                  variant="outlined"
                  style={{
                    color: "#252C58",
                    fontFamily: "Poppins",
                    fontSize: "16px",
                    fontWeight: 400,
                    border: "1px solid #252C58",
                    borderRadius: "8px",
                  }}
                  onClick={handleClick}
                  startIcon={<CalendarMonthIcon />}
                >
                  {selectedOptionLabel}
                </Button>
                <Menu
                  anchorEl={anchorEl}
                  open={Boolean(anchorEl)}
                  onClose={handleClose}
                >
                  <MenuItem
                    onClick={() => handleSelectOption("today", "Today")}
                  >
                    Today
                  </MenuItem>
                  <MenuItem
                    onClick={() => handleSelectOption("currentweek", "This Week")}
                  >
                    This Week({weekRange})
                  </MenuItem>
                  <MenuItem
                    onClick={() => handleSelectOption("currentmonth", "This Month")}
                  >
                    This Month({currentMonthName})
                  </MenuItem>

                  <MenuItem
                    onClick={() => handleSelectOption("pastmonth", "Last Month")}
                  >
                  Last Month({lastMonthName})
                  </MenuItem>
                  <MenuItem
                    onClick={() => handleSelectOption("custom", "Custom")}
                  >
                    Custom
                  </MenuItem>
                </Menu>
                {selectedOption === "custom" && (
                  <div style={{ marginLeft: "10px" }}>
                    <TextField
                      id="date-from"
                      label="From"
                      type="date"
                      defaultValue={fromDate1}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      inputProps={{
                        max: new Date().toISOString().split("T")[0],
                      }}
                      onChange={(e) => setFromDate1(e.target.value)}
                      sx={{ marginLeft: 2 }}
                    />
                    <TextField
                      id="date-to"
                      label="To"
                      type="date"
                      defaultValue={toDate1}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      inputProps={{
                        max: new Date().toISOString().split("T")[0],
                      }}
                      onChange={(e) => setToDate1(e.target.value)}
                      sx={{ marginLeft: 2 }}
                    />
                  </div>
                )}
                <Button
                  variant="contained"
                  color="secondary"
                  sx={{ marginLeft: 2, backgroundColor: "#019E6F" }}
                  style={{
                    color: "#fff",
                    fontFamily: "Poppins",
                    fontSize: "16px",
                    fontWeight: 400,
                    border: "1px solid #252C58",
                    borderRadius: "8px",
                  }}
                  onClick={handleApplyFilter1}
                >
                  Apply Filter
                </Button>
              </div>
            </Grid>

            <Grid item xs={12} lg={12} sm={12} md={12}>
              <Grid container spacing={1}>
                <Grid item xs={12} lg={4} sm={6}>
                  <AdminRevenueCard
                    //primary="2"
                    background="#fcdcb6"
                    iconBackground="#FF6B47"
                    iconSrc="/Revenue.svg"
                    value={
                      isLoading ? (
                        <CircularProgress />
                      ) : (
                        dailyData?.total_revenue
                      )
                    }
                    label="Total Revenue"
                  />
                </Grid>
                <Grid item xs={12} lg={4} sm={6}>
                  <AdminRevenueCard
                    background="#b6fcbd"
                    iconBackground="#3CD856"
                    iconSrc="/Expenses.svg"
                    value={
                      isLoading ? (
                        <CircularProgress />
                      ) : (
                        dailyData?.total_collected_revenue
                      )
                    }
                    label="Total Collected Revenue"
                  />
                </Grid>
                <Grid item xs={12} lg={4} sm={6}>
                  <AdminRevenueCard
                    background="#f7c8e5"
                    iconBackground="#FA5A7D"
                    iconSrc="/Profit.svg"
                    value={
                      isLoading ? (
                        <CircularProgress />
                      ) : (
                        dailyData?.total_pending_revenue
                      )
                    }
                    label="Total Pending Revenue"
                  />
                </Grid>
              </Grid>
            </Grid>

            <Grid item xs={12} lg={9} md={12}>
              <MainCard sx={{ height: "500px" }}>
                <Box display="flex" justifyContent="space-between" mt={2}>
                  <Box>
                    <Typography
                      sx={{
                        color: "#019E6F",
                        fontFamily: "Poppins",
                        fontSize: "23px",
                        fontStyle: "normal",
                        fontWeight: 600,
                      }}
                    >
                      Revenue Collected
                    </Typography>
                  </Box>
                  <Box>
                    <Select
                      value={filterType}
                      onChange={handleFilterChange}
                      sx={{ marginLeft: 2, width: "150px" }}
                    >
                      

                      <MenuItem value="today">Today</MenuItem>
          <MenuItem value="currentweek">This Week({weekRange})</MenuItem>
          <MenuItem value="currentmonth">This Month({currentMonthName})</MenuItem>
          <MenuItem value="pastmonth">Last Month({lastMonthName})</MenuItem>
          <MenuItem value="custom">Custom</MenuItem>
                    </Select>
                    {filterType === "custom" && (
                      <>
                        <TextField
                          type="date"
                          label="From"
                          value={fromDate}
                          onChange={handleFromDateChange}
                          InputLabelProps={{ shrink: true }}
                          sx={{ width: "150px", marginRight: 2, marginLeft: 2 }}
                          inputProps={{
                            max: new Date().toISOString().split("T")[0],
                          }}
                        />
                        <TextField
                          type="date"
                          label="To"
                          value={toDate}
                          onChange={handleToDateChange}
                          InputLabelProps={{ shrink: true }}
                          sx={{ width: "150px", marginRight: 2, marginLeft: 2 }}
                          inputProps={{
                            max: new Date().toISOString().split("T")[0],
                          }}
                        />
                      </>
                    )}
                    <Button
                      variant="contained"
                      color="secondary"
                      sx={{ marginLeft: 2 }}
                      onClick={handleApplyFilter}
                    >
                      Apply Filter
                    </Button>
                  </Box>
                </Box>
                <Divider />

                <AdvisorRevenueTable
                  data={revenuedata}
                  isLoading={collectedLoading}
                />
              </MainCard>
            </Grid>

            <Grid item xs={12} lg={3} md={12}>
              <MainCard>
                <LeadCalandar onDateChange={handleDateChange} />

                {isPieChartLoading ? (
                  <Box
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                    mt={2}
                  >
                    <CircularProgress />
                  </Box>
                ) : (
                  <>
                    <Stack
                      display="flex"
                      flexDirection="column"
                      justifyContent="center"
                      alignItems="center"
                    >
                      <ReactApexChart
                        options={chartData.options}
                        series={chartData.series}
                        type="donut"
                        width="280px"
                      />
                    </Stack>
                    <Stack
                      display="flex"
                      justifyContent="space-between"
                      flexDirection="row"
                    >
                      <Typography
                        sx={{
                          fontWeight: 500,
                          fontSize: "16px",
                          color: "Primary/Gray/600",
                        }}
                      >
                        Total Revenue
                      </Typography>
                      <Typography
                        sx={{
                          fontWeight: 500,
                          fontSize: "16px",
                          color: "Primary/Gray/600",
                        }}
                      >
                        {pieChartData?.total_revenue}
                      </Typography>
                    </Stack>

                    <Stack
                      display="flex"
                      flexDirection="row"
                      alignItems="center"
                      justifyContent="space-between"
                    >
                      <Stack>
                        <Box
                          sx={{
                            bgcolor: "#01BE85",
                            width: 10,
                            height: 10,
                            borderRadius: "50%",
                          }}
                        />
                      </Stack>
                      <Stack
                        display="flex"
                        flexDirection="row"
                        alignItems="center"
                        width="100%"
                        justifyContent="space-between"
                        marginLeft={1}
                      >
                        <Typography>Collected Revenue </Typography>
                        <Typography>
                          {pieChartData?.collected_revenue}
                        </Typography>
                      </Stack>
                    </Stack>
                    <Stack
                      display="flex"
                      flexDirection="row"
                      alignItems="center"
                      justifyContent="space-between"
                    >
                      <Stack>
                        <Box
                          sx={{
                            bgcolor: "#FABA3A",
                            width: 10,
                            height: 10,
                            borderRadius: "50%",
                          }}
                        />
                      </Stack>
                      <Stack
                        display="flex"
                        flexDirection="row"
                        alignItems="center"
                        width="100%"
                        justifyContent="space-between"
                        marginLeft={1}
                      >
                        <Typography>Pending</Typography>
                        <Typography>{pieChartData?.pending_revenue}</Typography>
                      </Stack>
                    </Stack>
                  </>
                )}
              </MainCard>
            </Grid>
          </Grid>
        </Box>
      </Box>

      <CustomSnackbar
        open={snackbarOpen}
        onClose={() => setSnackbarOpen(false)}
        message={snackbarMessage}
        severity={snackbarSeverity}
      />
    </>
  );
}
