import {
  Box,
  Button,
  Card,
  CircularProgress,
  createTheme,
  Divider,
  Grid,
  TextField,
  ThemeProvider,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import Content from "../Components/Content";
import { useDateUtils } from "../../../utils/formatTime";
import DataCards from "../AdvisorNewDashBoard/DataCards";
import DisplayCurrentDateTime from "../Components/DisplayCurrentDateTime";
import UpcommingEvents from "../Components/UpcommingEvents";
import NewCardcomp from "../../../components/card/NewCardcomp";
import LeadCalandar from "../../LeadConversation/LeadCalandar";
import MonthlyRevenuPichart from "../CenterAdminDashboard/MonthlyRevenuPichart";
import DemoToEnrolment from "./DemoToEnrolment";
import StudentLevelWiseData from "./StudentLevelWiseData";
import { useGetTokenOrLogout } from "../../../utils/token";
import EnrolDataPichart from "./EnrolDataPichart";
import FilterButtonComp from "../Components/FilterButtonComp";

const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT;
export default function TrainerNewDashBoard() {
  const userId = localStorage.getItem("userId");

  const getTokenOrLogout = useGetTokenOrLogout();
  const { currentMonthName, lastMonthName, weekRange } = useDateUtils();
  const [selectedFilter, setSelectedFilter] = useState("today");
  const [fromDate, setFromDate] = useState(null);
  const [toDate, setToDate] = useState(null);

  const [cardData, setcardData] = useState([]);
  const [cardLoading, setCardLoading] = useState(false);
  const [upcommigevent, setUpcommingEvent] = useState([]);
  const [upcommingLoading, setUpcommingLoding] = useState(false);
  const [isPieChartLoading, setIsPieChartLoading] = useState(false);

  const [chosenDate, setChosenDate] = useState(new Date());
  const [enrolData, setEnrolData] = useState([]);
  const [enrolLoading, setEnrolLoading] = useState(false);
  const [trainingStstusData, setTrainingStatusData] = useState([]);
  const [statusLoading, setStatusLoading] = useState(false);

  const handleClickFilter = (criteria) => {
    setSelectedFilter(criteria);
    setFromDate("");
    setToDate("");
    if (criteria !== "custom") {
      handleApplyCardFilter(criteria, null, null);
      // getRevenueForCenters(criteria, null, null);
    }
  };

  // Theme for Textdfield
  const theme = createTheme({
    components: {
      MuiInputLabel: {
        styleOverrides: {
          root: {
            fontSize: "12px", // Set label font size
          },
          outlined: {
            transform: "translate(14px, 14px) scale(1)", // Proper position for label
            "&.MuiInputLabel-shrink": {
              transform: "translate(14px, -6px) scale(0.75)", // Adjust when shrunk
            },
          },
        },
      },

      MuiOutlinedInput: {
        styleOverrides: {
          inputSizeSmall: {
            padding: "4px 4px", // Adjust input padding for small size
          },
          input: {
            fontSize: "9px", // Adjust font size for input content
            height: "22px", // Optional: Adjust input height
          },
          root: {
            borderRadius: "7px", // Optional: Adjust border radius for input
          },
        },
      },
    },
  });

  const handleDateChange = (date) => {
    setChosenDate(date);
  };

  const handleApplyCardFilter = async (criteria, fromDate, toDate) => {
    setCardLoading(true);
    const token = getTokenOrLogout();
    if (!token) return;
    let headers = {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json", // Make sure to keep the content type
    };
    const requestData = {
      user_id: parseInt(userId),

      filter: criteria,
      to_date: toDate || null,
      from_date: fromDate || null,
    };

    try {
      const response = await fetch(
        `${API_ENDPOINT}trainerdashboard/chip-data`,
        {
          method: "POST",
          headers,
          body: JSON.stringify(requestData),
        }
      );

      if (response.ok) {
        const result = await response.json();

        setcardData(result);

        setCardLoading(false);
      } else {
        console.log("Error");
        setCardLoading(false);
      }
    } catch (error) {
      console.log(error, "Error");
      setCardLoading(false);
    }
  };

  // Fetch Upcomming Event
  useEffect(() => {
    setUpcommingLoding(true);
    const token = getTokenOrLogout();
    if (!token) return;
    let headers = {
      Authorization: `Bearer ${token}`,
    };
    const fetchData = async () => {
      try {
        const response = await fetch(
          `${API_ENDPOINT}route2.0/ongoing-upcomingevent/${userId}`,
          { headers }
        );
        if (response.ok) {
          const data = await response.json();
          setUpcommingEvent(data);
          setUpcommingLoding(false);
        } else {
          throw new Error(`Request failed with status: ${response.status}`);
          setUpcommingLoding(false);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
        setUpcommingLoding(false);
      }
    };

    fetchData();
  }, []);

  const handleFetchEnrolData = async (date) => {
    setEnrolLoading(true);
    const token = getTokenOrLogout();
    if (!token) return;
    let headers = {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json", // Make sure to keep the content type
    };
    const requestData = {
      user_id: parseInt(userId),
      date: date,
    };

    try {
      const response = await fetch(
        `${API_ENDPOINT}trainerdashboard/enrollment-contribution`,
        {
          method: "POST",
          headers,
          body: JSON.stringify(requestData),
        }
      );

      if (response.ok) {
        const result = await response.json();

        setEnrolData(result);

        setEnrolLoading(false);
      } else {
        console.log("Error");
        setEnrolLoading(false);
      }
    } catch (error) {
      console.log(error, "Error");
      setEnrolLoading(false);
    }
  };

  useEffect(() => {
    handleFetchEnrolData(chosenDate);
  }, [chosenDate]);

  // Fetch Training Status Data
  useEffect(() => {
    setStatusLoading(true);
    const token = getTokenOrLogout();
    if (!token) return;
    let headers = {
      Authorization: `Bearer ${token}`,
    };
    const fetchData = async () => {
      try {
        const response = await fetch(
          `${API_ENDPOINT}trainerdashboard/training-status/${userId}`,
          { headers }
        );
        if (response.ok) {
          const data = await response.json();
          setTrainingStatusData(data);
          setStatusLoading(false);
        } else {
          setStatusLoading(false);
          throw new Error(`Request failed with status: ${response.status}`);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
        setStatusLoading(false);
      }
    };

    fetchData();
  }, []);

   useEffect(() => {
      if (selectedFilter !== "custom") {
        handleApplyCardFilter(selectedFilter);
      }
  
      if (selectedFilter === "custom" && fromDate !== "" && toDate !== "") {
        handleApplyCardFilter(selectedFilter, fromDate, toDate);
      }
    }, [fromDate, toDate]);
  return (
    <>
      <Grid container spacing={3}>
        <Grid item xs={12} lg={9} sm={12} md={9}>
          <Grid container spacing={2}>
            {/* Content */}
            <Grid item xs={12}>
              <Content />
            </Grid>

            {/* Filter Button */}
            <Grid item xs={12} sm={12} mt={2}>
              <FilterButtonComp
                selectedFilter={selectedFilter}
                handleClickFilter={handleClickFilter}
                fromDate={fromDate}
                toDate={toDate}
                setFromDate={setFromDate}
                setToDate={setToDate}
              />
            </Grid>

            {/* Data Cards */}
            <Grid item xs={12} container spacing={2} gap={1}>
              <Grid item xs={12} sm={3.8} lg={2.9}>
                <DataCards
                  backGround="#D5F6E1"
                  title="Completed Demos"
                  iconSrc="/Group 1000002878.png"
                  value={
                    cardLoading ? (
                      <CircularProgress />
                    ) : (
                      cardData?.completed_demo || 0
                    )
                  }
                />
              </Grid>

              <Grid item xs={12} sm={3.8} lg={2.9}>
                <DataCards
                  backGround="#E7F3FF"
                  title="Assigned Demos"
                  iconSrc="/Group 1000002854.png"
                  value={
                    cardLoading ? (
                      <CircularProgress />
                    ) : (
                      cardData?.assigned_demo || 0
                    )
                  }
                />
              </Grid>

              <Grid item xs={12} sm={3.8} lg={2.9}>
                <DataCards
                  backGround="#FFEDFB"
                  title="Tasks Assigned"
                  iconSrc="/Group 1000002879.png"
                  value={
                    cardLoading ? (
                      <CircularProgress />
                    ) : (
                      cardData?.assigned_task || 0
                    )
                  }
                />
              </Grid>

              <Grid item xs={12} sm={3.8} lg={2.9}>
                <DataCards
                  backGround="#FFF6E2"
                  title="Enquiry Assigned"
                  iconSrc="/Group 1000002880.png"
                  value={
                    cardLoading ? (
                      <CircularProgress />
                    ) : (
                      cardData?.new_enquiry || 0
                    )
                  }
                />
              </Grid>
            </Grid>

            <Grid item xs={12}>
              <NewCardcomp
               
                  height="387px"
                  
                
              >
                <Grid container spacing={2}>
                  <Grid item xs={6} sm={5.8} lg={5.8}>
                    <DemoToEnrolment />
                  </Grid>
                  <Divider
                    orientation="vertical"
                    flexItem
                    sx={{ ml: 2 }} // Add margin for spacing
                  />
                  <Grid item xs={6} sm={5.8} lg={5.8}>
                    <StudentLevelWiseData
                      statusLoading={statusLoading}
                      trainingStstusData={trainingStstusData}
                    />
                  </Grid>
                </Grid>
              </NewCardcomp>
            </Grid>
          </Grid>
        </Grid>

        <Grid item xs={12} lg={3} sm={12} md={3}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <DisplayCurrentDateTime />
            </Grid>
            <Grid item xs={12}>
              <UpcommingEvents
                data={upcommigevent}
                loading={upcommingLoading}
              />
            </Grid>

            <Grid item xs={12}>
              <NewCardcomp height= "270px">
                <Typography
                  variant='h6'
                  sx={{
                    
                    textAlign: "center",
                  }}
                >
                  {" "}
                  Contibution To Demo Conversion
                </Typography>

                <Box mt={1}>
                  <LeadCalandar onDateChange={handleDateChange} />
                  {isPieChartLoading ? (
                    <Box
                      display="flex"
                      justifyContent="center"
                      alignItems="center"
                      mt={2}
                    >
                      <CircularProgress />
                    </Box>
                  ) : (
                    <EnrolDataPichart
                      loading={enrolLoading}
                      pieChartData={enrolData}
                    />
                  )}
                </Box>
              </NewCardcomp>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
}
