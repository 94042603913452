import { Backdrop, Typography } from "@mui/material";
import {
  Document,
  Font,
  Page,
  PDFViewer,
  StyleSheet,
  Text,
  View,
} from "@react-pdf/renderer";

import React, { useEffect, useState } from "react";

Font.register({
  family: "Open Sans",
  fonts: [
    {
      src: "https://cdn.jsdelivr.net/npm/open-sans-all@0.1.3/fonts/open-sans-regular.ttf",
    },
    {
      src: "https://cdn.jsdelivr.net/npm/open-sans-all@0.1.3/fonts/open-sans-600.ttf",
      fontWeight: 600,
    },
  ],
});

export default function DownloadTaskWiseReport() {
  const [revenueData, setRevenueData] = useState([]);
  const [taskData, setTaskData] = useState([]);
  const [loading, setLoading] = useState(true);

  console.log("taskData", taskData);
  const styles = StyleSheet.create({
    document: { padding: 10 },
    page: {
      fontFamily: "Open Sans",

      paddingTop: 30,
      paddingLeft: 60,
      paddingRight: 60,
      lineHeight: 1.5,
      flexDirection: "column",
    },
    heading: {
      textAlign: "center",
      fontWeight: 600,
      fontSize: "18px",
      marginBottom: "10px",
    },

    heading2: {
      textAlign: "center",
      fontWeight: 600,
      fontSize: "14px",
      marginBottom: "10px",
    },
    table: {
      marginTop: 10,
      width: "100%",
      borderCollapse: "collapse",
      marginBottom: 10,
    },
    tableRow: {
      flexDirection: "row",
    },
    tableCell: {
      padding: 5,
      fontSize: 9,
      textAlign: "center",
      flex: 1,
      borderBottom: "1 solid #808080", // Bottom border for rows
      borderLeft: "1 solid #808080", // Left border for columns
    },
    tableCell1: {
      padding: 5,
      fontSize: 9,
      textAlign: "center",
      flex: 1,
      borderBottom: "1 solid #808080", // Bottom border for rows
      borderLeft: "1 solid #808080", // Left border for columns
      borderRight: "1 solid #808080",
    },

    tableHeaderCell1: {
      fontFamily: "Open Sans",
      padding: 5,
      fontSize: 10,
      textAlign: "center",
      fontWeight: "bold",
      flex: 1,
      borderBottom: "1 solid #808080", // Bottom border for header
      borderLeft: "1 solid #808080", // Left border for columns
      backgroundColor: "#7ff2ba",
      color: "#002e17",
      borderRight: "1 solid #808080",
      borderTop: "1 solid #808080",
    },
    tableHeaderCell: {
      fontFamily: "Open Sans",
      padding: 5,
      fontSize: 10,
      textAlign: "center",
      fontWeight: "bold",
      flex: 1,
      borderBottom: "1 solid #808080", // Bottom border for header
      borderLeft: "1 solid #808080", // Left border for columns
      backgroundColor: "#7ff2ba",
      color: "#002e17",
      borderTop: "1 solid #808080",
    },

    title: {
      marginTop: "20px",
      fontSize: "14px",
    },
    subtitle: {
      fontWeight: 600,
      fontSize: "14px",
      fontFamily: "Open Sans",
    },
    chipContainer: {
      marginTop: "6px",
      flexDirection: "row",
      flexWrap: "wrap",
      gap: 3, // Add spacing between chips
    },
    chip: {
      backgroundColor: "#7ff2ba",
      borderRadius: 16,
      paddingHorizontal: 6,
      paddingVertical: 2,
      marginRight: 1,
      marginBottom: 8,
    },
    chipText: {
      fontFamily: "Open Sans",
      fontSize: 9,
    },
  });

  useEffect(() => {
    // Retrieve the data from localStorage
    setLoading(true);
    const storedData = localStorage.getItem("targetdata");

    if (storedData) {
      try {
        const parsedData = JSON.parse(storedData);
        console.log("parsedData", parsedData);

        // Destructure the parsed object to extract individual values
        const { revenueData, advisorName, selectedYear, monthname } =
          parsedData;

        setTaskData(revenueData);
        // Update the state or perform actions with the retrieved data
        setRevenueData(parsedData);
        setLoading(false);
      } catch (error) {
        console.error("Error parsing stored data:", error);
        setLoading(false);
      }
    }
  }, []);

  const labelMap = {
    TotalTask: "Total Task",
    CallsConnected: "Calls Connected",
    CallsMade: "Calls Made",
    VirtualAppointment: "Virtual Appointment",
    BranchVisit: "Branch Visit",
    RevenueCollected: "Revenue Collected",
    DemoCompleted: "Demo Completed",
    Enrollment: "Enrollment",
    LeadAssigned: "Lead Assigned",
    LeadConverted: "Lead Converted",
  };

  return (
    <>
      <Backdrop open={loading} style={{ zIndex: 9999 }}>
        <Typography variant="h2" color="error" fontWeight={800} marginRight={2}>
          Fetching
        </Typography>
        <img
          src="/sand-clock.png"
          alt="Error"
          style={{ width: "200px", height: "200px" }}
        />
      </Backdrop>

      <PDFViewer style={{ width: "100%", height: "100vh" }}>
        <Document style={styles.document}>
          <Page size="A4" style={styles.page}>
            <View>
              <Text style={styles.heading}>Task Wise Revenue Report</Text>
            </View>
            <View style={styles.heading2}>
              <Text>
                {" "}
                {revenueData.monthname} {revenueData.selectedYear?.year}{" "}
              </Text>
            </View>

            {revenueData?.advisorName?.some(
              (name) => name.first_name === "Select All"
            ) ? (
              <Text style={styles.title}>
                Please refer the target report for{" "}
                <Text style={styles.subtitle}>All</Text> Users
              </Text>
            ) : (
              <>
                <Text style={styles.title}>
                  Please refer the target report for :-
                </Text>

                <View style={styles.chipContainer}>
                  {revenueData?.advisorName?.map((name, index) => (
                    <View key={index} style={styles.chip}>
                      <Text style={styles.chipText}>
                        {name.first_name} {name.last_name || ""}
                      </Text>
                    </View>
                  ))}
                </View>
              </>
            )}

            <View style={styles.table}>
              <View style={styles.tableRow}>
                <Text style={[styles.tableHeaderCell]}>Task Category</Text>
                <Text style={styles.tableHeaderCell1}>Measure</Text>
              </View>
              {revenueData?.data?.length === 0 ? (
                <Text style={{ fontSize: "10px" }}>No Data Available</Text>
              ) : (
                taskData.map(([key, value], index) => (
                  <View style={styles.tableRow} key={index}>
                    <Text style={[styles.tableCell]}>
                      {labelMap[key] || key}
                    </Text>
                    <Text style={[styles.tableCell1]}>{value}</Text>
                  </View>
                ))
              )}
            </View>
          </Page>
        </Document>
      </PDFViewer>
    </>
  );
}
