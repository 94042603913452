import React, { useEffect, useState } from 'react'
import ReactApexChart from 'react-apexcharts';

export default function AdminRevenueStat({data}) {
     const [state, setState] = useState({
        series: [
          {
            name: 'Collected Revenue',
            data: [],
          },
        ],
        options: {
          chart: {
            type: 'bar',
            height: 350,
          },
          plotOptions: {
            bar: {
              horizontal: false,
              columnWidth: "15px",
              borderRadius: 5,
              borderRadiusApplication: 'end',
            },
          },
          colors: ['#00B13F'],
          dataLabels: {
            enabled: false,
          },
          stroke: {
            show: true,
            width: 2,
            colors: ['transparent'],
          },
          xaxis: {
            categories: [], // Initially empty categories
            labels: {
              style: {
                fontSize: '8px',
              },
            },
          },
          yaxis: {
            title: {
              text: 'Revenue Collected (₹)',
            },
          },
          fill: {
            opacity: 1,
          },
          tooltip: {
            y: {
              formatter: function (val) {
                return `₹ ${val}`;
              },
            },
          },
        },
      });
    
      // Effect to update the state when the data changes
      useEffect(() => {
        // Check the incoming data structure
        if (data && data.length > 0) {
          // Extract branch names and collected revenue
          const monthName = data.map((month) => month.month_short_name);
          const collectedRevenue = data.map((branch) => parseFloat(branch.collected_revenue) || 0);
    
          // Update the state with the new data
          setState((prevState) => ({
            ...prevState,
            series: [
              {
                name: 'Collected Revenue',
                data: collectedRevenue, // Use collected revenue
              },
            ],
            options: {
              ...prevState.options,
              xaxis: {
                categories: monthName, // Set branch names as categories
              },
            },
          }));
        }
      }, [data]); // Re-run effect whenever `data` changes
  return (
<>
  <ReactApexChart
        options={state.options}
        series={state.series}
        type="bar"
        height={260}
      />
</>
  )
}
