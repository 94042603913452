// customTheme.js
import { createTheme } from "@mui/material/styles";

const CustomThemeDashboard= createTheme({
  components: {
    MuiInputLabel: {
      styleOverrides: {
        root: {
          fontSize: "12px",
        },
        outlined: {
          transform: "translate(14px, 14px) scale(1)",
          "&.MuiInputLabel-shrink": {
            transform: "translate(14px, -6px) scale(0.75)",
          },
        },
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        inputSizeSmall: {
          padding: "4px 4px",
        },
        input: {
          fontSize: "9px",
          height: "22px",
        },
        root: {
          borderRadius: "7px",
        },
      },
    },
  },
});

export default CustomThemeDashboard;
