import { Box, Card, CircularProgress, Grid, Typography } from "@mui/material";
import React, { useEffect } from "react";
import ReactApexChart from "react-apexcharts";
import NewCardcomp from "../../../components/card/NewCardcomp";

export default function StudentLevelWiseData({
  statusLoading,
  trainingStstusData,
}) {
  const [state, setState] = React.useState({
    series: [],
    options: {
      chart: {
        type: "donut",
      },
      plotOptions: {
        pie: {
          startAngle: -90,
          endAngle: 90,
          offsetY: 10,
          customScale: 1.1, // Adjust this value if you need to change the donut's inner size
        },
      },
      colors: ["#FF4B4B", "#01BE85", "#FABA3A"],
      grid: {
        padding: {
          bottom: -100,
        },
      },
      legend: {
        show: false, // This hides the legend
      },
      labels: ["Not Started", "Ongoing", "Training Completed"], // Custom labels for each segment
      tooltip: {
        y: {
          formatter: (value, { seriesIndex }) => {
            // const labels = ['Not Starte', 'Ongoing', 'Training Completed'];
            return `${value}%`; // Custom text with value
          },
          style: {
            fontSize: "10px", // Decrease tooltip font size
          },
        },
      },
      dataLabels: {
        style: {
          fontSize: "10px", // Decrease data label font size
        },
      },
      responsive: [
        {
          breakpoint: 480,
          options: {
            chart: {
              width: 200,
            },
            legend: {
              position: "bottom",
            },
          },
        },
      ],
    },
  });

  // Update the series when trainingStstusData changes
  useEffect(() => {
    if (trainingStstusData) {
      setState((prevState) => ({
        ...prevState,
        series: [
          trainingStstusData.not_started || 0,
          trainingStstusData.ongoing || 0,
          trainingStstusData.training_completed || 0,
        ],
      }));
    }
  }, [trainingStstusData]);

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <NewCardcomp>
          <Typography variant="h5" textAlign="center">
            {" "}
            Students Training Status
          </Typography>
        </NewCardcomp>
      </Grid>

      {statusLoading ? (
        <Box display="flex" justifyContent="center" alignItems="center" mt={2}>
          <CircularProgress />
        </Box>
      ) : (
        <Grid item xs={12}>
          <NewCardcomp height="300px">
            <Grid container mt={1} spacing={1}>
              <Grid item xs={12}>
                <ReactApexChart
                  options={state.options}
                  series={state.series}
                  type="donut"
                  height={230}
                />
              </Grid>
              <Grid
                item
                xs={6}
                display="flex"
                direction="row"
                alignItems="center"
                gap={1}
              >
                <Box
                  sx={{
                    bgcolor: "#FF4B4B",
                    width: 10,
                    height: 10,
                    borderRadius: "50%",
                  }}
                ></Box>
                <Typography variant="h7">Not Started</Typography>
              </Grid>

              <Grid item xs={6}>
                <Typography variant="h7">
                  {trainingStstusData?.not_started}
                </Typography>
              </Grid>

              <Grid
                item
                xs={6}
                display="flex"
                direction="row"
                alignItems="center"
                gap={1}
              >
                <Box
                  sx={{
                    bgcolor: "#01BE85",
                    width: 10,
                    height: 10,
                    borderRadius: "50%",
                  }}
                ></Box>
                <Typography variant="h7">Ongoing</Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography variant="h7">
                  {trainingStstusData?.ongoing}
                </Typography>
              </Grid>

              <Grid
                item
                xs={6}
                display="flex"
                direction="row"
                alignItems="center"
                gap={1}
              >
                <Box
                  sx={{
                    bgcolor: "#FABA3A",
                    width: 10,
                    height: 10,
                    borderRadius: "50%",
                  }}
                ></Box>
                <Typography variant="h7">Training Completed</Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography variant="h7">
                  {trainingStstusData?.training_completed}
                </Typography>
              </Grid>
            </Grid>
          </NewCardcomp>
        </Grid>
      )}
    </Grid>
  );
}
