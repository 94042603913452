import {
  Box,
  CircularProgress,
  Divider,
  Stack,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import ReactApexChart from "react-apexcharts";
import NewCardcomp from "../../../components/card/NewCardcomp";

export default function MonthlyAdvisorRevenuePiechart({
  pieChartData,
  loading,
}) {
  const collectedpercentage =
    (pieChartData?.advisor_collection / pieChartData?.total_branch_collection) *
    100;
  const Otherpercentage =
    (pieChartData?.others_collection / pieChartData?.total_branch_collection) *
    100;
  const [chartData, setChartData] = useState({
    series: [],
    options: {
      chart: {
        type: "donut",
      },
      plotOptions: {
        pie: {
          donut: {
            size: "50%", // Adjust the size to control the width of the slices
          },
        },
      },
      //   labels: percentages,
      colors: ["#019E6F", "#FABA3A"],
      responsive: [
        {
          breakpoint: 480,
          options: {
            chart: {
              width: 200,
            },
          },
        },
      ],
      tooltip: {
        enabled: false,
      },
      legend: {
        show: false,
      },
    },
  });

  const selectedKeys = ["others_collection", "advisor_collection"];

  const arr = Object.entries(pieChartData)
    .filter(([key]) => selectedKeys?.includes(key))
    .map(([, value]) => value);

  console.log("arr", arr);

  useEffect(() => {
    setChartData((prevData) => ({
      ...prevData,
      series: arr,
    }));
  }, [pieChartData]);

  return (
    <>
      {loading ? (
        <Box display="flex" justifyContent="center" alignItems="center" mt={2}>
          <CircularProgress />
        </Box>
      ) : (
        <>
          <Stack
            display="flex"
            flexDirection="column"
            justifyContent="center"
            alignItems="center"
          >
            {pieChartData.advisor_collection === 0 &&
            pieChartData.total_branch_collection === 0 ? (
              <Box
                display="flex"
                justifyContent="center"
                alignItems="center"
                height={170}
              >
                <Typography className="picharttexttotal" fontWeight={600}>
                  No Data Available
                </Typography>
              </Box>
            ) : (
              <ReactApexChart
                options={chartData.options}
                series={chartData.series}
                type="donut"
               width="200px"
              />
            )}
          </Stack>
          <Divider />
          

          <Stack
            display="flex"
            flexDirection="row"
            alignItems="center"
            justifyContent="space-between"
            mt={1}
          >
            <Stack>
              <Box
                sx={{
                  bgcolor: "#01BE85",
                  width: 10,
                  height: 10,
                  borderRadius: "50%",
                }}
              />
            </Stack>
            <Stack
              display="flex"
              flexDirection="row"
              alignItems="center"
              width="100%"
              justifyContent="space-between"
              marginLeft={1}
            >
              <Typography variant="h7">
                Your  Contribution (%)
              </Typography>
              <Typography variant="h7">
                {pieChartData?.advisor_collection !== 0
                  ? `${Math.round(collectedpercentage)}%`
                  : 0}
              </Typography>
            </Stack>
          </Stack>
          <Stack
            display="flex"
            flexDirection="row"
            alignItems="center"
            justifyContent="space-between"
            mt={1}
          >
            <Stack>
              <Box
                sx={{
                  bgcolor: "#FABA3A",
                  width: 10,
                  height: 10,
                  borderRadius: "50%",
                }}
              />
            </Stack>
            <Stack
              display="flex"
              flexDirection="row"
              alignItems="center"
              width="100%"
              justifyContent="space-between"
              marginLeft={1}
            >
              <Typography variant="h7"> Contribution By Team(%)</Typography>
              <Typography variant="h7">
                {pieChartData?.others_collection !== 0
                  ? `${Math.round(Otherpercentage)}%`
                  : 0}
              </Typography>
            </Stack>
          </Stack>
        </>
      )}
    </>
  );
}
