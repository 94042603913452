// Author : Ayesha Shaikh
//  Date : 02 Dec 2024

import {
  Backdrop,
  Box,
  Button,
  ButtonBase,
  Chip,
  CircularProgress,
  Grid,
  IconButton,
  Paper,
  Stack,
  styled,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import CustomPopup from "../../../components/CustomPopup";
import AddTemplateForm from "../Tamplate/AddTemplateForm";
import AddEmployeeTarget from "./AddEmployeeTarget";
import { useNavigate } from "react-router-dom";
import { useGetTokenOrLogout } from "../../../utils/token";
import MainCard from "../../../components/Maincard";
import EditIcon from "@mui/icons-material/Edit";
import EditEmployeeTarget from "./EditEmployeeTarget";
import FilterAltIcon from "@mui/icons-material/FilterAlt";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import EmployeeTargetFilter from "./EmployeeTargetFilter";

const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT;
export default function AllEmployeeTraget() {
  const navigate = useNavigate();
  const userId=localStorage.getItem("userId")
  const getTokenOrLogout = useGetTokenOrLogout();
  const [addPopup, setAddPopup] = useState(false);
  const [editPopup, setEditPopup] = useState(false);

  const [empoyeeTargetData, setEmpoyeeTargetData] = useState([]);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [page, setPage] = React.useState(0);
  const [loading, setloading] = useState(false);
  const [selectedData, setSelectedata] = useState([]);
  const [searchData, setSearchData] = useState([]);
  const [targetOriginalData, setTargetOriginalData] = useState([]);
  const [filterOpen, setFilterOpen] = useState(false);

  // Fetch Api
  useEffect(() => {
    const fetchData = async () => {
      setloading(true);
      const token = getTokenOrLogout();
      if (!token) return;
      let headers = {
        Authorization: `Bearer ${token}`,
      };

      try {
        const response = await fetch(`${API_ENDPOINT}advisor-rev-target/monthandupcomingtarget/${userId}`, {
          headers,
        });
        if (response.ok) {
          const data = await response.json();
          const sortedData = data.sort(
            (a, b) => new Date(b.createdAt) - new Date(a.createdAt)
          );
          setEmpoyeeTargetData(sortedData);
          setSearchData(sortedData);
          setTargetOriginalData(sortedData);
          setloading(false);
        } else if (response.status === 404) {
          navigate("/page404");
        } else if (response.status === 401) {
          navigate("/page401");
        } else if (response.status === 403) {
          navigate("/page403");
        } else if (response.status === 440) {
          navigate("/page440");
        } else {
          navigate("/page500");
        }
      } catch (error) {
        navigate("/page500");
      }
    };

    fetchData();
  }, []);

  //Open Add Popup
  const handleOpenAddPopup = () => {
    setAddPopup(true);
  };

  //Close Add Popup
  const handleCloseAddPopup = () => {
    setAddPopup(false);
  };

  //Open Edit Popup
  const handleOpenEditPopup = (rowData) => {
    setEditPopup(true);
    setSelectedata(rowData);
  };

  //Close Edit Popup
  const handleCloseEditPopup = () => {
    setEditPopup(false);
  };

  const StyledTableContainer = styled(TableContainer)(({ theme }) => ({
    marginTop: "20px",
    "& .MuiTableCell-head": {
      backgroundColor: "#abf7b1",
      fontWeight: 600,
      padding: "8px 16px",

      borderRight: "0.1px solid #C3C3C3",

      color: "#000",
      textAlign: "center",
    },
    "& .MuiTableCell-body": {
      fontSize: "14px",

      borderRight: "0.1px solid #C3C3C3",

      textAlign: "center",
      padding: "8px 16px",
      overflow: "hidden", // Hide overflowing content
      textOverflow: "ellipsis", // Show ellipsis when content overflows

      whiteSpace: "normal", // Allow text to wrap inside the cell
      wordWrap: "break-word",
    },
  }));

  const ActionButton = styled(IconButton)(({ theme }) => ({
    backgroundColor: "#E5E4E2",
  }));

  const startIndex = page * rowsPerPage;
  const endIndex = startIndex + rowsPerPage;
  const paginatedTargets = empoyeeTargetData.slice(startIndex, endIndex);
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event?.target.value, 10));
    setPage(0);
  };

  // Search by employee name
  const handleSearchByName = (e) => {
    const value = e.target.value;
    let filteredData1 = searchData.filter((data) =>
      `${data?.adv_user_id?.first_name} ${data?.adv_user_id?.last_name}`
        ?.toLowerCase()
        .replace(/\s+/g, "")
        .includes(value.toLowerCase().replace(/\s+/g, ""))
    );
    setEmpoyeeTargetData(filteredData1);
  };

  const handleFilterToggel = () => {
    setFilterOpen(!filterOpen);
  };

  // let  currentDate=new Date().toISOString().split("T")[0]
  // console.log("currentDate",currentDate)

  const pastDate = searchData.map((date) => {
    console.log("createdAt", date.createdAt); // Log each date
    const createdDate1 = new Date(date.createdAt);
    console.log("createdDate1", createdDate1);
    const currentDate = new Date();
    console.log("currentDate", currentDate.getMonth());
    return (
      createdDate1.getFullYear() < currentDate.getFullYear() ||
      (createdDate1.getFullYear() === currentDate.getFullYear() &&
        createdDate1.getMonth() < currentDate.getMonth())
    );
  });

  console.log("pastDate", pastDate);

  return (
    <>
      <Backdrop open={loading} style={{ zIndex: 9999 }}>
        <Stack direction="row" spacing={1}>
          <Typography
            variant="h4"
            color="secondary"
            fontWeight={800}
            marginRight={2}
          >
            Fetching
          </Typography>
          <CircularProgress color="primary" />
        </Stack>
      </Backdrop>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={6}>
          <Chip
            label="Employee Target"
            style={{
              background: "white",
              fontWeight: 600,
              fontSize: "20px",
              padding: "10px",
              // height: "40px",
              borderRadius: "25px",
            }}
          />
        </Grid>
        <Grid
          item
          xs={12}
          sm={6}
          textAlign="end"
          container
          direction="row"
          justifyContent="end"
        >
          <Button
            variant="contained"
            size="small"
            startIcon={<AddCircleOutlineIcon fontSize="small" />}
            style={{ borderRadius: "25px" }}
            onClick={handleOpenAddPopup}
          >
            Add Target
          </Button>

          {/* <Paper
            sx={{
              width: "100px",
              fontSize: "22px",
              fontWeight: 600,

              borderRadius: "25px",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              marginLeft: "10px",
            }}
            onClick={handleFilterToggel}
          >
            <FilterAltIcon
              fontSize="small"
              // onClick={handleOpen}
              color="orange"
            />
            <Typography
              sx={{ fontSize: "14px", fontWeight: 600, marginLeft: "5px" }}
            >
              Filter
            </Typography>
            {!filterOpen ? (
              <KeyboardArrowDownIcon fontSize="small" />
            ) : (
              <KeyboardArrowUpIcon fontSize="small" />
            )}
          </Paper> */}
        </Grid>

        {filterOpen && (
          <Grid item xs={12}>
            <EmployeeTargetFilter />
          </Grid>
        )}

        <Grid item xs={12}>
          <MainCard>
            <Grid
              item
              xs={12}
              lg={12}
              sm={12}
              md={12}
              textAlign="end"
              mt={2}
              mb={2}
            >
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  flexDirection: "row",
                  justifyContent: "end",
                }}
              >
                <Typography sx={{ fontWeight: 600, fontSize: "14px" }}>
                  Search:{"  "}
                </Typography>
                <TextField
                  // value={value}
                  onChange={handleSearchByName}
                  size="small"
                  InputProps={{ style: { borderRadius: "15px" } }}
                />
              </Box>
            </Grid>

            <StyledTableContainer component={Paper}>
              <Table
                sx={{ minWidth: 650, tableLayout: "fixed" }}
                aria-label="simple table"
                stickyHeader
              >
                <TableHead>
                  <TableRow>
                    <TableCell sx={{ borderLeft: "0.1px solid #C3C3C3" }}>
                      Employee Name
                    </TableCell>
                    <TableCell sx={{}}>Target</TableCell>
                    <TableCell sx={{}}>Months</TableCell>
                    <TableCell sx={{}}>Year</TableCell>
                    <TableCell sx={{ width: "10%" }}>Action</TableCell>
                  </TableRow>
                </TableHead>

                {paginatedTargets?.length === 0 ? (
                  <Grid
                    item
                    xs={12}
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <Typography variant="h5">No data available</Typography>
                  </Grid>
                ) : (
                  paginatedTargets?.map((row, index) => {
                    

                    const currentDate = new Date();

                    const isPastDate =
                      parseInt(row?.year) < currentDate.getFullYear() ||
                      (parseInt(row?.year) === currentDate.getFullYear() &&
                        row?.month_id?.id < currentDate.getMonth() + 1);
                    return (
                      <TableBody>
                        <TableRow key={row.name}>
                          <TableCell
                            component="th"
                            scope="row"
                            sx={{ borderLeft: "0.1px solid #C3C3C3" }}
                          >
                            {row?.adv_user_id?.first_name}{" "}
                            {row?.adv_user_id?.last_name}
                          </TableCell>
                          <TableCell>{row?.rev_target} /-</TableCell>
                          <TableCell>{row.month_id?.month_full_name}</TableCell>

                          <TableCell>{row?.year}</TableCell>
                          <TableCell
                            sx={{
                              width: "10%",
                            }}
                          >
                            <Button
                              disabled={isPastDate}
                              size="small"
                              variant="contained"
                              color="secondary"
                              onClick={() => handleOpenEditPopup(row)}
                            >
                              Edit
                            </Button>
                          </TableCell>
                        </TableRow>
                      </TableBody>
                    );
                  })
                )}
              </Table>
            </StyledTableContainer>
            <TablePagination
              rowsPerPageOptions={[10, 25, 50]}
              component="div"
              count={empoyeeTargetData.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </MainCard>
        </Grid>
      </Grid>

      <CustomPopup
        open={addPopup}
        onClose={handleCloseAddPopup}
        component={<AddEmployeeTarget onClose={handleCloseAddPopup} />}
        title="Add Employee Target"
        maxWidth="sm"
        showDivider={true}
      />
      <CustomPopup
        open={editPopup}
        onClose={handleCloseEditPopup}
        component={
          <EditEmployeeTarget
            onClose={handleCloseEditPopup}
            selectedData={selectedData}
          />
        }
        title="Edit Employee Target"
        maxWidth="sm"
        showDivider={true}
      />
    </>
  );
}
